// ENDLESS SCROLL CONSTANTS


export const ENDLESS_SCROLL_CONFIG = {
  events: {orderBy: "expireToPast"},
  news: {orderBy: "id"},
  policies: {orderBy: "id"},
  surveys: {orderBy: "id"}
}

// Timout after last fetch before must re-fetch all docs when navigating back to same page.
export const FORCE_REFETCH_TIME = 0 // in minutes

// Quantity of documents to be fetched at a time.
export const DOC_FETCH_QUANTITY = 8