import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Col, Row, Spinner} from "react-bootstrap";
import {getDocById, updateData} from "../../../dataManagement/database/dbFunctions";
import CredContext from "../../../providers/CredContext";

function Comments({comments}) {
    const [deleteStatus, setDeleteStatus] = useState({show: false, pending: false})
    const cred = useContext(CredContext)

    console.log(comments)

    const deleteComment = (commentId, postId) => {
        getDocById("likesComments", postId)
            .then(doc => {
                let tempArr = doc.comments
                let i = tempArr.findIndex(c => c.commentId === commentId)
                tempArr.splice(i, 1)
                updateData("comments", tempArr, postId, "likesComments")
            })
    }

    return <>
        <section className={"scComments border-grey border-thin p-4"}
                 style={{overflowY: "auto", height: "52rem"}}>

            {/*Comments*/}
            {comments && <div id={"commentDiv"}>
                {comments.slice(0).reverse().map((comment, i) => {
                    return <div key={i} className={"scComments__displayComments"}>
                        <Row className={"px-0 mx-0 border-bottom border-dark"}>
                            <Col xs={10} className={"py-2"}>
                                <p className={"scComments__displayComments--name scComments__displayComments--name-date"}>{comment.title}</p>
                            </Col>
                            <Col xs={2} className={"d-flex align-content-center justify-content-end"}>

                                {/*Delete Btn*/}
                                {cred.admin &&
                                <button className={"ml-1 border-0 prime-colour"} type="button"
                                        style={{fontSize: "1.3rem"}}
                                        onClick={() => setDeleteStatus({
                                            show: deleteStatus.show === comment.commentId ? 0 : comment.commentId,
                                            pending: false
                                        })}>
                                    <FontAwesomeIcon size={"lg"} icon={faTrashAlt}/>
                                </button>}
                                <div className={"position-absolute text-right background-white border-thin"}
                                     style={{
                                         right: "0",
                                         top: "3.5rem",
                                         transition: ".5s",
                                         maxHeight: deleteStatus.show === comment.commentId ? "15rem" : "0",
                                         overflow: "hidden",
                                         zIndex: "1",
                                     }}>
                                    <div className={"p-2 border border-grey"} style={{width: "16.5rem"}}>
                                        <p className={"position-relative mb-1 line-height-tight"}
                                           style={{fontSize: "1.6rem"}}>
                                            Delete this comment?
                                        </p>
                                        <button type="button" className="sc-red-btn scbtn--small"
                                                onClick={() => {
                                                    setDeleteStatus({show: true, pending: true})
                                                    deleteComment(comment.commentId, comment.postId)
                                                }}>
                                            {!deleteStatus.pending ? "Delete" :
                                                <Spinner animation={"border"} size={"sm"}/>}
                                        </button>
                                    </div>
                                </div>
                                {/*End Delete Btn*/}

                            </Col>
                        </Row>
                        <Row className={"scComments__displayComments--head px-0 mx-0"}>
                            <Col>
                                <p className={"scComments__displayComments--name scComments__displayComments--name-date"}>
                                    {decodeURIComponent(comment.userName)}
                                </p>
                                <p className=" scComments__displayComments--date scComments__displayComments--name-date">
                                    {comment.commentDate}
                                </p>
                            </Col>
                        </Row>
                        <p className={"scComments__displayComments--comment"}>
                            {decodeURIComponent(comment.userComment)}</p>
                    </div>
                })}
            </div>}
        </section>
    </>
}

export default Comments;