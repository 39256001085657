import React, {useContext, useState} from 'react';
import CredContext from "../providers/CredContext";
import {Link, useHistory} from "react-router-dom";
import {Accordion, Col, Form, Row} from "react-bootstrap";
import {deleteUser} from "./authHelpers";
import Loader from "../dataManagement/components/Loader";
import PageTitle from "../components/elements/PageTitle";

function MyAccount() {
    const cred = useContext(CredContext)
    let history = useHistory()
    const [deleteStatus, setDeleteStatus] = useState({pending: false, success: false, message: null});
    const [password, setPassword] = useState("");

    const deleteAccount = () => {
        setDeleteStatus({pending: true, success: false, message: null})
        deleteUser(cred.email, password)
            .then(() => {
                setDeleteStatus({pending: false, success: true, message: null})
                history.push("/login")
            })
            .catch(err => {
                setDeleteStatus({
                    pending: false,
                    success: false,
                    message: "An error occurred while trying to delete your account. Please try again."
                })
            })
    }

    return (
        <>
            <PageTitle title={"My Account"}/>

            <div className={"mt-5 background-light border-grey border-thin p-5 position-relative"}
                 style={{maxWidth: "900px", minWidth: "280px", width: "80%", margin: "auto"}}
            >

                <div className={"mb-4"}>
                    <h4 className={"mb-0 font-family-paragraph font-weight-bold"}>{cred.displayName}</h4>

                    <Row className={"pb-3 pt-1"}>
                        <Col xs={"auto"}>
                            <p className={"mb-0 text-small"}>Account</p>
                            <p className={"mb-0 text-small"}>Email</p>
                        </Col>
                        <Col xs={"auto"}>
                            <p className={"mb-0 text-small"}>{cred.principal ? "Site Manager" : cred.admin ? "Admin" : "Editor"}</p>
                            <p className={"mb-0 text-small"}>{cred.email}</p>
                        </Col>
                    </Row>

                    <div className={"pt-1"}>
                        <Link to={"/my-account/change-password"}>
                            <button className={"font-colour hover-prime border-0 background-light"}>
                                Change your password
                            </button>
                        </Link>
                    </div>


                    <Link to={`/my-account/change-display-name-${cred.displayName}`}>
                        <button className={"font-colour hover-prime border-0 background-light"}>
                            Change your display name
                        </button>
                    </Link>
                </div>

                <Accordion className={"p-0 m-0"}>
                    <Accordion.Toggle eventKey="0" as={"div"}>
                        <button type={"div"} className={"sc-red-btn scbtn--small text-small mt-3"}
                                onClick={(e) => e.currentTarget.blur()}>
                            Delete your account
                        </button>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        {cred.principal ?
                            <div>
                                <p className={"font-weight-bold m-0 pt-2"}>The Site Managers account cannot be
                                    deleted.</p>
                                <p className={"m-0 pb-2"}>
                                    You must first handover the Site Manager account to another user in
                                    &nbsp;<Link to={"/accounts/account-manager"}>Account Manager< /Link>.
                                </p>
                                <ol className={"line-height-tight"}>
                                    <li>Go to <strong>Edit</strong> on the account you wish to handover to.</li>
                                    <li>Select the <strong>Other</strong> tab.</li>
                                    <li>Select <strong>Handover</strong>.</li>
                                </ol>
                            </div>
                            :
                            <div className={"mt-2 alert-danger px-2 pb-3"}>
                                <p className={"pt-2 mb-1 dark-grey-colour font-weight-bold"}>Permanently delete your
                                    account?</p>
                                <div className={"mb-2"}>
                                    <Form.Label className={"general-input__label mb-1"}>Enter your password</Form.Label>
                                    <Form.Control type={"password"} placeholder={"Password"} id={"password"}
                                                  className={"general-input"} value={password}
                                                  onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>

                                <button type={"button"} className={"sc-red-btn scbtn--small mt-1"} onClick={(e) => {
                                    e.currentTarget.blur()
                                    deleteAccount()
                                }}>
                                    Confirm
                                </button>
                            </div>}
                    </Accordion.Collapse>
                </Accordion>


                {
                    deleteStatus.pending &&
                    <>
                        <div className={"position-absolute background-light"}
                             style={{
                                 height: "100%",
                                 width: "100%",
                                 top: "0",
                                 left: "0",
                                 opacity: ".3"
                             }}>
                            <Loader/>
                        </div>
                    </>
                }
                <p className={"p-1 mt-3 mb-0 text-right " + (deleteStatus.success ? "colour-green" : "prime-colour")}>{deleteStatus.message}</p>
            </div>
        </>
    );
}

export default MyAccount;