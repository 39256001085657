import React from "react";
import SurveyEditorProvider from "../providers/SurveyEditorContext";
import SurveyEditorContainer from "../components/surveys/SurveyEditorContainer";

function EventEditorScreen() {
  return (
    <SurveyEditorProvider>
      <SurveyEditorContainer />
    </SurveyEditorProvider>
  );
}

export default EventEditorScreen;