import React from 'react'
import {Link, useHistory} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap'
import {Container} from 'react-bootstrap'
import NavDesktop from "./header/NavDesktop";
import {faUserCog, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {auth} from "../dataManagement/firebaseConfig";

function Header({cred}) {
    const history = useHistory()

    const logout = () => {
        auth.signOut().then(() => {
            history.push("/login")
        })
    }

    const TOP_LINKS_STYLE = {opacity: ".8", fontSize: "1.5rem", alignSelf: "center", position: "relative"}

    return (
        <header>
            <nav style={{zIndex: "99999", width: "calc(100vw - 1.5rem)"}}
                 className={"navbar position-relative px-0"}>
                <Container className={"header-container position-relative pt-2"}>
                    <LinkContainer to="/" style={{cursor: "pointer"}}>
                        <div className={"d-flex mt-auto"}>
                            <h3 className={"mb-0"} style={{fontWeight: "300", color: "#6c757d"}}>S</h3>
                            <h3 className={"mb-0"}>C</h3>
                            <h3 className={"mb-0"} style={{color: "#6c757d", fontWeight: "300"}}>&nbsp;Admin Panel</h3>
                        </div>
                    </LinkContainer>

                    <div style={{minHeight: cred ? "6.2rem" : "2rem", transition: ".5s"}}>
                        {cred &&
                        <div className={"ml-auto text-right"}>
                            <div className={"d-flex justify-content-end"}>

                                <p className={"line-height-tight m-0 p-0"}
                                   style={{...TOP_LINKS_STYLE, top: "-2px", opacity: ".5"}}>{cred.displayName}</p>

                                <Link className={"navbar__link text-small pt-0 pr-0 ml-2"} to={"/my-account"}
                                      style={TOP_LINKS_STYLE}>
                                    My Account
                                    <FontAwesomeIcon style={{fontSize: "1.1rem", top: "-1px"}}
                                                     className={"position-relative ml-1"}
                                                     icon={faUserCog}/>
                                </Link>
                                <a className={"navbar__link text-small pt-0 pr-0 ml-2"} onClick={logout}
                                   style={{...TOP_LINKS_STYLE, top: "0"}}>
                                    Log Out
                                    <FontAwesomeIcon
                                        style={{fontSize: "1.5rem", top: "1px"}}
                                        className={"position-relative ml-1"}
                                        icon={faSignOutAlt}/>
                                </a>
                            </div>

                            <NavDesktop cred={cred}/>

                        </div>}
                    </div>

                </Container>
            </nav>
        </header>
    )
}

export default Header
