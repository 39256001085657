import React, {useEffect, useState} from 'react';
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Form} from "react-bootstrap";
import moment from 'moment';

function DateTimeGenerator({date, handleChange}) {
    const [dateInputs, setDateInputs] = useState({
        date: new Date(moment.utc(date)),
        hour: new Date(date).getUTCHours(),
        min: Math.round(new Date(date).getUTCMinutes() / 5) * 5,
    })

    const onChange = (value, key) => {
        dateInputs[key] = value
        setDateInputs({...dateInputs})
    }

    useEffect(() => {
        const addZero = (str) => {
            str = str.toString()
            return str === "60" ? "00" : str.length < 2 ? `0${str}` : str
        }
        const y = dateInputs.date.getFullYear(),
            m = dateInputs.date.getMonth() + 1,
            d = dateInputs.date.getDate(),
            h = dateInputs.hour,
            min = dateInputs.min;

        handleChange(`${y}-${addZero(m)}-${addZero(d)}T${addZero(h)}:${addZero(min)}:00Z`)
    }, [dateInputs])


    // //temp EVENT obj
    // let tempEvent = event[0]
    // event[eventKey] = tempArr
    // //Set Event
    // setEvent([tempEvent])

    if (!dateInputs) return null
    return <>
        <div className={"d-flex"}>
            <Form.Group className={"d-flex flex-column mr-4 mb-0"}>
                <Form.Label className={"readers-only"}>Date</Form.Label>
                <Datepicker
                    selected={dateInputs.date}
                    onChange={value => {
                        // const d = new Date(value)
                        // console.log("VALUE: ", Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
                        // console.log("VALUE: ", new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())))
                        onChange(new Date(value), "date")
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="general-input"
                    todayButton="Today"
                />
            </Form.Group>

            <div className={"d-flex flex-column"}>
                <label className={"readers-only"}>Hours</label>
                <select className={"time-dropdown"} value={dateInputs.hour}
                        onChange={e => onChange(e.target.value, "hour")}
                >
                    {[[...Array(24)].map((opt, i) => {
                        return <option key={i} className={"time-dropdown"} value={i + 1}>{i + 1}</option>
                    })]}
                </select>
            </div>

            <div className={"d-flex flex-column"}>
                <label className={"readers-only"}>Minutes</label>
                <select className={"time-dropdown"} value={dateInputs.min}
                        onChange={e => onChange(e.target.value, "min")}
                >
                    {[[...Array(12)].map((opt, i) => {
                        return <option key={i} className={"time-dropdown"}
                                       value={i * 5}>{(i * 5) < 10 ? "0" + (i * 5) : i * 5}</option>
                    })]}
                </select>
            </div>

        </div>

    </>
}

export default DateTimeGenerator;

