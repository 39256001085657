export const toolbarConfig = [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'blockQuote',
    'subscript',
    'superscript',
    'removeFormat',
    '|',
    'alignment',
    'outdent',
    'indent',
    'bulletedList',
    'numberedList',
    '|',
    'fontBackgroundColor',
    'fontColor',
    'highlight',
    '|',
    'specialCharacters',
    '|',
    'imageUpload',
    'imageInsert',
    'insertTable',
    'mediaEmbed',
    '|',
    'horizontalLine',
    'pageBreak',
    '|',
    'CKFinder',
    'htmlEmbed',
    'link',
    '|',
    'undo',
    'redo'
]

export const basicToolbarConfig = [
    'heading',
    '|',
    'bold',
    'italic',
    'removeFormat',
    '|',
    'alignment',
    '|',
    'link',
    '|',
    'undo',
    'redo'
]


export const colourConfig = [
    {color: "#0C090F", label: "Color de Fuente"},
    {color: "#991012", label: "Dora Mayer - Rojo"},
    {color: "#560809", label: "Dora Mayer - Rojo Oscuro"},
    {color: "#300505", label: "Dora Mayer - Rojo muy oscuro"},
    {color: "#897239", label: "Dora Mayer - Oro"},
    {color: "#434555", label: "Dora Mayer - Gris"},
    {color: "#cccccc", label: "#cccccc"},
    {color: "#e7e7e9", label: "#e7e7e9"},
    {color: "#f8f9fa", label: "#f8f9fa"},
    {color: "#FFFFFF", label: "#FFFFFF"},
    {color: "#000000", label: "#000000"},
    {color: "#434343", label: "#434343"},
    {color: "#666666", label: "#666666"},
    {color: "#999999", label: "#999999"},
    {color: "#B7B7B7", label: "#B7B7B7"},
    {color: "#CCCCCC", label: "#CCCCCC"},
    {color: "#D9D9D9", label: "#D9D9D9"},
    {color: "#EFEFEF", label: "#EFEFEF"},
    {color: "#F3F3F3", label: "#F3F3F3"},
    {color: "#FFFFFF", label: "#FFFFFF"},
    {color: "#E6B8AF", label: "#E6B8AF"},
    {color: "#F4CCCC", label: "#F4CCCC"},
    {color: "#FCE5CD", label: "#FCE5CD"},
    {color: "#FFF2CC", label: "#FFF2CC"},
    {color: "#D9EAD3", label: "#D9EAD3"},
    {color: "#D0E0E3", label: "#D0E0E3"},
    {color: "#C9DAF8", label: "#C9DAF8"},
    {color: "#CFE2F3", label: "#CFE2F3"},
    {color: "#D9D2E9", label: "#D9D2E9"},
    {color: "#EAD1DC", label: "#EAD1DC"},
    {color: "#DD7E6B", label: "#DD7E6B"},
    {color: "#EA9999", label: "#EA9999"},
    {color: "#F9CB9C", label: "#F9CB9C"},
    {color: "#FFE599", label: "#FFE599"},
    {color: "#B6D7A8", label: "#B6D7A8"},
    {color: "#A2C4C9", label: "#A2C4C9"},
    {color: "#A4C2F4", label: "#A4C2F4"},
    {color: "#9FC5E8", label: "#9FC5E8"},
    {color: "#B4A7D6", label: "#B4A7D6"},
    {color: "#D5A6BD", label: "#D5A6BD"},
    {color: "#CC4125", label: "#CC4125"},
    {color: "#E06666", label: "#E06666"},
    {color: "#F6B26B", label: "#F6B26B"},
    {color: "#FFD966", label: "#FFD966"},
    {color: "#93C47D", label: "#93C47D"},
    {color: "#76A5AF", label: "#76A5AF"},
    {color: "#6D9EEB", label: "#6D9EEB"},
    {color: "#6FA8DC", label: "#6FA8DC"},
    {color: "#8E7CC3", label: "#8E7CC3"},
    {color: "#C27BA0", label: "#C27BA0"},
    {color: "#A61C00", label: "#A61C00"},
    {color: "#CC0000", label: "#CC0000"},
    {color: "#E69138", label: "#E69138"},
    {color: "#F1C232", label: "#F1C232"},
    {color: "#6AA84F", label: "#6AA84F"},
    {color: "#45818E", label: "#45818E"},
    {color: "#3C78D8", label: "#3C78D8"},
    {color: "#3D85C6", label: "#3D85C6"},
    {color: "#674EA7", label: "#674EA7"},
    {color: "#A64D79", label: "#A64D79"},
    {color: "#85200C", label: "#85200C"},
    {color: "#990000", label: "#990000"},
    {color: "#B45F06", label: "#B45F06"},
    {color: "#BF9000", label: "#BF9000"},
    {color: "#38761D", label: "#38761D"},
    {color: "#134F5C", label: "#134F5C"},
    {color: "#1155CC", label: "#1155CC"},
    {color: "#0B5394", label: "#0B5394"},
    {color: "#351C75", label: "#351C75"},
    {color: "#733554", label: "#733554"},
    {color: "#5B0F00", label: "#5B0F00"},
    {color: "#660000", label: "#660000"},
    {color: "#783F04", label: "#783F04"},
    {color: "#7F6000", label: "#7F6000"},
    {color: "#274E13", label: "#274E13"},
    {color: "#0C343D", label: "#0C343D"},
    {color: "#1C4587", label: "#1C4587"},
    {color: "#073763", label: "#073763"},
    {color: "#20124D", label: "#20124D"},
    {color: "#4C1130", label: "#4C1130"},]

export const fontColourConfig = {
    colors: colourConfig,
    columns: 10,
}

export const bgColourConfig = {
    colors: colourConfig,
    columns: 10,
}

export const imageConfig = {
    // Configure the available styles.
    styles: [
        'alignLeft', 'alignCenter', 'alignRight'
    ],
    toolbar: [
        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative'
    ],
    resizeOptions: [
        {
            name: 'resizeImage:original',
            value: null,
            label: 'Original'
        },
        {
            name: 'resizeImage:50',
            value: '25',
            label: '25%'
        },
        {
            name: 'resizeImage:50',
            value: '50',
            label: '50%'
        },
        {
            name: 'resizeImage:75',
            value: '75',
            label: '75%'
        },
        {
            name: 'resizeImage:50',
            value: '100',
            label: '100%'
        },
    ],
}

export const mediaConfig = {previewsInData: true}

export const tableConfig = {
    contentToolbar: [
        'tableColumn', 'tableRow', 'mergeTableCells',
        'tableProperties', 'tableCellProperties'
    ],
    tableProperties: {
        borderColors: colourConfig,
        backgroundColors: colourConfig
    },

    // Set the palettes for table cells.
    tableCellProperties: {
        borderColors: colourConfig,
        backgroundColors: colourConfig
    }
}

export const headingConfig = {
    options: [
        {model: 'paragraph', title: 'Paragraph'},
        {model: 'heading2', view: 'h2', title: 'Heading 1'},
        {model: 'heading3', view: 'h3', title: 'Heading 2'},
        {model: 'heading4', view: 'h4', title: 'Heading 3'},
        {model: 'heading5', view: 'h5', title: 'Heading 4'},
        {model: 'heading6', view: 'h6', title: 'Heading 5'},
    ]
}

export const linkConfig = {
    decorators: {
        openInNewTab: {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
            }
        }
    }
}
