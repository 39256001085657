import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";


function SupportUsBtns({array, handleChange}) {
    const handleBtnChange = (newArr) => {
        handleChange(newArr, "buttons")
    }

    return <>
        {array &&
        <ArrayRepeater array={array} setFunction={handleBtnChange} />}
    </>
}

export default SupportUsBtns;

/////////////////// Repeater ///////////////////////////

//  1. {array}
//  ! Array to be repeated (mapped)

//  2. {setFunction}
//  ! function that takes updated array as input


function ArrayRepeater({array, setFunction}) {
    const [tempArray, setTempArray] = useState(array)

    useEffect(() => {
        setFunction(tempArray)
    }, [tempArray])

    return <>
        <hr className={"mt-5"} />
        {tempArray[0] && tempArray.map((btn, i) => {
            return <div key={i} className={"mt-5"}>
                <Row>
                    <Col md={9}>
                        <ArrayForm state={[tempArray, setTempArray]} title={btn.title} link={btn.link} index={i} />
                    </Col>
                    <Col md={3}>
                        <ArrayBtns last={i + 1 !== tempArray.length} first={i !== 0} index={i}
                                   state={[tempArray, setTempArray]} />
                    </Col>
                </Row>
                <hr className={"mt-4"} />
            </div>
        })}
        <div className={"mb-5 text-right"}>
            <button type={"button"} className={"scbtn--med scbtn--1"} onClick={() => {
                setTempArray(prevState => {
                    return [...prevState, {title: "", link: ""}]
                })
            }}>
                New Button
            </button>
        </div>
    </>
}

///////////////////// Form ///////////////////////

function ArrayForm
({title, link, index, state: [array, setArray]}) {
    const [dropdown, setDropdown] = useState(link);

    useEffect(() => {
        if (link.length > 0) {
            // handleChange(link.length > 0 ? link : "", "link")
            setDropdown("custom")
        }
    }, [])

    const handleChange = (v, n) => {
        let tempArr = array
        tempArr[index][n] = v
        setArray([...tempArr])
    }

    const handleCtaChange = (val) => {
        handleChange(val !== "custom" ? val : "", "link")
        setDropdown(val)
    }

    return <>
        <label className={"general-input__label"}>Button Text</label>
        <input type={"text"} placeholder={"Button Text"} className={"general-input mb-2"}
               value={title} onChange={(e) => handleChange(e.target.value, "title")} />

        <Form.Group className={"w-50"}>
            <label className={"general-input__label"}>Call To Action</label>
            <Form.Control as="select" value={dropdown} id={"horizontal"} className={"general-input"}
                          onChange={(e) => handleCtaChange(e.target.value)}>
                <option value={"/support-us/cta?form=donor"}>Make a Donation</option>
                <option value={"/support-us/cta?form=friend"}>Become a Friend</option>
                <option value={"/support-us/cta?form=mail-signup"}>Join Mailing List</option>
                <option value={"custom"}>Custom Link</option>
            </Form.Control>
        </Form.Group>

        {dropdown === "custom" &&
        <>
            <label className={"general-input__label"}>Button Link</label>
            <input type={"text"} placeholder={"Button Link"} className={"general-input mb-2"}
                   value={link} onChange={(e) => handleChange(e.target.value, "link")} />
        </>}

    </>
}

///////////////////// Buttons ///////////////////////

function ArrayBtns({last, first, index, state: [array, setArray]}) {
    const [helpText, setHelpText] = useState(" ")

    const handleMove = (action) => {
        let tempArr = array
        let f = tempArr.splice(index, 1)[0];
        action === "down" && tempArr.splice(index + 1, 0, f);
        action === "up" && tempArr.splice(index - 1, 0, f);
        setArray([...tempArr])
    }

    const handleAddDelete = (action) => {
        let tempArr = array
        action === "add" && tempArr.splice(index + 1, 0, {title: "", link: ""});
        action === "delete" && tempArr.splice(index, 1);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"position-absolute"} style={{bottom: "4rem", right: "0"}}>
                <p className={"text-right m-0 p-0 text-small"}>{helpText}</p>
            </div>
            <div className={"position-absolute"} style={{bottom: "0", right: "0"}}>
                {first && <Btn value={<FontAwesomeIcon icon={faAngleUp} />} action={"up"}
                               setHelp={setHelpText} helpText={"Move Up"} handleChange={handleMove} />}
                {last && <Btn value={<FontAwesomeIcon icon={faAngleDown} />} action={"down"}
                              setHelp={setHelpText} helpText={"Move Down"} handleChange={handleMove} />}

                <Btn value={<FontAwesomeIcon icon={faPlus} />} action={"add"} handleChange={handleAddDelete}
                     setHelp={setHelpText} helpText={"Add Bellow"} />
                <Btn value={<FontAwesomeIcon icon={faTrashAlt} />} action={"delete"} handleChange={handleAddDelete}
                     setHelp={setHelpText} helpText={"Delete"} />

            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, setHelp, helpText, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "2rem", lineHeight: ".6"}}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onMouseEnter={() => setHelp(helpText)} onMouseLeave={() => setHelp("")}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {value}
        </button>
    </>
}