import React from "react";

const EventSurveyAndDonorBtns = ({event, position}) => {
  const notes = event.programmeNotes

  if (
    !notes.surveyBtnPositions.includes(`pos-${position}_survey`)
    && !notes.surveyBtnPositions.includes(`pos-${position}_donate`)
  ) return null

  return <div className={"hide-on-print"}>
    <p className={"mb-0 mt-5"}>{notes.surveyCallToActionText}</p>
    <div className={"d-flex"}>
      {notes.surveyBtnPositions.includes(`pos-${position}_survey`) &&
      <div className={""}>
        <button className={"header-btn--btn scbtn--2 "} type={"button"}
                onClickCapture={(e) => e.currentTarget.blur()}>{notes.surveyBtnText}</button>
      </div>}

      {notes.surveyBtnPositions.includes(`pos-${position}_donate`) &&
      <div className={"ml-3"}>
        <button className={"header-btn--btn scbtn--2 "} type={"button"}
                onClickCapture={(e) => e.currentTarget.blur()}>{notes.donateBtnText}</button>
      </div>}
    </div>
  </div>
}

export default EventSurveyAndDonorBtns;