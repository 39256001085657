import React, {useEffect, useState} from 'react';
import {Spinner} from "react-bootstrap";

function BtnAndConfirm({
                           btnClassName,
                           btnText,
                           message,
                           confirmBtnText,
                           showState: [show, setShow],
                           btnId,
                           handleClick,
                           onClickAction,
                           spin,
                       }) {
    const [collapse, setCollapse] = useState(true)

    useEffect(() => {
        setCollapse(btnId !== show)
    }, [show])

    return <>
        <button style={{
            fontSize: "1.3rem",
            width: confirmBtnText !== "Delete" ? "12rem" : "5rem",
            height: "4rem",
            borderRadius: "0",
        }}
                className={"ml-1 " + btnClassName} type="button"
                onClick={() => setShow(show === btnId ? 0 : btnId)}>
            {spin !== onClickAction ? btnText : <Spinner animation={"border"} />}
        </button>
        
        <div className={"position-absolute text-right background-white"}
             style={{
                 top: "4.4rem",
                 transition: ".5s",
                 maxHeight: !collapse ? "15rem" : "0",
                 overflow: "hidden",
                 zIndex: "999",
             }}>
            <div className={"border border-thin px-3 pb-4"}>
                <p className={"position-relative my-0 pt-3 px-3"}>{message}</p>
                <button type="button" className="scbtn--2 scbtn--small" onClick={() => {
                    handleClick(onClickAction)
                    setShow(0)
                }}>
                    {confirmBtnText}
                </button>
            </div>
        </div>
    </>
}

export default BtnAndConfirm;