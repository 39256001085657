import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/auth"
import "firebase/analytics"
import "firebase/storage"
import "firebase/functions"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1Zoj1FGkBmetVBOBmJW9ie8UNbdFPSBE",
  authDomain: "skipton-camerata-cf704.firebaseapp.com",
  projectId: "skipton-camerata-cf704",
  storageBucket: "skipton-camerata-cf704.appspot.com",
  messagingSenderId: "568196353287",
  appId: "1:568196353287:web:730f08e65c3e13903b09a2",
  measurementId: "G-ZPVCC06Y5F"
};
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
!firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app()
firebase.analytics();
firebase.firestore();

export const storage = firebase.storage();
export const db = firebase.firestore();
export const auth = firebase.auth();
export const functions = firebase.functions();
// functions.useEmulator("localhost", 4000)

export const STORAGE_PATH = "https://firebasestorage.googleapis.com/v0/b/dora-mayer-83993.appspot.com/o/"


export default firebase