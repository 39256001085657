import React, {useContext, useEffect, useRef, useState} from 'react';
import {NewsEditorContext} from "../../providers/NewsEditorContext";
import PageTop from "../blocks/PageTop";
import NewsPostPreview from "./NewsPostPreview";
import NewsEditorForm from "./NewsEditorForm";
import SetSectionDocument from "../../dataManagement/SetSectionDocument";

function NewsEditorContainer() {
    const {article, articleId, fetchErr, fetchComplete, handlePreSave} = useContext(NewsEditorContext)
    const [previewMode, setPreviewMode] = useState(false);
    const titleBeforeUpdate = useRef({set: false, title: ""})

    useEffect(() => {
        if (fetchComplete) {
            if (!titleBeforeUpdate.current.set) titleBeforeUpdate.current = {set: true, title: article.title}
        }
    }, [articleId, fetchComplete])

    return <>
        <PageTop section={"news"} title={articleId === "new" ? "News Creator" : "News Editor"} />

        {/* Set edit || preview mode button */}
        <div className={"position-sticky text-right"} style={{top: "5rem", right: "0", zIndex: "99"}}>
            <button className={"scbtn--med scbtn--1"} onClick={() => setPreviewMode(!previewMode)}>
                {!previewMode ? "Preview" : "Edit"} Mode
            </button>
        </div>


        {/* Preview || Editor Form */}
        {previewMode ? <NewsPostPreview article={article} /> : <NewsEditorForm />}


        {/* Create/Update Button */}
        {articleId === "new" && article &&
        <SetSectionDocument document={article} collection={"news"} docId={article.id} btnText={"Create Post"}
                            cancelLink={"news"} callback={handlePreSave} isUpdate={false} />}
        {articleId !== "new" && article &&
        <SetSectionDocument document={article} collection={"news"} docId={articleId} btnText={"Update Post"}
                            cancelLink={"news"} callback={handlePreSave} isUpdate
                            oldTitle={titleBeforeUpdate.current.title} />}

    </>
}

export default NewsEditorContainer;