import React from 'react';
import ImageLibrary from "../../imageManager/ImageLibrary";


// Choose Image (sets image and alt)

function Page2({bannerState}) {
    function handleImage(image, action = "add") {
        bannerState[1](prevState => {
            console.log(image)
            return {
                ...prevState,
                img: image[0],
                alt: image[1].replace("https://firebasestorage.googleapis.com/v0/b/skipton-camerata-cf704.appspot.com/o/banner%2F", ""),
            }
        })
    }


    return (
        <div className={"w-50 m-auto"}>
            <h3 style={{marginTop: "25%"}}>Select an image</h3>

            <div className={"d-flex"}>
                <ImageLibrary directory={"banner"} handleImageChoice={handleImage}/>

                <button className={"scbtn--1 scbtn--med mx-1"}
                        style={{height: "3.7rem", padding: ".6rem 1rem"}}
                        onClick={() => handleImage(["", ""])}
                        disabled={!bannerState[0].alt || bannerState[0].alt.length === 0}
                >
                    No Image
                </button>

                {bannerState[0].img ?
                    <p className={"d-inline-block p-1 ml-1"} style={{opacity: ".8"}}>
                        Image: <strong>{bannerState[0].alt}</strong>
                    </p>
                    :
                    <p className={"d-inline-block p-1 ml-1"} style={{opacity: ".5"}}>
                        No image has been selected</p>}
            </div>

        </div>
    );
}

export default Page2;