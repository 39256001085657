import React from 'react';
import Content from "./Content";


function BannerPreview({bannerState}) {

    let content1 = bannerState[0].writtenContent.content1
    let content2 = bannerState[0].writtenContent.content2
    let content3 = bannerState[0].writtenContent.content3
    let link1 = bannerState[0].links.link1
    let link2 = bannerState[0].links.link2
    let link3 = bannerState[0].links.link3

    return (
        <div className={"med-scrn-banner-center my-3 mx-auto ld"}
             style={{height: "40.5vw", width: "72vw"}}>

            <div className={"mt-2 position-relative"} id={"bannerPreview"} style={{height: "100%"}}>

                {bannerState[0].img !== "" ?
                    <img className={"banner__img " + (bannerState[0].imagePosition)}
                         src={`${bannerState[0].img}`} alt={bannerState[0].img}
                    />
                    :
                    <div className={"banner__img h-100"}>&nbsp;</div>
                }
                <div
                    style={{
                        backgroundColor: bannerState[0].overlayColour
                    }}
                    className="banner__overlay"/>

                <div className={"fade-in visible"}>
                    <Content content={content1} link={link1} disabled={true} preview={true}/>
                    <Content content={content2} link={link2} disabled={true} preview={true}/>
                    <Content content={content3} link={link3} disabled={true} preview={true}/>
                </div>
            </div>
        </div>
    );
}

export default BannerPreview;
