import {db} from "../firebaseConfig";
import {
    AUTH_REQUEST, BANNER_REQUEST, CONTENT_REQUEST, EVENTS_REQUEST, FOOTER_REQUEST,
    GENERAL_REQUEST, MESSAGE_REQUEST, NEWS_REQUEST, POLICIES_REQUEST
} from "../constants/contentConstants";


// Content
export const dataContent = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: CONTENT_REQUEST[0]})

        db.collection("content")
            .where('page', '==', table)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: CONTENT_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: CONTENT_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Footer
export const dataFooter = (table, condition) => async (dispatch) => {
    try {
        dispatch({type: FOOTER_REQUEST[0]})

        db.collection("footer")
            .doc(condition)
            .get()
            .then((snapshot) => {
                let data = [snapshot.data()];
                // snapshot.docs.forEach(doc => {
                //     data.push(doc.data())
                // })
                dispatch({
                    type: FOOTER_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: FOOTER_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// banner
export const dataBanner = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: BANNER_REQUEST[0]})

        db.collection("banner")
            .where(conditions)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: BANNER_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: BANNER_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Message
export const dataMessage = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: MESSAGE_REQUEST[0]})

        db.collection("messageStrip")
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: MESSAGE_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: MESSAGE_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// News
export const dataNews = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: NEWS_REQUEST[0]})

        db.collection("news")
            .where(conditions.key, conditions.comparison, conditions.value)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: NEWS_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: NEWS_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


// Events
export const dataEvents = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: EVENTS_REQUEST[0]})
        db.collection("events")
            .where(conditions.key, conditions.comparison, conditions.value)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: EVENTS_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: EVENTS_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Policies
export const dataPolicies = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: POLICIES_REQUEST[0]})
        db.collection("policies")
            .where(conditions.key, conditions.comparison, conditions.value)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: POLICIES_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: POLICIES_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// General
export const dataGeneral = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: GENERAL_REQUEST[0]})

        db.collection("general")
            .where('fetchId', '==', conditions)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                console.log("GENERAL ACTION ", data)
                dispatch({
                    type: GENERAL_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: GENERAL_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Auth
export const dataAuth = (table, conditions) => async (dispatch) => {
    try {
        dispatch({type: AUTH_REQUEST[0]})

        db.collection("auth")
            .where('page', '==', table)
            .get()
            .then((snapshot) => {
                let data = [];
                snapshot.docs.forEach(doc => {
                    data.push(doc.data())
                })
                dispatch({
                    type: AUTH_REQUEST[1],
                    payload: data
                })
            })
    } catch (error) {
        dispatch({
            type: AUTH_REQUEST[2],
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        })
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////