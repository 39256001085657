import React, {useState} from 'react';
import PageTop from "../components/blocks/PageTop";
import {FetchContent} from "../dataManagement/FetchContent";
import MessageManager from "../components/messageStrip/MessageManager";
import MessageCreator from "../components/messageStrip/MessagesCreator";
import UpdateMessages from "../components/messageStrip/UpdateMessages";

function MessageStrip() {
    const [data, setData] = useState([])
    const [changeList, setChangeList] = useState([])

    const handleCreatorChange = (val) => {
        let tempArr = data
        data[9].library = val
        setData([...tempArr])
        setChangeList(prevState => {
            return [...prevState, 9]
        })
    }
    console.log("DATA: ", data)

    return <>
        <PageTop title={"Message Strip"} section={"message"}/>
        <FetchContent contentState={[data, setData]} table={"message"} conditions={""}>
            {data[9] && data[9].library &&
            <>
                <label className={"mt-2 mb-0 general-input__label font-weight-bold prime-colour"}>Manager</label>
                <hr className={"mt-0"}/>
                <MessageManager dataState={[data, setData]} changeListState={[changeList, setChangeList]}/>
                <label className={"mt-5 pt-4 mb-0 general-input__label font-weight-bold prime-colour"}>Messages</label>
                <hr className={"mt-0"}/>
                <MessageCreator data={data[9].library} handleChange={handleCreatorChange}/>
                <UpdateMessages changeListState={[changeList, setChangeList]} data={data}/>
            </>}
        </FetchContent>
    </>
}

export default MessageStrip;

