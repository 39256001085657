import React, {useEffect, useRef} from 'react';
import useOnScreen from "./useOnScreen";
import Loader from "../../dataManagement/components/Loader";

function ImOnScreen({setOnScreenState: [onScreen, setOnScreen], showLoader}) {

    const ref = useRef()
    const isVisible = useOnScreen(ref)

    useEffect(() => {
        if (onScreen !== isVisible) setOnScreen(isVisible)
    }, [isVisible])

    return <div ref={ref} className={"text-center prime-colour py-4"}>{showLoader ? <Loader/> : ""}</div>
}

export default ImOnScreen;