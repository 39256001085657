import React, {useState} from "react";
import Loader from "../../../dataManagement/components/Loader";

function AddedList({events, handleChangeList, pending}) {
  const [hover, setHover] = useState(false);

  if (!events) return null
  return (
    <>
      <h6 className={"line-height-tight mb-2"}>See Also List</h6>
      <div style={{height: "7rem", overflowY: "auto"}}>
        {pending && <Loader spinner />}
        {events && events.map((event, i) => {
          return <div key={event.id} className={"mr-3 mb-0 d-inline-block position-relative"}>

                        <span
                          className={"text-small mb-0 badge white-colour mb-2 " + (hover !== event.id ? "bg-success" : "background__fail")}
                          style={{cursor: "pointer"}}
                          onClick={() => handleChangeList(event)}
                          onMouseOver={() => setHover(event.id)}
                          onMouseOut={() => setHover(null)}
                        >
                            {event.title}
                        </span>
          </div>
        })}
      </div>
    </>
  );
}

export default AddedList;