import React, {useContext, useState} from 'react';
import {Accordion, Form} from "react-bootstrap";
import {auth, functions} from "../../dataManagement/firebaseConfig";
import Loader from "../../dataManagement/components/Loader";
import CredContext from "../../providers/CredContext";
import {authCheck} from "../authHelpers";
import {useHistory} from "react-router-dom";
import ToggleButtons from "../../components/elements/ToggleButtons";


function AdvancedSettings({userState: [user, setUser], handleDeletedUser}) {
    const cred = useContext(CredContext)
    const deleteUser = functions.httpsCallable("deleteUser")
    const [loading, setLoading] = useState({pending: false, success: false, message: null});
    const [showDeletedMessage, setShowDeletedMessage] = useState(false);
    const [deleteOrAdmin, setDeleteOrAdmin] = useState(0);
    const history = useHistory()

    const deleteAccount = () => {
        setLoading({pending: true, success: false, message: null})
        deleteUser({uid: user.userId})
            .then(() => {
                setLoading({
                    pending: false,
                    success: true,
                    message: `${user.displayName}'s account has been successfully deleted`
                })
                setShowDeletedMessage(true)
                handleDeletedUser(user.userId)
            })
            .catch(data => {
                setLoading({
                    pending: false,
                    success: false,
                    message: `There was a problem while trying to delete ${user.displayName}'s account. Please try again.`
                })
            })
    }

    const handoverPrincipal = functions.httpsCallable("handoverPrincipal")
    const handoverPrincipalsAccount = (e) => {
        setLoading({pending: true, success: false, message: null})
        e.preventDefault()
        handoverPrincipal({deleteOrAdmin: deleteOrAdmin, newPrincipalUid: user.userId, oldPrincipalUid: cred.userId})
            .then(() => {
                setLoading({pending: false, success: false, message: null})
                if (deleteOrAdmin === 1) {
                    auth.signOut()
                    history.push("/login")
                } else {
                    authCheck()
                    history.push("/my-account")
                }

            })
            .catch(err => {
                setLoading({pending: false, success: false, message: null})
            })
    }

    return (
        <>
            <div className={"position-relative"}>

                {(cred.principal || (cred.admin && !user.admin)) && <>
                    <h5 className={"font-weight-light"}>Delete this account</h5>
                    <Accordion>
                        <Accordion.Toggle eventKey="0" as={"div"}>
                            <button type={"div"} className={"scbtn--1 background-white scbtn--small"}
                                    onClick={(e) => e.currentTarget.blur()}>
                                Delete
                            </button>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <div className={"mt-2 alert-danger px-2"}>
                                <p className={"mb-1 dark-grey-colour font-weight-bold"}>Permanently delete this
                                    account?</p>
                                <button type={"button"} className={"sc-red-btn scbtn--med mb-3"}
                                        onClick={(e) => {
                                            e.currentTarget.blur()
                                            deleteAccount()
                                        }}>
                                    Confirm
                                </button>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                </>}


                {cred.principal && <>

                    <h5 className={"mt-4 font-weight-light"}>Handover the Site Manager account
                        to <u>{user.displayName}</u></h5>
                    <Accordion>
                        <Accordion.Toggle eventKey="0" as={"div"}>
                            <button type={"div"} className={"scbtn--1 background-white scbtn--small"}
                                    onClick={(e) => e.currentTarget.blur()}>
                                Handover
                            </button>
                        </Accordion.Toggle>
                        <Accordion.Collapse className={"alert-warning px-2 pb-3"} eventKey="0">
                            <Form onSubmit={handoverPrincipalsAccount} className={"mt-2"}>
                                <p className={"font-weight-bold mb-1 dark-grey-colour"}>Are you sure you want to hand
                                    over the Site Manager account?</p>
                                <p className={"mb-1 dark-grey-colour"}>By proceeding you will lose the Site Manager
                                    account as only one can exist.</p>

                                <hr className={"background-white"}/>
                                <p className={"font-weight-bold mb-2 dark-grey-colour"}>What should happen with your
                                    account?</p>
                                <div className={"mb-4 d-flex"}>
                                    <ToggleButtons btnState={[deleteOrAdmin, setDeleteOrAdmin]}
                                                   buttonArray={[
                                                       {name: "Make me an Admin", value: 0},
                                                       {name: "Delete my account", value: 1}
                                                   ]}
                                                   onChangeFunc={(val) => {
                                                       setDeleteOrAdmin(val)
                                                       console.log(val)
                                                   }} size={"md"}/>

                                    {deleteOrAdmin === 0 ?
                                        <p className={"ml-3 mb-0 dark-grey-colour line-height-tight"}>Your account type
                                            will
                                            become admin.</p>
                                        :
                                        <p className={"ml-3 mb-0 dark-grey-colour line-height-tight"}>Your account will
                                            be
                                            deleted.</p>}
                                </div>
                                <hr className={"background-white"}/>

                                <button type={"submit"} className={"sc-red-btn scbtn--med"}
                                        onClick={(e) => e.currentTarget.blur()}>
                                    Confirm
                                </button>
                            </Form>
                        </Accordion.Collapse>
                    </Accordion>
                </>}

                {loading.pending &&
                <>
                    <div className={"position-absolute background-white"}
                         style={{height: "100%", width: "100%", top: "0", left: "0", opacity: ".8"}}>
                        <Loader wait/>
                    </div>
                </>
                }
                {/*<p className={"p-1 mt-3 mb-0 text-right prime-colour"}>{loading.message}</p>*/}
                {showDeletedMessage &&
                <>
                    <br/>
                    <p className={"py-1 px-3 mt-2 mb-0 " + (loading.success ? "background__success" : "background__fail")}>{loading.message}</p>
                </>}
            </div>
        </>
    );
}

export default AdvancedSettings;