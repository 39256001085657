import React, {useEffect} from "react";
import {decode} from "html-entities";

function HtmlReader({htmlContent, classNames, editorId}) {

  /* Replace tables with responsive table styled divs */
  useEffect(() => {
    // get figures that wraps table
    const figures = document.querySelectorAll(`#editor-${editorId} > figure.table`)
    // get tables
    const tables = document.querySelectorAll(`#editor-${editorId} > figure.table > table`)

    tables.forEach((t, i) => {
      if (!t.innerHTML) return
      // Get attributes of figure
      const figureStyle = figures[i].getAttribute("style")
      // Get attributes of table
      const tableStyle = t.getAttribute("style")
      // Calculate number of rows
      const rowLength = t.rows.length
      // Array for rows being created
      const newTableHtml = []
      // Array for new cells (columns) being created
      let newColsHtml = []

      // Loop through rows
      for (let i = 0; i < rowLength; i++) {
        // gets cells of current row
        let cells = t.rows.item(i).cells;
        // gets amount of cells of current row
        let cellLength = cells.length;

        //loops through each cell in current row
        for (let j = 0; j < cellLength; j++) {
          // get cell value
          let cellVal = cells.item(j).innerHTML;
          let cellStyle = cells.item(j).getAttribute("style");

          // push new cell value to newColsHtml array
          let cell = `<div class="${cellVal === "&nbsp;" ? "d-none" : ""}" style="${cellStyle ? cellStyle : ""}"><p>${cellVal}</p></div>`
          if (cell.includes("<div class=\"\" style=\"\"><p>-</p></div>")) {
            cell = "<div class='py-3 m-0'></div>"
          }
          newColsHtml[j] = !newColsHtml[j] ? [cell] : [...newColsHtml[j], cell]
        }
        // console.log(newColsHtml)
      }

      let doubleColRow = []
      newColsHtml.forEach((col, i) => {
        // console.log("FOR EACH: ", col)
        const colSize = newColsHtml.length < 8 ? newColsHtml.length : 8

        const colClasses = colSize === 1 ? [`col-12 col-md-12 col-lg-12`, `col-12 col-md-12 col-lg-12`] :
                           colSize === 2 ? [`col-12 col-md-6 col-lg-6`, `col-12 col-md-12 col-lg-12`] :
                           colSize === 3 ? [`col-12 col-md-12 col-lg-6`, `col-12 col-md-6 col-lg-6`] :
                           colSize === 4 ? [`col-12 col-md-12 col-lg-6`, `col-12 col-md-6 col-lg-6`] :
                           colSize === 5 ? [`col-12 col-md-12 col-lg-6`, `col-12 col-md-6 col-lg-4`] :
                           colSize === 6 ? [`col-12 col-md-12 col-lg-6`, `col-12 col-md-4 col-lg-4`] :
                           colSize === 7 ? [`col-12 col-md-12 col-lg-6`, `col-12 col-md-4 col-lg-3`] :
                           colSize === 8 ? [`col-12 col-md-12 col-lg-6`, `col-12 col-md-3 col-lg-3`] :
                             [`col-12 col-md-12 col-lg-12`, `col-12 col-md-12 col-lg-12`]


        const newColumn = `<div class="${colClasses[0]} p-0 avoid-break-for-print line-height-tighter my-3">${col.join("")}</div>`

        doubleColRow.push(newColumn)

        if (doubleColRow.length === 2 || i === newColsHtml.length - 1) {
          newTableHtml.push(`<div class="${colClasses[1]}"><div class="row">${doubleColRow.join("")}</div></div>`)
          console.log(doubleColRow)
          doubleColRow = []
        }


      })
      // console.log("-- ", newTableHtml)

      // Put together final html
      const finalHtml = `<div class="d-flex flex-wrap w-100" style="${tableStyle ? tableStyle : ""}">${newTableHtml.join("")}</div>`
      // Set figure wrapper as the joined newTableHtml array
      document.querySelectorAll(`#editor-${editorId} > figure.table`)[i].innerHTML = finalHtml
      document.querySelectorAll(`#editor-${editorId} > figure.table`)[i].classList.add("avoid-break-for-print")
    })
  }, [editorId])


  return (<div className={classNames + " data-from-editor"} id={`editor-${editorId}`}
               dangerouslySetInnerHTML={{__html: decode(htmlContent)}} />);
}

export default HtmlReader;