import {idMaker} from "../helper";
import moment from "moment";

export const createNewArticle = () => {
  return {
    object: "news",
    id: idMaker(),
    dateCreated: moment.utc().format(),
    datePublished: "unpublished",
    title: "",
    summary: "",
    img: "",
    alt: "",
    body: "",
    online: false,
    homePage: false,
  }
}