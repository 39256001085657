import React, {useContext, useEffect, useRef, useState} from "react";
import PageTop from "../blocks/PageTop";
import EventEditorForm from "./EventEditorForm";
import SetSectionDocument from "../../dataManagement/SetSectionDocument";
import EventPreview from "./EventPreview";
import {EventEditorContext} from "../../providers/EventEditorContext";
import {useLocation} from "react-router-dom";
import Event_NotesContainer from "./notes/Event_NotesContainer"
import Loader from "../../dataManagement/components/Loader";
import Event_Preview_NotesContainer from "./notes/Event_Preview_NotesContainer";

function EventEditorContainer() {
  const hashPath = useLocation().hash
  const {event, eventId, fetchErr, fetchComplete} = useContext(EventEditorContext)
  // Toggle preview mode
  const [previewMode, setPreviewMode] = useState(false);
  // Update title (needs to be done before updating the rest)
  const titleBeforeUpdate = useRef({set: false, title: ""})


  useEffect(() => {
    if (fetchComplete) {
      if (!titleBeforeUpdate.current.set) titleBeforeUpdate.current = {set: true, title: event.title}
    }
  }, [eventId, fetchComplete])

  if (!fetchComplete && eventId !== "new") return <Loader />
  return <>
    <PageTop section={"events"}
             title={hashPath.includes("notes") ? "Event Notes" : eventId === "new" ? "Event Creator" : "Event Editor"} />


    {/* Set edit || preview mode button */}
    <div className={"position-sticky text-right"} style={{top: "5rem", right: "0", zIndex: "99"}}>
      <button className={"scbtn--med scbtn--1"} onClick={() => setPreviewMode(!previewMode)}>
        {!previewMode ? "Preview" : "Edit"} Mode
      </button>
    </div>


    {/* Preview || Editor Form */}
    {
      previewMode && hashPath.includes("notes") ? <Event_Preview_NotesContainer event={event} /> :
      previewMode ? <EventPreview event={event} /> :
      hashPath.includes("notes") ? <Event_NotesContainer /> :
      <EventEditorForm />
    }


    {/* Create/Update Button */}
    {eventId === "new" && event &&
    <SetSectionDocument document={event} collection={"events"} docId={event.id}
                        btnText={"Create Event"} cancelLink={"events"} isUpdate={false} />}
    {eventId !== "new" && event &&
    <SetSectionDocument document={event} collection={"events"} docId={eventId} btnText={"Update Event"}
                        cancelLink={"events"} isUpdate oldTitle={titleBeforeUpdate.current.title} />}
  </>
}

export default EventEditorContainer;