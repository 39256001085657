import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {CredProvider} from "./CredContext";
import {DbDataProvider} from "./DbDataContext";
import {TextEditorProvider} from "./TextEditorContext";
import {authCheck} from "../accountMangment/authHelpers";
import Loader from "../dataManagement/components/Loader";

function SiteProvider({children}) {
    let pathname = useLocation().pathname

    // db fetched data
    const [dbFetchedData, setDbFetchedData] = useState({
        news: [],
        newsTimout: null,
        pastEvents: [],
        pastEventsTimout: null,
        events: [],
        eventsTimout: null
    });

    //Text Editor
    const [textEditorCleanup, setTextEditorCleanup] = useState({});

    // User Credentials
    const [cred, setCred] = useState(false);
    const [fetchingCred, setFetchingCred] = useState(true);

    useEffect(() => {
        authCheck()
            .then((cred) => {
                setCred(cred)
            })
            .catch(() => {
                setCred(false)
            })
            .finally(() => {
                setFetchingCred(false)
            })
        // return null;
    }, [pathname])

    return (
        <CredProvider value={cred}>
            <TextEditorProvider value={{textEditorCleanup, setTextEditorCleanup}}>
                <DbDataProvider value={{dbFetchedData, setDbFetchedData}}>
                    {fetchingCred ? <Loader /> : children}
                </DbDataProvider>
            </TextEditorProvider>
        </CredProvider>
    );
}

export default SiteProvider;