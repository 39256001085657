import React, {useEffect, useState} from 'react';
import HtmlReader from "../blocks/HtmlReader";
import {Link} from "react-router-dom";
import {decode} from "html-entities";

function Content({content, link, disabled = false, preview}) {
    // Calculates if content html should render or not. Depends on banner custom field (bool) and if quick/custom fields have input (that aren't spaces)
    // function shouldRender(custom, quickContent, customContent) {
    //     return ((!custom && quickContent.replace(/\s/g, '')) || (custom && customContent.replace(/\s/g, '')))
    // }
    const [qContent, setQContent] = useState(null)

    const getLinkClass = (type, size) => {
        let isLink = type === "o-link" || type === "w-link"
        let typeClass = type === "o-link" ? ""
            : type === "w-link" ? "white-colour"
                : type === "btn-1" ? "scbtn--1"
                    : type === "btn-2" ? "scbtn--2 "
                        : null
        let sizeClass = size === "sm" && isLink ? "sm-link "
            : size === "md" && isLink ? "md-link "
                : size === "lg" && isLink ? "lg-link "
                    : size === "sm" && !isLink ? "scbtn--small banner-btn "
                        : size === "md" && !isLink ? "scbtn--med banner-btn "
                            : size === "lg" && !isLink ? "scbtn--large banner-btn "
                                : null

        return `${typeClass} ${sizeClass}`
    }

    useEffect(() => {
        let arr = content && content.contentQuick && content.contentQuick
        let tempArr = []
        for (let i = 0; i < arr.length; i++) {
            // Tag
            let tag = arr[i].type.includes("h2") ? ["<h2", "</h2>"] : arr[i].type.includes("h3") ? ["<h3", "</h3>"] : ["<p", "</p>"]
            // Colour
            let colour = arr[i].type.includes("o-") ? "prime-colour" : arr[i].type.includes("w-") ? "white-colour" : ""
            // BG Colour
            let bg = arr[i].bg === "n" ? "" : arr[i].bg === "o" ? "#ff9605" : arr[i].bg === "w" ? "#fff" : arr[i].bg === "d" ? "#0C0D04" : null
            // Text Size
            let sizeClass = arr[i].type.includes("lg") ? "lg-title" : arr[i].type.includes("md") ? "md-title" : arr[i].type.includes("h3") ? "sm-title" : ""
            // Push HTML to tempArr
            tempArr.push(`<div>${tag[0]} class="${sizeClass} ${colour}" style="background-color:${bg}; font-weight:${arr[i].weight}">${arr[i].text}${tag[1]}</div>`)
        }
        setQContent(tempArr.join(""))
    }, [content.contentQuick])


    return <>
        <div className={`banner__content ${content.position} ` + (!content.custom && content.textAlign)}>

            {/* CONTENT */}
            <HtmlReader
                classNames={(!content.custom ? `px-3 ` : "px-3") + (preview ? " bannerTitle__preview" : " bannerTitle")}
                htmlContent={content.custom ? decode(content.contentCustom) : qContent}/>

            {/* LINK */}
            {!link.type.includes("link") && <br/>}
            {!disabled && link.type !== "nl" ?
                <Link to={link.link}
                      className={"my-0 bannerLink " + getLinkClass(link.type, link.size) + (!preview ? " bannerLink" : "bannerLink__preview")}>{link.text}</Link>
                :
                <Link
                    className={"my-0 " + getLinkClass(link.type, link.size) + (!preview ? " bannerLink" : "bannerLink__preview")}>{link.text}</Link>}

        </div>
    </>
}

export default Content;