import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom"
import PageTitle from "../components/elements/PageTitle";
import TextEditor from "../components/textEditor/TextEditor";
import SetDocument from "../dataManagement/SetDocument";
import {getDataByQuery} from "../dataManagement/database/dbFunctions";

function GeneralScreenEditor() {
    const pathId = useLocation().pathname.replace("/", "").replaceAll("-", "")
    const [form, setForm] = useState([])

    //Text Editor Change Handler
    const handleChange = (data, key) => {
        let tempObj = form[0]
        tempObj[key] = data
        tempObj.fetchId = pathId
        setForm([tempObj])
    }

    useEffect(() => {
        setForm([])
        getDataByQuery("general", {fetchId: pathId})
            .then(doc => {
                console.log("DOC: ", doc)
                setForm(doc)
            })
    }, [pathId])


    return <>

        {form[0] && <div key={pathId}>
            <PageTitle title={form[0].title}/>
            <br/>

            <TextEditor value={form[0].body} handleChange={handleChange} imageDirectory={`${pathId}/body`}
                        keyToUpdate={"body"}/>


            <SetDocument document={form[0]} collection={"general"} docId={pathId} dataObject={form[0]}/>
        </div>}

    </>
}

export default GeneralScreenEditor;