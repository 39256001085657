import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {storage} from "../firebaseConfig";
import {deleteData, getDocById, setData} from "../database/dbFunctions";
import {getFileId} from "../../imageManager/ImageHelpers";


//// File upload to a given directory --- with axios ////

export function ImageUploadForm({directoryName, renderCountState}) {
    const [file, setFile] = useState(null)
    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false)

    function onChangeHandler(e) {
        if (e.target.files[0]) {
            const selectedFile = e.target.files[0]
            setFile(selectedFile)
        }
    }

    function onClickHandler(e) {
        e.target.blur()
        //Create storage ref
        let storageRef = storage.ref(`/${directoryName}/${file.name}`)
        //Upload file
        let task = storageRef.put(file)
        // state changes
        task.on("state_changed",
            function progress(snap) {
                setShowProgress(true)
                let status = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
                setProgress(status)
            },
            function error(err) {
                console.log(err)
            },
            function complete() {
                storageRef.getDownloadURL().then((url) => {

                    setData("imageNames", getFileId(url), {name: file.name})
                        .then(() => {
                            renderCountState[1](renderCountState[0] + 1)
                            setTimeout(() => {
                                setShowProgress(false)
                            }, 2500)
                        })
                })
            }
        )
    }

    return (
        <>
            <div className={"position-relative"} style={{height: "0"}}>
                <div className={"m-0 position-absolute progress-bar " + (!showProgress && "collapse")}
                     style={{width: `${progress}%`}}><span/></div>
            </div>
            <div className={"d-flex py-3 border-thin-grey"}>
                <Form>
                    <Form.File
                        id="file"
                        className={"text-small"}
                        custom
                        // value={selectedFile.name}
                        onChange={onChangeHandler}
                    />
                </Form>
                <button type="button" className="scbtn--small scbtn--1 ml-2 py-0"
                        disabled={file === null}
                        onClick={onClickHandler}
                >
                    Upload
                </button>
            </div>
        </>
    );
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function uploadImage(directoryName, file) {
    return new Promise((resolve, reject) => {
        //Create storage ref
        let storageRef = storage.ref(`/${directoryName}/${file.name}`)
        //Upload file
        let task = storageRef.put(file)
        // state changes
        task.on("state_changed",
            function progress() {
            },
            function error(err) {
                reject(err)
            },
            function complete() {

                storageRef.getDownloadURL()
                    .then((url) => {
                        setData("imageNames", getFileId(url), {name: file.name})
                            .then(() => resolve(url))
                    })
            }
        )
    })
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//// Fetch all files from a given directory --- with axios ////

export function fetchFileUrls(directoryName) {
    let storageRef = storage.ref(directoryName) //Create storage ref
    // Promise
    return new Promise((resolve, reject) => {
        storageRef.listAll()
            .then((res) => {
                let imagUrls = [];

                let createArr = new Promise(r => {
                    if (res.items.length === 0) r()
                    res.items.forEach((itemRef, i) => {
                        itemRef.getDownloadURL().then(function (url) { // All the items under listRef.
                            imagUrls.push(url)
                            if (i === res.items.length - 1) r();
                        });
                    })
                })

                createArr.then(() => resolve(imagUrls))
            })
            .catch((error) => {
                // Uh-oh, an error occurred!
                reject(error)
            })
    })
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//// Delete a file from a given directory ////

export function deleteFile(directoryName, fileUrl) {
    return new Promise((resolve, reject) => {

        const fileId = getFileId(fileUrl)
        console.log(fileId)
        getDocById("imageNames", fileId)
            .then(doc => {
                let storageRef = storage.ref(`${directoryName}/${doc.name}`) //Create storage ref
                console.log("TRYING TO DELETE:: ", `${directoryName}/${doc.name}`)
                storageRef.delete()
                    .then(function (response) {
                        deleteData("imageNames", fileId)
                            .then(() => resolve())
                    })
                    .catch(function (response) {
                        console.log("Delete Failed. Response: ", response)
                        deleteData("imageNames", fileId)
                            .then(() => reject())
                    });

            })
            .catch((err) => {
                console.log("Failed to get image name: ", err)
                reject(err)
            })

    })
}









