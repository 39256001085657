import React, {useEffect, useState} from "react";
import {getAllData, getDocById} from "../dataManagement/database/dbFunctions";
import Loader from "../dataManagement/components/Loader";

// Result: {data, err, pending, complete, handleChange, FetchDataLoader}
function useFetchData(collection, docId = null, disableFetch = false) {
  const [data, setData] = useState(null);
  const [err, setErr] = useState(null);
  const [pending, setPending] = useState(false);
  const [complete, setComplete] = useState(false);

  // console.log("useFetch:: ", data, err, pending, complete)

  // FETCH DATA
  useEffect(() => {
    if (!disableFetch) {
      setComplete(false)
      setPending(true)
      if (docId) {
        getDocById(collection, docId)
          .then(data => {
            setData(data)
            setErr(null)
          })
          .catch(err => setErr(err))
          .finally(() => {
            setPending(false)
            setComplete(true)
          })
      } else {
        getAllData(collection)
          .then(data => {
            setData(data)
            setErr(null)
          })
          .catch(err => setErr(err))
          .finally(() => {
            setPending(false)
            setComplete(true)
          })
      }

    }
  }, [collection, docId, disableFetch])


  // EXPORT LOADER WHEN FETCHING
  const FetchDataLoader = ({children}) => {
    return !data ? <Loader /> : children
  }

  // HANDLE DATA CHANGE
  const handleChange = (value, key) => {
    if (!data) return
    data[key] = value
    setData({...data})
  }


  return {data, err, pending, complete, handleChange, FetchDataLoader};
}

export default useFetchData;