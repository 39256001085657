import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './dataManagement/store.js'
import './index.css';
import App from './App';
import SiteProvider from "./providers/SiteProvider";

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <SiteProvider>
                <App/>
            </SiteProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

