import React from 'react';

function Textarea({label, rows, keyToUpdate, value, handleChange}) {

    return <>
        <label className={"general-input__label"}>{label}</label>
        <textarea placeholder={label}
                  className={"general-input"}
                  value={value}
                  onChange={e => handleChange(e.target.value, keyToUpdate)}
                  rows={rows} />
    </>
}

export default Textarea;