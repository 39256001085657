import React, {useState} from 'react';
import {FetchContent} from "../../dataManagement/FetchContent";
import TextEditor from "../textEditor/TextEditor";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";

function LegalInfo() {
    const [data, setData] = useState([])

    const handleChange = (val) => {
        let tempObj = data[0]
        tempObj.legalInfo = val
        setData([tempObj])
    }

    return <>
        <FetchContent contentState={[data, setData]} table={"footer"} conditions={"legalInfo"}>

            {data && data[0] &&
            <div className={"mt-4"}>
                <TextEditor handleChange={handleChange} keyToUpdate={"legalInfo"} value={data[0].legalInfo}
                            imageDirectory={"legalInfo"} toolbar={"basic"}/>

                <UpdateDocumentField collection={"footer"} docId={"legalInfo"} updateKey={"legalInfo"}
                                     updateValue={data[0].legalInfo}/>
            </div>}

        </FetchContent>
    </>
}

export default LegalInfo;