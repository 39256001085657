import React, {useState} from 'react';
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";
import PageTop from "../components/blocks/PageTop";
import SectionManageBtns from "../components/sectionManagerButtons/SectionManageBtns";
import {Col, Row} from "react-bootstrap";
import SectionSearch from "../components/blocks/SectionSearch";
import {newsDateFormat} from "../helper";

function News() {
    const [posts, setPosts] = useState([])
    const [searchResults, setSearchResults] = useState([])


    return <>
        <PageTop section={"news"} title={"News Manager"} />

        <FetchWithEndlessScroll currentPageDocsState={[posts, setPosts]} type={"news"} pauseFetch={searchResults[0]}>

            {/*Search bar*/}
            <SectionSearch setSearchResults={setSearchResults} collection={"news"} field={"title"} />

            <section className="container">
                {posts && (searchResults[0] ? searchResults : posts).map((post, i) => {
                    return <Row key={i} className={"search-card"}>
                        <Col>
                            <h2 style={{fontSize: "2.5rem"}}>{post.title}</h2>

                            <div className={"m-0"}>
                                {post.datePublished && post.datePublished !== "unpublished" ?
                                    <p>{newsDateFormat(post.datePublished)}</p>
                                    :
                                    <li className={"list-unstyled text-info"}>Unpublished</li>
                                }
                            </div>

                            <ul className={"mb-0 search-card__date"}>

                            </ul>

                            {post.summary && post.summary.length > 75 ?
                                <p className={"d-block w-100 m-0"}>{(post.summary).substring(0, 70)}...</p>
                                :
                                <p className={"d-block w-100 m-0"}>{post.summary}</p>
                            }
                        </Col>

                        <Col className="d-flex justify-content-end">

                            <SectionManageBtns
                                itemState={searchResults[0] ? [searchResults, setSearchResults] : [posts, setPosts]}
                                page={"news"} index={i} dbCollection={"news"} />

                        </Col>
                    </Row>
                })}
            </section>
        </FetchWithEndlessScroll>
    </>
}

export default News;