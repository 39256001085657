import React from 'react';

//// Page title

// 1. {title}
// ! title text

function PageTitle({title, pageTop = false}) {
    return (
        <div className={"w-100 mb-3 " + (!pageTop && "pt-2")}>
            <h2 className={"text-capitalize"}>{title}</h2>
        </div>
    );
}

export default PageTitle;