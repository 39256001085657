import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faMinus,} from "@fortawesome/free-solid-svg-icons";
import {FetchContent} from "../../dataManagement/FetchContent";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";


function HomeDisplay() {
    const [array, setArray] = useState([])

    const handleChange = (newArr) => {
        let tempObj = array[0]
        tempObj.display = newArr
        setArray([tempObj])
    }

    return <>
        <FetchContent contentState={[array, setArray]} table={"general"} conditions={"homeDisplay"}>
            {array[0] && array[0].display &&
            <ArrayRepeater array={array[0].display} setFunction={handleChange} />}
        </FetchContent>

        {array[0] && array[0].display &&
        <UpdateDocumentField collection={"general"} docId={"homeDisplay"} updateKey={"display"}
                             updateValue={array[0].display} />}
    </>
}

export default HomeDisplay;

/////////////////// Repeater ///////////////////////////

//  1. {array}
//  ! Array to be repeated (mapped)

//  2. {setFunction}
//  ! function that takes updated array as input


function ArrayRepeater({array, setFunction}) {
    const [tempArray, setTempArray] = useState(array)

    useEffect(() => {
        setFunction(tempArray)
    }, [tempArray])

    return <>
        <hr className={"mt-5"} />
        {tempArray[0] && tempArray.map((sect, i) => {
            return <div key={i} className={"my-4"}>
                <Row>
                    <Col md={9}>
                        <ArrayForm section={sect} />
                    </Col>
                    <Col md={3}>
                        <ArrayBtns last={i + 1 !== tempArray.length} first={i !== 0} index={i}
                                   state={[tempArray, setTempArray]} />
                    </Col>
                </Row>
                <hr className={"mt-4"} />
            </div>
        })}
    </>
}

///////////////////// Form ///////////////////////

function ArrayForm({section}) {
    const title = section === "m" ? "Message Strip" :
        section === "a" ? "Welcome" :
            section === "e" ? "Events Cards" :
                section === "n" ? "News Cards" :
                    section === "l" ? "Newsletter Form" :
                        section === "i" ? "Intro" :
                            null

    return <>
        <h4 className={"general-input__label m-0"}>{title}</h4>
    </>
}

///////////////////// Buttons ///////////////////////

function ArrayBtns({last, first, index, state: [array, setArray]}) {

    const handleMove = (action) => {
        let tempArr = array
        let f = tempArr.splice(index, 1)[0];
        action === "down" && tempArr.splice(index + 1, 0, f);
        action === "up" && tempArr.splice(index - 1, 0, f);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"position-absolute"} style={{bottom: "0", right: "0"}}>
                <Btn value={<FontAwesomeIcon icon={faAngleUp} />} action={"up"} disabled={(!first)}
                     helpText={"Move Up"} handleChange={handleMove} />
                <Btn value={<FontAwesomeIcon icon={faAngleDown} />} action={"down"} disabled={(!last)}
                     helpText={"Move Down"} handleChange={handleMove} />

            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, disabled, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "2rem", lineHeight: ".6", cursor: disabled ? "default" : "pointer"}}
                disabled={disabled}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {!disabled ? value : <FontAwesomeIcon icon={faMinus} style={{fontSize: "1.4rem", margin: ".3rem 0"}} />}
        </button>
    </>
}