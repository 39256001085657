import {getAllDocs, getDocsByQuery} from "./database/dbFunctions";

//// Fetch banners from db ////
export function fetchBanner(bannerId, setBanner) {
    if (bannerId === "all") {
        getAllDocs("banner")
            .then(docs => {
                let banners = [];
                docs.forEach(doc => {
                    banners.push([doc.id, doc.data()])
                })
                setBanner(banners)
            })
    } else {
        getDocsByQuery("banner", {bannerId: bannerId})
            .then(docs => {
                let banners = [];
                docs.forEach(doc => {
                    banners.push([doc.id, doc.data()])
                })
                setBanner(banners)
            })
    }
}

//// Fetch banner from db ////
export function fetchSingleBanner(bannerId, setBanner) {
    return new Promise((resolve, reject) => {
        getDocsByQuery("banner", {bannerId: bannerId})
            .then((doc) => {
                setBanner(doc[0].data())
                resolve()
            })
            .catch(() => {
                reject()
            })
    })
}


