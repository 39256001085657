import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import Loader from "../dataManagement/components/Loader";
import {resetPasswordEmail} from "./authHelpers";
import {LinkContainer} from "react-router-bootstrap";
import PageTitle from "../components/elements/PageTitle";

function ForgottenPassword() {
    const [email, setEmail] = useState("");
    const [submitStatus, setSubmitStatus] = useState({pending: false, success: false, message: null});

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitStatus({pending: true, success: false, message: ""})
        resetPasswordEmail(email)
            .then(() => {
                setSubmitStatus({
                    pending: false,
                    success: true,
                    message: "An email has been sent to you with a link to reset your password."
                })
            })
            .catch(err => {
                if (err) {
                    console.log(err.code === "auth/user-not-found")
                    setSubmitStatus({
                        pending: false,
                        success: false,
                        message: "No user with that email address was found. Please check your entry."
                    })
                } else {
                    setSubmitStatus({
                        pending: false,
                        success: false,
                        message: "An error occurred. Please check your entry."
                    })
                }
            })
    }

    return (
        <>
            <PageTitle title={"Password Reset"}/>

            <Form className={"mt-5 bg-light border table-bordered border-thin p-5 position-relative"}
                  style={{maxWidth: "500px", minWidth: "240px", width: "50%", margin: "auto"}}
                  onSubmit={(e) => handleSubmit(e)}
            >


                <Form.Group className={"mb-2"}>
                    <Form.Label className={"readers-only"}>Email</Form.Label>
                    <Form.Control type={"email"} placeholder={"Email"} id={"email"} value={email}
                                  className={"general-input mb-0"}
                                  onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <div className={"d-flex justify-content-end"}>
                    <LinkContainer to={"/login"} style={{fontSize: "1.2rem"}}>
                        <button className={"border-0 background-light"} type={"button"}>
                            Back to Login
                        </button>
                    </LinkContainer>
                </div>


                <div className={"d-flex justify-content-end"}>
                    <button className={"scbtn--med scbtn--1 mt-4 background-light"} style={{padding: ".4rem 1.2rem"}}
                            type="submit">
                        Submit
                    </button>
                </div>

                {submitStatus.pending &&
                <>
                    <div
                        className={"position-absolute background-white d-flex justify-content-center align-items-center"}
                        style={{height: "100%", width: "100%", top: "0", left: "0", opacity: ".8"}}>
                        <div style={{paddingBottom: "2.5rem"}}>
                            <Loader wait/>
                        </div>
                    </div>
                </>}
                {submitStatus.message &&
                <>
                    <br/>
                    <p className={"py-1 px-3 mt-2 mb-0 " + (submitStatus.success ? "background__success" : "background__fail")}>{submitStatus.message}</p>
                </>}
            </Form>

        </>
    );
}

export default ForgottenPassword;