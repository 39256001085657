import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

function Page4QuickContentMaker({val, handleChange, i}) {
    const [contentArr, setContentArr] = useState(val)

    const handleContentChange = (v, key, index) => {
        let tempArr = contentArr
        tempArr[index][key] = v
        setContentArr([...tempArr])
    }

    useEffect(() => {
        handleChange({target: {value: contentArr}}, i, "contentQuick")
    }, [contentArr])

    return <>

        {contentArr && contentArr.map((c, index) => {
            return <Row>
                {/* TEXT */}
                <Col xs={4}>
                    <Form.Group className={""}>
                        <Form.Label className={"readers-only"}>Quick content creator</Form.Label>
                        <Form.Control type="text" placeholder={`Line ${index + 1} Text`}
                                      className={"general-input"}
                                      onChange={(e) => handleContentChange(e.target.value, "text", index)}
                                      value={contentArr[index].text}
                        />
                    </Form.Group>
                </Col>
                {/* TEXT TYPE */}
                <Col xs={3} className={"ml-0 text-right"}>
                    <Form.Control as="select" className={"general-input"}
                                  value={contentArr[index].type}
                                  onChange={(e) => handleContentChange(e.target.value, "type", index)}>
                        <option value={"o-h2-lg"}>Large Title - Orange</option>
                        <option value={"w-h2-lg"}>Large Title - White</option>
                        <option value={"o-h2-md"}>Title - Orange</option>
                        <option value={"w-h2-md"}>Title - White</option>
                        <option value={"o-h3"}>Subtitle - Orange</option>
                        <option value={"w-h3"}>Subtitle - White</option>
                        <option value={"o-p"}>Paragraph - Orange</option>
                        <option value={"w-p"}>Paragraph - White</option>
                        <option value={"n-p"}>Paragraph - Normal</option>
                    </Form.Control>
                </Col>
                {/* BACKGROUND COLOUR */}
                <Col xs={2} className={"mr-0 text-right"}>
                    <Form.Control as="select" className={"general-input"}
                                  value={contentArr[index].bg}
                                  onChange={(e) => handleContentChange(e.target.value, "bg", index)}>
                        <option value={"n"}>None</option>
                        <option value={"o"}>Orange</option>
                        <option value={"w"}>White</option>
                        <option value={"d"}>Dark</option>
                    </Form.Control>
                </Col>
                {/* TEXT WEIGHT */}
                <Col xs={2} className={"mr-0 text-right"}>
                    <Form.Control as="select" className={"general-input"}
                                  value={contentArr[index].weight}
                                  onChange={(e) => handleContentChange(e.target.value, "weight", index)}>
                        <option value={"900"}>Bold</option>
                        <option value={"300"}>Light</option>
                    </Form.Control>
                </Col>
                <Col xs={1} className={"p-0 m-0 text-right text-nowrap"}>
                    <ArrayBtns index={index} state={[contentArr, setContentArr]}/>
                </Col>
            </Row>
        })}
        <div className={"mb-5 text-right"}>
            <button type={"button"} className={"scbtn--med scbtn--1"} onClick={() => {
                setContentArr(prevState => {
                    return [...prevState, {text: "", type: "o-h2-lg", bg: "n", weight: "900"}]
                })
            }}>
                New Line
            </button>
        </div>

    </>
}

export default Page4QuickContentMaker;


///////////////////// Buttons ///////////////////////

function ArrayBtns({index, state: [array, setArray]}) {

    const handleAddDelete = (action) => {
        let tempArr = array
        action === "add" && tempArr.splice(index + 1, 0, {text: "", type: "o-h2-lg", bg: "n", weight: "900"});
        action === "delete" && tempArr.splice(index, 1);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"pr-4"}>
                <Btn value={<FontAwesomeIcon icon={faPlus}/>} action={"add"} handleChange={handleAddDelete}/>
                <Btn value={<FontAwesomeIcon icon={faTrashAlt}/>} action={"delete"} handleChange={handleAddDelete}/>
            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, setHelp, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "1.8rem", lineHeight: ".9", height: "3.7rem"}}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {value}
        </button>
    </>
}