import React, {useContext, useRef, useState} from 'react';
import {EventEditorContext} from "../../providers/EventEditorContext";
import Input from "../elements/Input";
import Texarea from "../elements/Texarea";
import {Form} from "react-bootstrap";
import DateTimeGenerator from "../blocks/DateTimeGenerator";
import EventDateTimeSection from "./EventDateTimeSection";
import ImageLibrary from "../../imageManager/ImageLibrary";
import ToggleButtons from "../elements/ToggleButtons";
import InputWithList from "../elements/InputWithList";
import Loader from "../../dataManagement/components/Loader";
import Event_TextEditorFormSection from "./Event_TextEditorFormSection";

function EventEditorForm() {
    const {event, handleChange} = useContext(EventEditorContext)
    // Ticket URL radio button state
    const [btnState, setBtnState] = useState(event.ticketURL === "--noUrl--" ? 2 : 1)
    const url = useRef("")

    const handleToggle = (val) => {
        if (val === 2) url.current = event.ticketURL
        handleChange(val === 1 ? url.current : "--noUrl--", "ticketURL")
    }

    if (!event.id) return <Loader spinner />
    return <Form className={""}>
        {/*Title*/}
        <Input label={"Title"} keyToUpdate={"title"} value={event.title} handleChange={handleChange} />

        {/*Subtitle*/}
        <Input label={"Subtitle"} keyToUpdate={"subtitle"} value={event.subtitle} handleChange={handleChange} />

        <hr />
        {/*Summary*/}
        <Texarea label={"Summary"} rows={4} keyToUpdate={"summary"} value={event.summary} handleChange={handleChange} />

        <hr />
        {/*Image*/}
        <div className={"mb-3 d-flex"}>
            <div>
                <label className={"general-input__label"}>Main Image</label>
                <ImageLibrary directory={"events/main-image"} handleImageChoice={(imgArr) => handleChange(imgArr, "img")} />
            </div>
            <img className={"ml-4"} style={{maxHeight: "6.7rem"}} src={event.img} alt={"thumb"} />
        </div>
        {/*Image Caption*/}
        <Input label={"Image Caption / Alt"} keyToUpdate={"alt"} value={event.alt} handleChange={handleChange} />

        <hr />
        {/*Venue*/}
        <Input label={"Venue"} keyToUpdate={"venue"} value={event.venue} handleChange={handleChange} />

        <hr className={"mb-4"} />

        {/*DATES*/}
        <EventDateTimeSection event={event} handleChange={handleChange} />

        <hr className={"mb-4 mt-5"} />
        {/*Expiry*/}
        <div>
            <label className={"general-input__label"}>
                Expiry Date (When should this event become a past event?)
            </label>
            {event.expireToPast &&
            <DateTimeGenerator date={event.expireToPast} handleChange={d => handleChange(d, "expireToPast")} />}
        </div>

        <hr />
        {/*Use Ticket URL*/}
        <div className={"mb-3"}>
            <label className={"general-input__label"}>Add "Book Now" button?</label>
            <br />
            <ToggleButtons btnState={[btnState, setBtnState]} onChangeFunc={handleToggle}
                           buttonArray={[{name: "Yes", value: 1}, {name: "No", value: 2}]} />
        </div>
        <div className={btnState === 1 ? "" : "collapse"}>
            {/* Ticket Btn Text*/}
            <div>
                <InputWithList label={"Ticket Button Text"} value={event.ticketBtnTxt} keyToUpdate={"ticketBtnTxt"} handleChange={handleChange}
                               collection={"general"} docId={"ticketBtnOptions"} />
            </div>
            {/*Ticket URL*/}
            <Input label={"Ticket Booking URL"} keyToUpdate={"ticketURL"} value={event.ticketURL} handleChange={handleChange} />
        </div>

        {/*EDITORS*/}
        <Event_TextEditorFormSection />
        <hr />
    </Form>
}

export default EventEditorForm;