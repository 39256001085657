import moment from "moment";


//////////////////
// ID GENERATOR //
//////////////////

export const idMaker = () => {
  return new Date().toISOString().replace(/[-:.TZ]/g, "")
}


//////////////////////
// DATE TIME FORMAT //
//////////////////////

///// Create event Date, Time
export const eventDateFormat = (d) => {
  return moment(d).utc(false).format("dddd Do MMMM YYYY [at] h:mma")
}

///// Create news Date, Time
export const newsDateFormat = (d) => {
  return moment(d).utc(false).format("Do MMMM YYYY")
}

///// Create policy Date, Time
export const policyDateFormat = (d) => {
  return moment(d).utc(false).format("Do MMMM YYYY")
}

////////////////////////////////////
// ARRAY OF OBJECT UTIL FUNCTIONS //
////////////////////////////////////

// Check if object with certain key/val exists in array
export const checkIfObjInArr = (array, key, value) => {
  return !!array.some(x => x[key] === value);
}

// remove object fro array with certain key/val pair
export const removeObjFromArr = (array, key, value) => {
  return array.filter((item) => item[key] !== value);
}


///////////////////////
// GENERAL FUNCTIONS //
///////////////////////