import {idMaker} from "../helper";
import moment from "moment";

export const createNewEvent = () => {
  return {
    object: "event",
    id: idMaker(),
    dateCreated: moment.utc().format(),
    datePublished: "unpublished",
    title: "",
    subtitle: "",
    summary: "",
    img: ["", ""],
    alt: "",
    imgCredits: "",
    venue: "",
    eventDates: [moment.utc().format()],
    expireToPast: moment.utc().format(),
    ticketURL: "",
    body: "",
    programme: "",
    programmeNotes: {
      showNotes: false,
      showSurveyLink: true,
      surveyCallToActionText: "",
      surveyLink: "",
      donateLink: "support-us",
      surveyBtnText: "",
      donateBtnText: "",
      surveyBtnPositions: [],
      repertoireNotes: "",
      performersNotes: "",
      useExpire: false,
      expireDate: moment.utc().format(),
    },
    performers: "",
    ticket: "",
    ticketBtnTxt: "",
    ticketBtnTxtOptions: [],
    online: false,
    homePage: false,
    seeAlso: [],
  }
}