import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {handleBannerChange, handleBannerInputChange} from "./BannerHelper";


// Set banner Name (sets name and ID)
function Page1({bannerState, newBanner}) {
    const [nameHasChanged, setNameHasChanged] = useState(0)
    const [specialCharWarning, setSpecialCharWarning] = useState(false)
    const format = /[!@#$%^&£`~*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    function handleNameInput(e) {
        let value = e.target.value
        if (format.test(value)) {
            setSpecialCharWarning(true)
        } else {
            handleBannerInputChange(e, bannerState[1])
            setNameHasChanged(nameHasChanged + 1)
        }
    }

    useEffect(() => {
        let newId = newBanner.bannerName.replace(/ /g, "-").toLowerCase()
        handleBannerChange("bannerId", newId, bannerState[1])
    }, [nameHasChanged])

    return (
        <div className={"w-50 m-auto"}>
            <h3 style={{marginTop: "25%"}}>Banner Name</h3>
            <p>You cannot rename this banner once it's saved.</p>
            <Form>
                <Form.Group className={"mb-1"}>
                    <Form.Label className={"readers-only"}>Banner Name</Form.Label>
                    <Form.Control type={"text"} placeholder={"Banner Name"} className={"general-input"}
                                  onChange={(e) => {
                                      handleNameInput(e)
                                  }}
                                  value={bannerState[0].bannerName}
                                  id={"bannerName"}
                    />
                </Form.Group>
                {specialCharWarning &&
                <p className={"prime-colour text-small"}>Only letters and numbers are allowed.</p>}
            </Form>
        </div>
    );
}

export default Page1;