import {db} from "../../../dataManagement/firebaseConfig";

export const clListener = (setComments, setLikes) => {
    db.collection("likesComments")
        .onSnapshot((docs) => {
            //comments
            commentList(docs).then(c => {
                setComments(c)
            })
            LikesList(docs).then(l => {
                setLikes(l)
            })
        });
}
//Sort comments into most recent first
const commentList = (docs) => {
    return new Promise(resolve => {
        let comments = []
        docs.forEach(doc => {
            let d = doc.data()
            if (d.comments) {
                d.comments.forEach(comment => {
                    console.log(doc.data())
                    comment.title = d.title
                    comment.postId = doc.id
                    comments.push(comment)
                })
            }
        })
        comments.sort((a, b) => (a.commentId > b.commentId) ? 1 : ((b.commentId > a.commentId) ? -1 : 0))
        resolve(comments)
    })
}

//Sort Likes into highestFirst
const LikesList = (docs) => {
    return new Promise(resolve => {
        let likes = []
        docs.forEach(doc => {
            let d = doc.data()
            if (d.title) likes.push({title: d.title, likes: d.likes, id: d.id})
        })
        likes.sort((a, b) => (b.likes > a.likes) ? 1 : ((a.likes > b.likes) ? -1 : 0))
        resolve(likes)
    })
}


export const clUnsubscribe = () => {
    db.collection("likesComments")
        .onSnapshot(() => {
        });
}
