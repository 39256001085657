import React, {useContext} from 'react';
import Input from "../elements/Input";
import Texarea from "../elements/Texarea";
import {Form} from "react-bootstrap";
import TextEditor from "../textEditor/TextEditor";
import ImageLibrary from "../../imageManager/ImageLibrary";
import {NewsEditorContext} from "../../providers/NewsEditorContext";

function EventEditorForm() {
    const {article, handleChange} = useContext(NewsEditorContext)

    return <>
        {article &&
        <Form className={""}>
            {/*Title*/}
            <Input label={"Title"} keyToUpdate={"title"} value={article.title} handleChange={handleChange} />

            <hr />
            {/*Summary*/}
            <Texarea label={"Summary"} rows={4} keyToUpdate={"summary"} value={article.summary} handleChange={handleChange} />

            <hr />
            {/*Image*/}
            <div className={"mb-3 d-flex"}>
                <div>
                    <label className={"general-input__label"}>Main Image</label>
                    <ImageLibrary directory={"news/main-image"} handleImageChoice={(imgArr) => handleChange(imgArr, "img")} />
                </div>
                {article.img !== "" &&
                <img className={"ml-4"} style={{maxHeight: "6.7rem"}} src={article.img} alt={"Thumbnail"} />}
            </div>
            {/*Image Caption*/}
            <Input label={"Image Caption"} keyToUpdate={"alt"} value={article.alt} handleChange={handleChange} />

            <hr className={"mb-4"} />


            {/*Editor - BODY*/}
            <h4 style={{margin: "2rem auto 0"}}>Main Description</h4>
            <TextEditor value={article.body}
                        handleChange={handleChange}
                        keyToUpdate={"body"}
                        imageDirectory={`news/${article.id}/body`}
            />

        </Form>
        }
    </>
}

export default EventEditorForm;