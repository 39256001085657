import React from 'react';
import {ButtonGroup, ToggleButton} from "react-bootstrap";

//  1. {buttonArray}
//  ! Array of button objects
//  -> [{name: "Btn Name", value: num}]

//  2. {btnState}
//  !  The state array for the buttons (selected button state)
//  -> const [btnState, setBtnState] = useState(1)

//  3. {size}
//  !  Size of buttons
//  -> "sm" or "lg"
// = "lg

//  4. {onChangeFunc}
//  !  function to be called on change
//  =  "none"

//  5. {colourDark}
//  !  for darker colour
//  =  "false"


function ToggleButtons({buttonArray, btnState, size = "lg", onChangeFunc = false, colourDark = false}) {

    return (
        <>

            <ButtonGroup toggle>
                {buttonArray.map((radio, index) => (
                    <>
                        <ToggleButton
                            key={index}
                            className={!colourDark ? "btn-light" : "btn-light black-colour"}
                            size={size}
                            style={{
                                backgroundColor: btnState[0] !== radio.value ? "#eee" : "#ff9605",
                                color: btnState[0] !== radio.value ? "#000" : "#fff",
                                borderColor: btnState[0] !== radio.value ? "#eee" : "#ff9605",
                                zIndex: 0,
                            }}
                            // key={index}
                            type="radio"
                            name="radio"
                            value={radio.value}
                            checked={btnState[0] === radio.value}
                            onChange={(e) => {
                                btnState[1](radio.value)
                                onChangeFunc && onChangeFunc(radio.value)
                            }}
                        >
                            {radio.name}
                        </ToggleButton>
                    </>
                ))}
            </ButtonGroup>

        </>
    );
}

export default ToggleButtons;