import React from 'react';
import {Modal} from "react-bootstrap";

//  1. {showModalState}
//  ! state to show & hide modal window
//  -> const [showModal, setShawModal] = React.useState(false);

//  2. {title}
//  ! Modal window Title

//  3. {center}
//  ! Centered on page option
//  = false.

//  4. >children<

function PopupModal({
                        showModalState,
                        title,
                        center = false,
                        buttonText = "Close",
                        greenBtnText = "none",
                        greenBtnFunction,
                        children
                    }) {
    return (
        <Modal
            show={showModalState[0]}
            onHide={() => showModalState[1](false)}
            size="lg"
            aria-labelledby="title"
            centered={center}
            style={{zIndex: "99999"}}
            autoFocus={false}
        >

            <Modal.Header closeButton className={"px-5"}>
                <Modal.Title id="title" className={"font-weight-bold text-dark"}
                             style={{fontSize: "2.4rem"}}>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={"p-0"}>
                <div className={"px-5 py-3"}>
                    {children}
                </div>
            </Modal.Body>
            {(greenBtnText !== "none" || buttonText !== "none") &&
            <Modal.Footer>
                {greenBtnText !== "none" &&
                <button className={"btn-success background__success btn scbtn--small hover-scale"}
                        style={{borderRadius: "0"}}
                        onClickCapture={(e) => e.currentTarget.blur()}
                        onClick={greenBtnFunction}>{greenBtnText}</button>}

                {buttonText !== "none" &&
                <button className={"scbtn--2 scbtn--small"}
                        onClick={() => showModalState[1](false)}>{buttonText}</button>}
            </Modal.Footer>}
        </Modal>
    );
}

export default PopupModal;