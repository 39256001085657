import React, {useEffect, useState} from 'react';
import {createNewEvent} from "../factoryFunctions/eventFactory";
import {useParams} from "react-router-dom";
import useFetchData from "../customHooks/useFetchData";

export const EventEditorContext = React.createContext({})

const EventEditorProvider = ({children}) => {
    const [event, setEvent] = useState({})
    const {id} = useParams()
    const {data, err, pending, complete} = useFetchData("events", id, id === "new")

    // Fetch Data
    useEffect(() => {
        const eventData = id === "new" ? createNewEvent() : data ? data : {}
        setEvent(eventData)
        return () => setEvent({})
    }, [data, id])

    // Change Handler
    const handleChange = (data, key) => {
        event[key] = data
        setEvent({...event})
    }

    return (
        <EventEditorContext.Provider value={{event, handleChange, eventId: id, fetchErr: err, fetchPending: pending, fetchComplete: complete}}>
            {children}
        </EventEditorContext.Provider>
    );
}

export default EventEditorProvider;