import React, {useContext, useEffect, useState} from 'react';
import {auth} from "../dataManagement/firebaseConfig";
import {changePassword, createUser} from "./authHelpers";
import {useHistory} from "react-router-dom";
import CredContext from "../providers/CredContext";

//screens
import ChangePassword from "./ChangePassword";
import SignIn from "./SignIn";


function SignInContainer() {
    const [signInDetails, setSignInDetails] = useState({
        displayName: "",
        email: "",
        password: "",
        confirmPassword: "",
        oldPassword: "",
    });
    const [userResponse, setUserResponse] = useState({loading: false, success: null, message: null});
    const [renderChangePassword, setRenderChangePassword] = useState(false);
    const history = useHistory()
    const cred = useContext(CredContext)

    // Check if should render signup/login or change password
    useEffect(() => {
        if (cred && history.location.pathname.includes("/change-password")) {
            setRenderChangePassword(true)
        } else {
            setRenderChangePassword(false)
        }
    }, [])

// Handle all data change requests.
// Methods: 1 === login, 2 === signup, 3 === change password
    const handleSubmit = (e, method) => {
        e.preventDefault()
        setUserResponse({loading: true, success: null, message: null})
        if (method === 1) {
            //LOGIN
            auth.signInWithEmailAndPassword(signInDetails.email, signInDetails.password)
                .then(() => {
                    history.push("/")
                })
                .catch(() => finishProcess(false, false, "Incorrect username or password."))
        } else if (method === 2) {
            //CREATE ACCOUNT
            if (signInDetails.password.length >= 6 && signInDetails.password === signInDetails.confirmPassword) {
                createUser(signInDetails.email, signInDetails.password, signInDetails.displayName)
                    .then(userName => {
                        finishProcess(false, true,
                            <span>A new account has been created for <strong>{userName}</strong></span>)
                    })
                    .catch(err => {
                        if (err.code === "auth/email-already-in-use") {
                            finishProcess(false, false, "An account with this email address already exists.")
                        } else {
                            finishProcess(false, false, "Could not create a new account. Try again.")
                        }
                    })
            } else {
                checkPwdsMatch()
            }

        } else if (method === 3) {
            //CHANGE PASSWORD
            if (signInDetails.password.length >= 6 && signInDetails.password === signInDetails.confirmPassword) {
                changePassword(signInDetails.oldPassword, signInDetails.password)
                    .then(message => finishProcess(false, true, message))
                    .catch(message => finishProcess(false, false, message))
            } else {
                checkPwdsMatch()
            }
        }
    }

    // Reset Forms
    const finishProcess = (loading, success, message) => {
        if (success) {
            setSignInDetails({
                password: "", confirmPassword: "", oldPassword: "", displayName: "", email: ""
            })
        }
        setUserResponse({loading: loading, success: success, message: message})
    }

    // Check Passwords Match - for creating new account
    const checkPwdsMatch = () => {
        if (signInDetails.password.length < 6) {
            setUserResponse({
                loading: false,
                success: false,
                message: "Your password must be at least 6 characters long."
            })
        } else if (signInDetails.password !== signInDetails.confirmPassword) {
            setUserResponse({loading: false, success: false, message: "Your passwords do not match."})
        } else {
            setUserResponse({loading: false, success: false, message: ""})
        }
    }

    return (
        <>
            {!renderChangePassword ?
                <SignIn handleSubmit={handleSubmit} signInDetailsState={[signInDetails, setSignInDetails]}
                        userResponse={userResponse} checkPwdsMatch={checkPwdsMatch}/>
                :
                <ChangePassword handleSubmit={handleSubmit} signInDetailsState={[signInDetails, setSignInDetails]}
                                userResponse={userResponse} checkPwdsMatch={checkPwdsMatch}/>
            }
        </>
    );
}

export default SignInContainer;