import React, {useEffect, useState} from "react";
import PopupModal from "../../blocks/PopupModal";
import SectionSearch from "../../blocks/SectionSearch";
import useSearchQuery from "../../../customHooks/useSearchQuery";
import AddedList from "./AddedList";
import {checkIfObjInArr, removeObjFromArr} from "../../../helper";
import ResultList from "./ResultList";
import useFetchData from "../../../customHooks/useFetchData";
import {updateData} from "../../../dataManagement/database/dbFunctions";

function SeeAlsoModal() {
  const [searchResults, setSearchResults] = useState([])
  const [addedResults, setAddedResults] = useState([]);
  const [collectionToSearch, setCollectionToSearch] = useState("News");
  const [show, setShow] = useState(false);
  const [saveToDbStatus, setSaveToDbStatus] = useState({pending: false, success: false, err: false});
  const [query, setQuery] = useSearchQuery()
  const {data, pending, complete, handleChange,} = useFetchData("events", query?.see_also, !query?.see_also)

  useEffect(() => query?.see_also && setShow(true), [query])

  useEffect(() => {
    if (!show) {
      setQuery({})
      setAddedResults([])
      setSearchResults([])
    }
  }, [show])

  useEffect(() => data && setAddedResults(data.seeAlso || []), [complete])

  useEffect(() => complete && handleChange(addedResults, "seeAlso"), [addedResults])


  const handleChangeList = (event) => {
    if (checkIfObjInArr(addedResults, "id", event.id)) {
      let arr = removeObjFromArr(addedResults, "id", event.id)
      setAddedResults([...arr])
    } else {
      addedResults.push(event)
      setAddedResults([...addedResults])

    }
  }

  const handleSave = () => {
    setSaveToDbStatus({pending: true, success: false, err: false})
    updateData("seeAlso", addedResults, data.id, "events")
      .then(() => {
        setSaveToDbStatus({pending: false, success: true, err: false})
        setShow(false)
      })
      .catch(err => {
        setSaveToDbStatus({pending: false, success: false, err: true})
      })
  }


  return (
    <>
      <PopupModal title={`See Also: ${query.title}`}
                  greenBtnText={!saveToDbStatus.pending ? "Save" : "Saving..."}
                  greenBtnFunction={handleSave}
                  showModalState={[show, setShow]}
                  center
      >

        <h6 className={"line-height-tight mb-2"}>Search</h6>
        <div className={"d-flex mb-4"}>
          {["News", "Projects", "Events"].map(x => {
            return <button key={x}
                           className={"float-right mb-2 default-font-styles scbtn--1 scbtn--small " + (x === collectionToSearch && " background-prime white-colour")}
                           style={{maxHeight: "4.3rem"}}
                           onClick={() => setCollectionToSearch(x)}
            >
              {x}
            </button>
          })}
          <SectionSearch setSearchResults={setSearchResults} collection={collectionToSearch.toLowerCase()}
                         field={"title"} showErrBellow />
        </div>

        <AddedList events={addedResults} handleChangeList={handleChangeList} pending={pending} />

        <ResultList addedResults={addedResults} handleChangeList={handleChangeList}
                    searchResults={searchResults} />


      </PopupModal>
    </>
  );
}

export default SeeAlsoModal;