import React from 'react'
import FooterNavBar from "./footer/FooterNavBar";


function Footer() {
    return (
        <footer className={""} style={{height: "100px"}}>
            {/*<div className={"background-white h-100"}>*/}
            {/*<div className={"container"}>*/}
            {/*<FooterNavBar/>*/}
            {/*</div>*/}
            {/*</div>*/}
        </footer>
    )
}

export default Footer
