import React, {useEffect, useState} from "react";
import {createNewArticle} from "../factoryFunctions/newsFactory";
import {useParams} from "react-router-dom";
import useFetchData from "../customHooks/useFetchData";
import {getDocById, setDocAndUpdateStat} from "../dataManagement/database/dbFunctions";

export const NewsEditorContext = React.createContext({})

const NewsEditorProvider = ({children}) => {
  const [article, setArticle] = useState({})
  const {id} = useParams()
  const {data, err, pending, complete} = useFetchData("news", id, id === "new")

  // Fetch Data
  useEffect(() => {
    const newsData = id === "new" ? createNewArticle() : data ? data : {}
    setArticle(newsData)
    return () => setArticle({})
  }, [data, id])

  //Change Handler
  const handleChange = (data, key) => {
    article[key] = data
    setArticle({...article})
  }

  // pre-save callback
  const handlePreSave = (docId, title) => {

    // Helper Function - Save likes & comments
    const saveDoc = (likes, comments, increment, title, docId) => {
      console.log("likes: ", likes, "comments: ", comments, "Inc: ", increment, title, docId)
      return new Promise((resolve, reject) => {
        setDocAndUpdateStat("likesComments", docId, {
          title: title,
          likes: likes,
          comments: comments,
        }, "docCount", increment)
          .then(() => resolve())
          .catch(() => reject())
      })
    }

    // Main Function
    const isNew = (id === "new")
    return new Promise(async (resolve, reject) => {
      // If edit then get doc first
      if (!isNew) {
        resolve()
      } else saveDoc(0, [], 1, title, docId)
        .then(() => resolve())
        .catch(() => reject())
    })
  }

  return (
    <NewsEditorContext.Provider
      value={{
        article,
        handleChange,
        articleId: id,
        fetchErr: err,
        fetchPending: pending,
        fetchComplete: complete,
        handlePreSave
      }}
    >
      {children}
    </NewsEditorContext.Provider>
  );
}

export default NewsEditorProvider;