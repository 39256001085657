import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {ModelBannerContent} from "./BannerPopups";
import Page4QuickContentMaker from "./Page4QuickContentMaker";

function Page4Content({contentI, handleContentChange, allContent, pos1, pos2, pos3, i}) {

    return <>
        <div key={i} className={"mb-2"}>
            <Form>
                <div key={`inline-radios`} className="pl-0 custom-radio custom-control general-input border-0 mb-2">
                    <Form.Check inline label="Quick and easy" type={"radio"}
                                checked={!contentI.custom} value={!contentI.custom}
                                onChange={(e) => handleContentChange(e, i, "custom")}/>
                    <Form.Check inline label="Custom" type={"radio"}
                                checked={contentI.custom} value={contentI.custom}
                                onChange={(e) => handleContentChange(e, i, "custom")}/>
                </div>

            </Form>

            {!contentI.custom ?
                <>
                    <Page4QuickContentMaker handleChange={handleContentChange} i={i} val={contentI.contentQuick}/>
                </>
                :
                <>
                    <ModelBannerContent editorValue={contentI.contentCustom}
                                        handleContentChange={handleContentChange} index={i}/>
                    <br/>
                    <br/>
                </>
            }

            <Form>
                {/*<div className="mb-3 pl-0 custom-radio custom-control">*/}

                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className={"mb-1 mr-2 text-small general-input__label"}>
                                Text Alignment
                            </Form.Label>
                            <Form.Control as="select" className={"general-input"}
                                          value={i === 0 ? allContent.content1.textAlign : i === 1 ? allContent.content2.textAlign : allContent.content3.textAlign}
                                          onChange={(e) => handleContentChange(e, i, "textAlign")}>
                                <option value={"text-left"}>Left</option>
                                <option value={"text-center"}>Centre</option>
                                <option value={"text-right"}>Right</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Label className={"mb-1 mr-2 text-small general-input__label"}>
                            Content Alignment Y
                        </Form.Label>
                        <Form.Group>
                            <Form.Label className={"readers-only"}>Vertical</Form.Label>
                            <Form.Control as="select" className={"general-input"}
                                          value={i === 0 ? pos1.vert : i === 1 ? pos2.vert : pos3.vert}
                                          onChange={(e) => handleContentChange(e, i, "vertical")}>
                                <option value={"content__top"}>Top</option>
                                <option value={"content__middle"}>Middle</option>
                                <option value={"content__bottom"}>Bottom</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Label className={"mb-1 mr-2 text-small general-input__label"}>
                            Content Alignment X
                        </Form.Label>
                        <Form.Group>
                            <Form.Label className={"readers-only"}>Horizontal</Form.Label>
                            <Form.Control as="select" className={"general-input"}
                                          value={i === 0 ? pos1.hoz : i === 1 ? pos2.hoz : pos3.hoz}
                                          onChange={(e) => handleContentChange(e, i, "horizontal")}>
                                <option value={"left"}>Left</option>
                                <option value={"centre"}>Centre</option>
                                <option value={"right"}>Right</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
}

export default Page4Content;