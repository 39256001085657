import React from 'react'
import {Spinner} from "react-bootstrap";

function Loader({showLoader = true, spinner = false, wait = false}) {
    return (
        <>
            {showLoader && !spinner ?
                <div className={"text-center"}>
                    <div className={"loader mx-auto mt-5"}>
                        <div className={"loader__bar-line--1 position-absolute"}/>
                        <img src={"/images/icons/Treble_Clef.svg"} className={"loader__item loader__clef"}
                             style={{animationDelay: "-1s"}} alt={"croche-1"}/>
                        <img src={"/images/icons/Bemol.svg"} className={"loader__item loader__bemol--1"} alt={"bemal-1"}
                             style={{animationDelay: "-.8s"}}/>
                        <img src={"/images/icons/Bemol.svg"} className={"loader__item loader__bemol--2"} alt={"bemol-2"}
                             style={{animationDelay: "-.6s"}}/>
                        <img src={"/images/icons/Bemol.svg"} className={"loader__item loader__bemol--3"} alt={"bemol-3"}
                             style={{animationDelay: "-.5s"}}/>
                        <div className={"loader__item loader__nums"} style={{animationDelay: "-.3s"}}>
                            <p className={"m-0 loader__nums--p"}>2</p>
                            <p className={"m-0 loader__nums--p"}>4</p>
                        </div>
                        <img src={"/images/icons/soupir.svg"} className={"loader__item loader__soupir"} alt={"croche-1"}
                             style={{animationDelay: "-.2s"}}/>
                        <img src={"/images/icons/croche.svg"} className={"loader__item loader__note--1"}
                             alt={"croche-2"}
                             style={{animationDelay: "0s"}}/>
                        <img src={"/images/icons/double_croches.svg"} className={"loader__item loader__note--2"}
                             alt={"croche-2"} style={{animationDelay: ".3s"}}/>
                        <div className={"loader__bar-line--2 position-absolute"} style={{animationDelay: ".4s"}}/>
                        <img src={"/images/icons/blanche.svg"} className={"loader__item loader__note--3"}
                             alt={"blanche"}
                             style={{animationDelay: ".6s"}}/>
                        <div className={"loader__line"}/>
                        <div className={"loader__line"}/>
                        <div className={"loader__line"}/>
                        <div className={"loader__line"}/>
                        <div className={"loader__line mb-0"}/>
                    </div>
                    <p className={"loader__loading"}>{wait ? "Please Wait" : "Loading"}</p>
                </div>
                : showLoader && spinner ?
                    <Spinner animation={"border"}/>
                    :
                    null}
        </>
    )
}

export default Loader
