import React from 'react';
import {Row} from "react-bootstrap";
import {eventDateFormat} from "../../helper";

function EventDateTime({dateTime, classname}) {

    return <Row className={classname}>
        <ul className={"p-0 mt-2"}>
            {dateTime && dateTime.map((date, i) => {
                return <li className={"list-unstyled mb-1 line-height-tight"} key={i}>{eventDateFormat(date)}</li>
            })}
        </ul>
    </Row>
}

export default EventDateTime;

