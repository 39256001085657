// General use, when only one request
export const CONTENT_REQUEST = ["CONTENT_REQUEST", "CONTENT_SUCCESS", "CONTENT_FAIL"]

// banner
export const BANNER_REQUEST = ["BANNER_REQUEST", "BANNER_SUCCESS", "BANNER_FAIL"]

// Message strip
export const MESSAGE_REQUEST = ["MESSAGE_REQUEST", "MESSAGE_SUCCESS", "MESSAGE_FAIL"]

// News
export const NEWS_REQUEST = ["NEWS_REQUEST", "NEWS_SUCCESS", "NEWS_FAIL"]

// Events
export const EVENTS_REQUEST = ["EVENTS_REQUEST", "EVENTS_SUCCESS", "EVENTS_FAIL"]

// Policies
export const POLICIES_REQUEST = ["EVENTS_REQUEST", "EVENTS_SUCCESS", "EVENTS_FAIL"]

// General Management
export const GENERAL_REQUEST = ["GENERAL_REQUEST", "GENERAL_SUCCESS", "GENERAL_FAIL"]

// Auth
export const AUTH_REQUEST = ["AUTH_REQUEST", "AUTH_SUCCESS", "AUTH_FAIL"]

// Footer
export const FOOTER_REQUEST = ["FOOTER_REQUEST", "FOOTER_SUCCESS", "FOOTER_FAIL"]