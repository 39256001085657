import React, {useContext, useEffect, useState} from "react";
import {EventEditorContext} from "../../../providers/EventEditorContext";
import Input from "../../elements/Input";
import ToggleButtons from "../../elements/ToggleButtons";
import EventNotesEditorForm from "./Event_NotesEditorForm";
import Event_NotesSurveyBtnOrder from "./Event_NotesSurveyBtnOrder";
import InputWithList from "../../elements/InputWithList";
import {Collapse} from "react-bootstrap";
import DateTimeGenerator from "../../blocks/DateTimeGenerator";
import Event_NotesSurveyAndDonateTabs from "./Event_NotesSurveyAndDonateTabs";

function Event_NotesContainer() {
  const {event, handleChange, eventId, fetchErr, fetchComplete} = useContext(EventEditorContext)
  const [notesBtnState, setNotesBtnState] = useState(event.programmeNotes.showNotes ? 1 : 2)
  const [useExpire, setUseExpire] = useState(event.programmeNotes.useExpire ? 1 : 2)


  const onNoteChanges = (val, key) => {
    event.programmeNotes[key] = val
    handleChange(event.programmeNotes, "programmeNotes")
  }

  console.log("CTA: ", event.programmeNotes)

  return (
    <>
      {/*Notes Online Toggle*/}
      <h3 className={"mt-5"}>{event.title}</h3>
      <div className={"mb-3 mr-3 d-flex"}>
        <div>
          <label className={"general-input__label mr-4 mb-0"}>Online</label>
          <ToggleButtons btnState={[notesBtnState, setNotesBtnState]}
                         onChangeFunc={v => (onNoteChanges(v === 1, "showNotes"))}
                         buttonArray={[{name: "Yes", value: 1}, {name: "No", value: 2}]} />
        </div>
        <div className={"mb-3 ml-5"}>
          <label className={"general-input__label mr-4 mb-0"}>Use expire date to take notes offline</label>
          <ToggleButtons btnState={[useExpire, setUseExpire]} onChangeFunc={v => (onNoteChanges(v === 1, "useExpire"))}
                         buttonArray={[{name: "Yes", value: 1}, {name: "No", value: 2}]} />
        </div>
      </div>

      <div style={{overflow: "hidden"}}>
        <Collapse in={useExpire === 1}>
          <div>
            <label className={"general-input__label mr-4 mb-0"}>Expire Date</label>
            <DateTimeGenerator date={event.programmeNotes.expireDate}
                               handleChange={d => onNoteChanges(d, "expireDate")} />
          </div>
        </Collapse>
      </div>

      {/* Notes Editors */}
      <EventNotesEditorForm onNoteChanges={onNoteChanges} />

      {/* Notes Survey */}
      <br />
      <h5>Survey & Donate Buttons</h5>
      <Event_NotesSurveyAndDonateTabs event={event} onNoteChanges={onNoteChanges} />
      <Event_NotesSurveyBtnOrder onNoteChanges={onNoteChanges} positions={event.programmeNotes.surveyBtnPositions} />

    </>
  );
}

export default Event_NotesContainer;