import React from 'react';

function ScreenContainer({children}) {
    return (
        <main className={"container"}>
            {children}
        </main>
    );
}

export default ScreenContainer;