import React, {useEffect, useState} from 'react';
import {getAllData, setData} from "../dataManagement/database/dbFunctions";
import {Col, Row} from "react-bootstrap";
import UserEditorModal from "./userSettings/UserEditorModal";

function AccountManager() {
    const [users, setUsers] = useState(null)
    const [modalUid, setModalUid] = useState(false);
    const [user, setUser] = useState({});
    const [savingStatus, setSavingStatus] = useState({pending: false, success: false, message: null});

    // Fetch all user data from db
    useEffect(() => {
        getAllData("users").then(data => {
            setUsers(data.filter(u => !u.principal))
        })
    }, [])

    useEffect(() => {
        setSavingStatus({pending: false, success: false, message: null})
    }, [modalUid])

    const setUserToUpdate = (id) => {
        setUser(users[id])
    }

    const handleDeletedUser = (uid) => {
        setUsers(users.filter(u => {
            return u.userId !== uid
        }))
    }

    //Update db
    const updateUserToDb = (mapId) => {
        setSavingStatus({pending: true, success: false, message: null})
        setData("users", modalUid, user)
            .then(() => {
                setSavingStatus({pending: false, success: true, message: "Updates have been saved"})
                let tempArr = users
                tempArr[mapId] = user
                setUsers(tempArr)
            })
            .catch(() => {
                setSavingStatus({
                    pending: false,
                    success: false,
                    message: "There was a problem trying to save your changes. Please try again."
                })
            })
    }

    //style for access labels
    let accessStyle = "mr-4 m-0 d-inline-block background-colour-grey"

    if (users && !users[0]) return <p style={{opacity: ".6"}}>No accounts found</p>

    return (
        <>
            {users && users.filter(u => !u.principal).map((user, i) => {
                let access = user.access
                return <div className={"border border-thin mb-3"}
                            key={i}>
                    <div className={"background-light-grey p-3 position-relative"}>
                        <h6><b>{user.displayName}</b></h6>

                        <button type={"button"} className={"scbtn--1 scbtn--small position-absolute"}
                                style={{top: "1.5rem", right: "1rem"}}
                                onClick={(e) => {
                                    setModalUid(user.userId)
                                    e.currentTarget.blur()
                                }}>
                            Edit
                        </button>
                    </div>
                    <div className={"p-3 pb-4"}>

                        <Row>
                            <Col xs={"auto"}>
                                <p className={"mb-0 pr-2 line-height-tight"}><b>Account:</b></p>
                                <p className={"mb-0 pr-2 line-height-tight"}><b>Email:</b></p>
                                {!user.admin && <p className={"mb-0 pr-2 mt-2"}><b>Access rights:</b></p>}
                            </Col>
                            <Col>
                                <p className={"mb-0 line-height-tight"}>{user.principal === true ? "Site Manager" : user.admin === true ? "Admin" : "Editor"}</p>
                                <p className={"mb-0 line-height-tight"}>{user.email}</p>

                                {!user.admin &&
                                <span className={"d-flex flex-wrap mt-2"}>
                                    {access.home && <p className={accessStyle}>Home</p>}
                                    {access.events && <p className={accessStyle}>Events</p>}
                                    {access.news && <p className={accessStyle}>News</p>}
                                    {access.about && <p className={accessStyle}>About</p>}
                                    {access.support && <p className={accessStyle}>Support Us</p>}
                                    {access.legal && <p className={accessStyle}>Legal Pages</p>}
                                    {access.headerFooter && <p className={accessStyle}>Header & Footer</p>}
                                    {access.message && <p className={accessStyle}>Message Strip</p>}
                                    </span>}
                            </Col>

                            {!user.admin &&
                            <></>}
                        </Row>

                    </div>

                    <UserEditorModal setUserToUpdate={setUserToUpdate} mapId={i} modalUidState={[modalUid, setModalUid]}
                                     user={[user, setUser]} updateUserToDb={updateUserToDb}
                                     savingStatus={savingStatus} handleDeletedUser={handleDeletedUser}/>

                </div>
            })}

        </>
    );
}

export default AccountManager;