import React from 'react';
import {Col, Row} from "react-bootstrap";

function MessageManager({dataState: [data, setData], changeListState: [changeList, setChangeList]}) {

    const handleSelect = (val, i) => {
        let tempArr = data
        if (val === "offline") {
            tempArr[i].name = val
            tempArr[i].message = ""
        } else {
            let obj = tempArr[9].library.find(obj => obj.name === val)
            tempArr[i].name = obj.name
            tempArr[i].message = obj.message
        }
        setData([...tempArr])
        setChangeList(prevState => {
            return [...prevState, i]
        })
    }

    return <>
        <Row>
            {data && data[0] && data[0].page && data.filter(d => d.page !== "library").map((d, i) => {
                console.log(d.page)
                return <Col key={i} lg={4}>
                    <div className={"position-relative text-nowrap"}>
                        <label className={"general-input__label mb-0"}>{d.page}</label>
                        <span
                            className={"ml-2 position-relative " + (changeList.includes(i) ? "prime-colour" : (d.name === "offline" || data[9].library.includes(d)) ? "text-danger" : "text-success")}
                            style={{fontSize: "1rem", bottom: "-.1rem", height: "0"}}>&#9632;</span>
                    </div>
                    <div className={"mb-3"}>
                        <select value={d.name}
                                onChange={(e) => handleSelect(e.target.value, i)}
                                className={"general-input__label"} style={{width: "27rem"}}>
                            <option value={"offline"}>No Message</option>
                            {data[data.length - 1].library.map((l, index) => {
                                return <option key={index} value={l.name}>{l.name}</option>
                            })}
                        </select>
                    </div>
                </Col>
            })}
        </Row>
    </>
}

export default MessageManager;

