import React, {useState} from 'react';
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";
import EventDateTime from "../components/events/EventDateTime";
import {Col, Row} from "react-bootstrap";
import PageTop from "../components/blocks/PageTop";
import SectionManageBtns from "../components/sectionManagerButtons/SectionManageBtns";
import SectionSearch from "../components/blocks/SectionSearch";
import SeeAlsoModal from "../components/events/SeeAlso/SeeAlsoModal";

function Events() {
    const [events, setEvents] = useState([])
    const [searchResults, setSearchResults] = useState([])

    return <>
        <PageTop section={"events"} title={"Event Manager"} />

        <FetchWithEndlessScroll currentPageDocsState={[events, setEvents]} type={"events"}
                                pauseFetch={searchResults[0]}>

            {/*Search bar*/}
            <SectionSearch setSearchResults={setSearchResults} collection={"events"} field={"title"} />

            <section className="container">
                {events && (searchResults[0] ? searchResults : events).map((event, i) => {
                    return <Row key={i} className={"scPast search-card"}>

                        <Col md={6} style={{padding: "0"}}>
                            {/*Titles*/}
                            <h2 style={{fontSize: "2.5rem"}}>{event.title}</h2>
                            <h3>{event.subtitle}</h3>

                            {/*Venue / Date*/}
                            <div className={"m-0"}>
                                <ul className={"m-0 list-unstyled"}>
                                    {event.venue &&
                                    <li className={"m-0"} style={{fontWeight: "400"}}>{event.venue}</li>}
                                </ul>
                                <EventDateTime dateTime={event.eventDates}
                                               classname={"search-card__pe-date"} />
                            </div>

                            {/*Summary*/}
                            {event.summary && event.summary.length > 60 ?
                                <p className={"d-block w-100 m-0"}>{event.summary.substring(0, 57)}...</p>
                                :
                                <p className={"d-block w-100 m-0"}>{event.summary}</p>}
                        </Col>


                        <Col md={6}>
                            {/*Buttons*/}
                            <SectionManageBtns
                                itemState={searchResults[0] ? [searchResults, setSearchResults] : [events, setEvents]}
                                page={"events"} index={i} dbCollection={"events"} />
                        </Col>


                    </Row>
                })}
            </section>
        </FetchWithEndlessScroll>

        {/* SEE ALSO SEARCH MODAL */}
        <SeeAlsoModal />
    </>
}

export default Events;