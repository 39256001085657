import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {getDocById, searchWithIdArray,} from "../../dataManagement/database/dbFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

function SectionSearch({setSearchResults, collection, showErrBellow = false}) {
  const [search, setSearch] = useState("");
  const [resultCount, setResultCount] = useState(null);
  const [msg, setMsg] = useState("")

  const handleSearch = async (e) => {
    e.preventDefault()
    setMsg("Searching...")
    setResultCount(null)
    // If search is less than 3 chars
    if (search.length < 3) {
      setSearchResults([])
      setResultCount(0)
      if (search.length) setMsg("At least 3 characters are needed.")
      else setMsg(" ")
      return
    }
    // Get search titles/ids from search helper
    const searchRes = await getDocById(collection, "--collection-search--")
    // if no results - the query went wrong
    if (!searchRes) {
      setMsg("Something went wrong, please try again.")
      return
    }
    // Search results for matches between user search and titles
    let results = []
    searchRes.searchHelper.forEach((obj) => {
      if (results.length !== 9 && obj.id) {
        if (obj.title.toLowerCase().includes(search.toLowerCase())) results.push(obj.id)
      }
    })
    // If no matches, return the function and set serach count to 0
    if (results.length === 0) {
      setSearchResults([])
      setResultCount(0)
      setMsg("")
      return
    }
    // is matches, get all matched documents and set search results
    searchWithIdArray(collection, results)
      .then(docs => {
        console.log("RES: ", docs, results)
        setSearchResults(docs.reverse())
        setResultCount(docs.length)
        if (docs.length === 9) setMsg("There may be more, result limit has been reached.")
        else setMsg("")
      })
      .catch(() => setMsg("Something went wrong, please try again."))
  }

  //Clear Results on clear btn
  const handleClear = () => {
    setSearch("")
    setSearchResults([])
    setMsg("")
    setResultCount(null)
  }

  return <form onSubmit={handleSearch} className={"w-100 d-flex mb-2"}>
    <div className={"d-flex d-inline ml-auto position-relative"}>
      <div className={"mr-2"}>
        {msg !== "" ? <p className={"text-right line-height-tight text-small"}>{msg}</p> :
         resultCount !== null &&
           <p className={"text-right line-height-tight text-small"}>{resultCount === 0 ? "No" : resultCount} Results
             Found</p>}
      </div>
      <div>
        <div className={"d-flex ml-auto"}>
          <input className={"general-input w-100"} placeholder={"Search"} value={search}
                 style={{paddingRight: "26px", minWidth: "23rem"}}
                 onChange={e => setSearch(e.target.value)} />
          <button className={"position-relative border-0 bg-transparent p-0 my-0 light-grey-colour hover-prime"}
                  style={{marginLeft: "-22px", marginRight: "12px", top: "-1px"}} type={"button"}
                  onClick={() => handleClear()}>
            <FontAwesomeIcon size={"2x"} icon={faTimes} />
          </button>
          <input className={"general-input scbtn--1 background-white"} type={"submit"}
                 value={"Submit"} id={"searchBtn"} style={{borderColor: "#ff9605"}} />
        </div>
      </div>
    </div>
  </form>
}

export default SectionSearch;