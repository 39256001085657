import React, {useEffect, useState} from 'react';
import PageTop from "../components/blocks/PageTop";
import TextEditor from "../components/textEditor/TextEditor";
import {FetchContent} from "../dataManagement/FetchContent";
import UpdateDocumentField from "../dataManagement/UpdateDocumentField";
import SupportUsBtns from "../components/supportUs/SupportUsBtns";
import ToggleButtons from "../components/elements/ToggleButtons";

function SupportUs() {
    const [buttons, setButtons] = useState([])
    const [toggleState, setToggleState] = useState(1)

    //Text Editor Change Handler
    const handleChange = (data, key) => {
        let tempObj = buttons[0] && buttons[0].content
        tempObj[key] = data
        setButtons([{content: tempObj, fetchId: "supportPage"}])
    }

    const handleToggle = (value) => {
        buttons[0] && handleChange(value === 2, "additionalIsCustom")
    }

    useEffect(() => {
        buttons[0] && buttons[0].content && setToggleState(buttons[0].content.additionalIsCustom ? 2 : 1)
    }, [buttons])

    return <>
        <PageTop title={"Support us"} section={"support"} />

        <FetchContent contentState={[buttons, setButtons]} table={"general"} conditions={"supportPage"}>

            {buttons[0] && buttons[0].content &&
            <>
                <label className={"general-input__label"}>Main Appeal</label>
                <TextEditor handleChange={handleChange} value={buttons[0].content.body} imageDirectory={"support/body"}
                            keyToUpdate={"body"} />

                <SupportUsBtns array={buttons[0].content.buttons} handleChange={handleChange} />

                <div className={"mb-3"}>
                    <ToggleButtons buttonArray={[{name: "Quick", value: 1}, {name: "Custom", value: 2}]}
                                   btnState={[toggleState, setToggleState]} onChangeFunc={handleToggle} />
                    <p className={"text-small"}>Quickly provide additional information which is displayed as small text,
                        or create your own custom information.</p>
                </div>
                <label className={"general-input__label"}>
                    Additional Information | {toggleState === 1 ? "Quick" : "Custom"}
                </label>

                <div className={toggleState === 2 ? "collapse" : ""}>
                    <TextEditor handleChange={handleChange} value={buttons[0].content.additionalInfo}
                                imageDirectory={"support/additionalInfo"}
                                keyToUpdate={"additionalInfo"} toolbar={"none"} />
                </div>
                <div className={toggleState === 1 ? "collapse" : ""}>
                    <TextEditor handleChange={handleChange} value={buttons[0].content.additionalCustom}
                                imageDirectory={"support/additional"}
                                keyToUpdate={"additionalCustom"} />
                </div>

            </>}


        </FetchContent>
        {buttons[0] &&
        <UpdateDocumentField updateValue={buttons[0].content} updateKey={"content"} collection={"general"}
                             docId={"supportPage"} />}
    </>
}

export default SupportUs;