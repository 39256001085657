import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Col, Row} from "react-bootstrap";
import Loader from "../../dataManagement/components/Loader";


function MessageCreator({data, handleChange}) {
    const [tempArray, setTempArray] = useState(data)
    const hasRendered = useRef(false)

    console.log("EMAIL DATA: ", data, tempArray)

    useEffect(() => {
        hasRendered.current && handleChange(tempArray, "emailTo")
    }, [tempArray])

    useEffect(() => {
        hasRendered.current = true
    }, [])

    if (!data || !tempArray) return <Loader />
    return <>
        {tempArray[0] && tempArray.map((e, i) => {
            return <div key={i} className={"mb-4"}>
                <Row className={"pb-3"}>
                    <Col className={"d-block"}>
                        <ArrayForm email={e} state={[tempArray, setTempArray]} index={i} />
                    </Col>
                    <Col xl={"auto"} className={"d-block"} style={{height: "4.1rem", width: "5rem"}}>
                        <ArrayBtns last={i + 1 !== tempArray.length} first={i !== 0} index={i}
                                   state={[tempArray, setTempArray]} />
                    </Col>
                </Row>
            </div>
        })}
        <div className={"mb-3 text-right"}>
            <button type={"button"} className={"scbtn--med scbtn--1"} onClick={() => {
                setTempArray(prevState => {
                    return [...prevState, {email: ""}]
                })
            }}>
                Add
            </button>
        </div>
    </>
}

export default MessageCreator;


///////////////////// Form ///////////////////////

function ArrayForm({state: [array, setArray], email, index}) {
    const handleChange = (v, n) => {
        let tempArr = array
        tempArr[index][n] = v
        setArray([...tempArr])
    }

    return <>
        <input type={"email"} placeholder={"Email Address"} className={"general-input mb-2"}
               value={email.email} onChange={(e) => handleChange(e.target.value, "email")} />
    </>
}

///////////////////// Buttons ///////////////////////

function ArrayBtns({index, state: [array, setArray]}) {
    const [helpText, setHelpText] = useState(" ")

    const handleDelete = () => {
        let tempArr = array
        tempArr.splice(index, 1);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"position-absolute"} style={{bottom: "0", right: "0"}}>
                <Btn value={<FontAwesomeIcon icon={faTrashAlt} />} handleChange={handleDelete}
                     setHelp={setHelpText} helpText={"Delete"} />
            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, setHelp, helpText, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "2rem", lineHeight: ".6"}}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onMouseEnter={() => setHelp(helpText)} onMouseLeave={() => setHelp("")}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {value}
        </button>
    </>
}