import React, {useEffect, useState} from 'react';
import {createNewArticle} from "../factoryFunctions/newsFactory";
import {useParams} from "react-router-dom";
import useFetchData from "../customHooks/useFetchData";

export const PoliciesEditorContext = React.createContext({})

const PoliciesEditorProvider = ({children}) => {
    const [policy, setPolicy] = useState({})
    const {id} = useParams()
    const {data, err, pending, complete} = useFetchData("policies", id, id === "new")

    // Fetch Data
    useEffect(() => {
        const policiesData = id === "new" ? createNewArticle() : data ? data : {}
        setPolicy(policiesData)
        return () => setPolicy({})
    }, [data, id])

    //Change Handler
    const handleChange = (data, key) => {
        policy[key] = data
        setPolicy({...policy})
    }

    return (
        <PoliciesEditorContext.Provider
            value={{policy, handleChange, policyId: id, fetchErr: err, fetchPending: pending, fetchComplete: complete}}
        >
            {children}
        </PoliciesEditorContext.Provider>
    );
}

export default PoliciesEditorProvider;