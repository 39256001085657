import React from 'react';
import {Col, Row} from "react-bootstrap";
import PageTitle from "../components/elements/PageTitle";
import CommentsAndLikesContainer from "../components/startScreen/commentsAndLikes/CommentsAndLikesContainer";
import StatsContainer from "../components/startScreen/StatsContainer";

function StartScreen() {
    return <>
        <PageTitle title={"Dashboard"}/>
        <Row style={{minHeight: "80vh"}}>
            <Col lg={6} style={{height: "90rem"}}>
                <StatsContainer/>
            </Col>
            <Col lg={6} style={{height: "90rem"}}>
                <CommentsAndLikesContainer/>
            </Col>
        </Row>

    </>
}

export default StartScreen;