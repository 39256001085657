import React, {useEffect, useState} from 'react';
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import {ModelExitWithoutSaving} from "./BannerPopups";
import {ButtonsSaveAndExit, ButtonsNextAndPrev} from "./BannerButtons";
import {saveBannerToDb} from "./BannerHelper";
import {getDocById} from "../../dataManagement/database/dbFunctions";

function BannerCreator({setShowCreator, bannerNames, bannerId, bannerOnDb}) {
    const [newBanner, setNewBanner] = useState({
        docId: bannerId,
        bannerId: "",
        bannerName: "",
        img: "",
        alt: "",
        imagePosition: "middle-centre",
        overlayColour: "rgba(86, 8, 9, 0)",
        writtenContent: {
            content1: {
                custom: false,
                contentQuick: [{text: "", type: "o-h2-lg", bg: "n", weight: "900"}],
                contentCustom: "",
                position: "content__middle-centre",
                textAlign: "text-center",
            },
            content2: {
                custom: false,
                contentQuick: [{text: "", type: "o-h2-lg", bg: "n", weight: "900"}],
                contentCustom: "",
                position: "content__middle-centre",
                textAlign: "text-center",
            },
            content3: {
                custom: false,
                contentQuick: [{text: "", type: "o-h2-lg", bg: "n", weight: "900"}],
                contentCustom: "",
                position: "content__middle-centre",
                textAlign: "text-center",
            }
        },
        links: {
            link1: {link: "", applyTo: "nl", type: "o-link", size: "md", text: ""},
            link2: {link: "", applyTo: "nl", type: "o-link", size: "md", text: ""},
            link3: {link: "", applyTo: "nl", type: "o-link", size: "md", text: ""},
        },
    })
    const [pageNum, setPageNum] = useState(1)
    const [showExitConfirm, setShowExitConfirm] = useState(false)
    const [postStatus, setPostStatus] = useState({pending: false, saved: false, error: false})
    const [originalDoc, setOriginalDoc] = useState({});
    const [matches, setMatches] = useState(true);

    useEffect(() => {
        setMatches(originalDoc === newBanner)
    }, [newBanner, originalDoc])

    const setOriginal = () => {
        setOriginalDoc(newBanner)
    }

    useEffect(() => {
        if (bannerOnDb) {
            setPageNum(2)
            getDocById("banner", bannerId)
                .then((doc) => {
                    setOriginalDoc(doc)
                    setNewBanner(doc)
                })
        }
    }, [])

    function saveBanner() {
        saveBannerToDb(newBanner, setPostStatus, setNewBanner)
        setOriginalDoc(newBanner)
        setNewBanner(newBanner)
    }

    return (
        <>
            <div className={"position-fixed border-dark-grey background-colour"}
                 style={{
                     height: "100vh",
                     width: "100vw",
                     overflowY: "scroll",
                     top: "0",
                     left: "0",
                     zIndex: "99999"
                 }}>

                <div className={"d-flex justify-content-between position-sticky background-white pt-3 px-5"}
                     style={{zIndex: "999", height: "6rem", top: "0"}}>

                    <div className={"d-flex align-content-center"}>
                        <h3 style={{fontWeight: "600"}}>{newBanner.bannerName}</h3>
                    </div>


                    <ButtonsSaveAndExit pageNum={pageNum} setShowCreator={setShowCreator}
                                        setShowExitConfirm={setShowExitConfirm}
                                        saveBanner={saveBanner} postStatus={postStatus}
                                        safeToExit={matches}
                    />
                </div>

                <ModelExitWithoutSaving showExitConfirmState={[showExitConfirm, setShowExitConfirm]}
                                        setShowCreator={setShowCreator}/>


                <div className={"hide-small-screen w-100"}>

                    <div className={"d-flex"} style={{height: "0"}}>
                        <p className={"d-inline-block font-colour ml-auto mr-5 pr-1 pt-4"} style={{opacity: ".6"}}>
                            {!bannerOnDb ? pageNum + "/4" : pageNum - 1 + "/3"}
                        </p>
                    </div>

                    <section>
                        {pageNum === 1 ?
                            <Page1 bannerState={[newBanner, setNewBanner]} newBanner={newBanner}/>
                            : pageNum === 2 ?
                                <Page2 bannerState={[newBanner, setNewBanner]}/>
                                : pageNum === 3 ?
                                    <Page3 bannerState={[newBanner, setNewBanner]} bannerOnDb={bannerOnDb}/>
                                    : pageNum === 4 ?
                                        <Page4 bannerState={[newBanner, setNewBanner]} bannerOnDb={bannerOnDb}/>
                                        : null}
                    </section>

                    <ButtonsNextAndPrev pageNumState={[pageNum, setPageNum]} namesArray={bannerNames}
                                        newName={newBanner.bannerName} bannerOnDb={bannerOnDb}
                                        safeToExit={matches} setOriginal={setOriginal}
                    />
                </div>


                <div className={"w-50 m-auto show-small-screen"}>
                    <h3 style={{marginTop: "25%"}}>
                        You need a bigger screen to use the banner creator.
                    </h3>
                </div>
            </div>
        </>
    );
}

export default BannerCreator;

