import React, {useState} from 'react';
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";
import PageTop from "../components/blocks/PageTop";
import SectionSearch from "../components/blocks/SectionSearch";
import {Col, Row} from "react-bootstrap";
import SectionManageBtns from "../components/sectionManagerButtons/SectionManageBtns";
import {policyDateFormat} from "../helper";

function Policies() {
    const [policies, setPolicies] = useState([])
    const [searchResults, setSearchResults] = useState([])

    return <>
        <PageTop title={"Policies"} section={"policies"} />
        <FetchWithEndlessScroll currentPageDocsState={[policies, setPolicies]} type={"policies"}>

            {/*Search bar*/}
            <SectionSearch setSearchResults={setSearchResults} collection={"policies"} field={"title"} />


            <section className="container">
                {policies && (searchResults[0] ? searchResults : policies).map((policy, i) => {
                    return <>

                        <Row key={i} className={"scPast search-card"}>

                            <Col md={6} style={{padding: "0"}}>

                                {/*Title*/}
                                <h2 style={{fontSize: "2.5rem"}}>{policy.title}</h2>

                                {/*Date*/}
                                <div className={"m-0"}>
                                    {policy.datePublished && policy.datePublished !== "unpublished" ?
                                        <p>{policyDateFormat(policy.datePublished)}</p>
                                        :
                                        <li className={"list-unstyled text-info"}>Unpublished</li>
                                    }
                                </div>

                            </Col>


                            <Col md={6}>
                                {/*Buttons*/}
                                <SectionManageBtns itemState={[policies, setPolicies]} page={"policies"} index={i}
                                                   dbCollection={"policies"} />
                            </Col>

                        </Row>
                    </>
                })}
            </section>
        </FetchWithEndlessScroll>
    </>
}

export default Policies;