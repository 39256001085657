import React from 'react';
import DateTimeGenerator from "../blocks/DateTimeGenerator";
import moment from "moment";

function EventDateTimeSection({event, handleChange}) {
    const addDate = () => {
        let tempArr = event.eventDates
        tempArr[tempArr.length] = moment.utc().format()
        handleChange(tempArr, "eventDates")
    }

    const deleteDate = (index) => {
        let tempArr = event.eventDates
        console.log("TEMP ARR 1: ", tempArr, index)
        tempArr.splice(index, 1)
        console.log("TEMP ARR 2: ", tempArr, index)
        handleChange(tempArr, "eventDates")
    }

    const handleChangeDate = (d, index) => {
        event["eventDates"][index] = d
        handleChange(event["eventDates"], "eventDates")
    }

    return <>
        <label className={"general-input__label mb-3"}>Event Dates</label>
        <div className="row" key={event.eventDates.length}>

            {event.eventDates && event.eventDates.map((date, i) => {
                return <div className={"d-flex container"} key={i}>
                    <DateTimeGenerator date={date} handleChange={d => handleChangeDate(d, i)} />
                    {event.eventDates.length !== 1 &&
                    <button className={"scbtn--small scbtn--1 sc-red-btn ml-2"}
                            style={{height: "3.7rem", minWidth: "6rem"}}
                            type={"button"} onClick={() => deleteDate(i)}>
                        Delete
                    </button>}
                </div>
            })}

            <div className={"container"}>
                <button type={"button"} className={"scbtn--small scbtn--1"}
                        style={{height: "3.7rem", fontSize: "1.6rem"}}
                        onClick={addDate}>
                    Add Date
                </button>
            </div>

        </div>
    </>
}

export default EventDateTimeSection;