import React, {useEffect, useState} from "react";
import {getDocById, setData} from "../../dataManagement/database/dbFunctions";
import Loader from "../../dataManagement/components/Loader";

function InputWithList({
                         label,
                         value,
                         keyToUpdate,
                         handleChange,
                         staticList = null,
                         collection,
                         docId,
                         className = ""
                       }) {
  const [inputOptions, setInputOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState("");
  const [defaultStatus, setDefaultSetStatus] = useState({pending: false});
  const [suggestStatus, setSuggestStatus] = useState({pending: false});
  const [deleteStatus, setDeleteStatus] = useState({pending: false});

  useEffect(() => {
    getDocById(collection, docId)
      .then(x => {
        setInputOptions(x.inputOptions)
        setDefaultOption(x.default)
        value?.length < 1 && handleChange(x.default, keyToUpdate)
      })
  }, [])

  const addSuggestion = () => {
    if (!inputOptions.includes(value)) {
      setSuggestStatus({pending: true})
      let tempArr = [...inputOptions, value]
      setData("general", docId, {default: defaultOption, inputOptions: tempArr})
        .then(() => {
          setInputOptions(tempArr)
          setSuggestStatus({pending: false})
        })
    }
  }

  const setDefault = () => {
    setDefaultSetStatus({pending: true})
    setData("general", docId, {default: value, inputOptions})
      .then(() => {
        setDefaultOption(value)
        setDefaultSetStatus({pending: false})
      })
  }

  const deleteSuggestion = () => {
    if (inputOptions.includes(value)) {
      setDeleteStatus({pending: true})
      const index = inputOptions.indexOf(value)
      const tempArr = inputOptions
      tempArr.splice(index, 1)
      setData("general", docId, {default: defaultOption, inputOptions: tempArr})
        .then(() => {
          setInputOptions(tempArr)
          setDeleteStatus({pending: false})
        })
    }
  }

  return (
    <>
      <label className={"general-input__label"}>{label}</label>
      <div className={"d-flex"}>
        <input type={"text"} list={`list-${keyToUpdate}`} placeholder={label} className={"general-input " + (className)}
               value={value} onChange={e => handleChange(e.target.value, keyToUpdate)} autoComplete={false} />
        <datalist id={`list-${keyToUpdate}`}>
          {(staticList ? staticList : inputOptions).map((o, i) => {
            console.log("OPTION: ", o)
            return <option key={i}>{o}</option>
          })}
        </datalist>
        <button className={"scbtn--small scbtn--2 ml-2"}
                style={{height: "3.9rem", minWidth: "11rem"}}
                type={"button"} onClick={addSuggestion}>
          {suggestStatus.pending ? <Loader spinner /> : "Add to suggestions"}
        </button>
        <button className={"scbtn--small scbtn--2 ml-2"}
                style={{height: "3.9rem", minWidth: "9rem"}}
                type={"button"} onClick={setDefault}>
          {defaultStatus.pending ? <Loader spinner /> : "Set as default"}
        </button>
        <button className={"scbtn--small ml-2 sc-red-btn"}
                style={{height: "3.9rem", minWidth: "11rem"}}
                type={"button"} onClick={deleteSuggestion}>
          {deleteStatus.pending ? <Loader spinner /> : "Delete suggestion"}
        </button>
      </div>
    </>
  );
}

export default InputWithList;