import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {useLocation} from "react-router-dom";
import Loader from "../dataManagement/components/Loader";
import PageTitle from "../components/elements/PageTitle";

function SignIn({
                    signInDetailsState: [signInDetails, setSignInDetails],
                    handleSubmit,
                    checkPwdsMatch,
                    userResponse,
                }) {
    const [showPassword, setShowPassword] = useState(false);
    const [method, setMethod] = useState(null);

    //Form to show depends on location || 1 === login, 2 === signup, 3 === change password
    const location = useLocation()
    useEffect(() => {
        let path = location.pathname
        setMethod(path === "/login" ? 1 : path.includes("/create-account") ? 2 : null)
    }, [])

    //handles input change with state prop
    const handleChange = (e) => {
        const {value, id} = e.target
        setSignInDetails(prevState => {
            return {...prevState, [id]: value}
        })
    }

    return (
        <>
            {method === 1 && <PageTitle title={"Log in"}/>}

            <Form className={"mt-5 bg-light border table-bordered border-thin p-5 position-relative"}
                  style={{maxWidth: "500px", minWidth: "240px", width: "50%", margin: "auto"}}
                  onSubmit={(e) => handleSubmit(e, method)}
            >

                {method === 2 &&
                <Form.Group>
                    <Form.Label className={"readers-only"}>Name</Form.Label>
                    <Form.Control type={"text"} placeholder={"Name"} id={"displayName"}
                                  value={signInDetails.displayName}
                                  className={"general-input"}
                                  onChange={(e) => handleChange(e)}
                    />
                </Form.Group>}

                <Form.Group>
                    <Form.Label className={"readers-only"}>Email</Form.Label>
                    <Form.Control type={"email"} placeholder={"Email"} id={"email"} value={signInDetails.email}
                                  className={"general-input"}
                                  onChange={(e) => handleChange(e)}
                    />
                </Form.Group>

                <Form.Group className={"mt-4 " + (method === 1 ? "mb-2" : "")}>
                    <Form.Label className={"readers-only"}>Password</Form.Label>
                    <Form.Control type={showPassword ? "text" : "password"} placeholder={"Password"}
                                  id={"password"} className={"general-input " + (method === 1 ? "mb-0" : "")}
                                  value={signInDetails.password}
                                  onChange={(e) => handleChange(e)}
                    />
                </Form.Group>

                {method === 2 &&
                <Form.Group className={"mt-4 mb-2"}>
                    <Form.Label className={"readers-only"}>Confirm Password</Form.Label>
                    <Form.Control type={showPassword ? "text" : "password"}
                                  placeholder={"Confirm Password"} id={"confirmPassword"}
                                  className={"general-input mb-0"}
                                  value={signInDetails.confirmPassword}
                                  onChange={(e) => handleChange(e)}
                                  onBlur={(() => checkPwdsMatch())}
                    />
                </Form.Group>}


                <div className={"d-flex justify-content-end"}>
                    {method === 1 &&
                    <>
                        <LinkContainer to={"/reset-password"} style={{fontSize: "1.2rem"}}>
                            <button className={"border-0 background-light"}
                                    type={"button"}>
                                Forgot Password
                            </button>
                        </LinkContainer>
                        <p className={"mx-2 my-1 line-height-tight"}
                           style={{backgroundColor: "#7e868d", width: "1px", opacity: ".5", fontSize: ".8rem"}}
                        >&nbsp;</p>
                    </>}
                    <button
                        className={"border-0 background-light"} style={{fontSize: "1.2rem"}}
                        type={"button"}
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? "Hide" : "Show"} Password
                    </button>
                </div>


                <div className={"d-flex justify-content-end"}>
                    <button className={"scbtn--med scbtn--1 mt-4 background-light"} style={{padding: ".4rem 1.2rem"}}
                            type="submit">
                        Submit
                    </button>
                </div>

                {userResponse.loading &&
                <>
                    <div
                        className={"position-absolute background-white d-flex justify-content-center align-items-center"}
                        style={{height: "100%", width: "100%", top: "0", left: "0", opacity: ".8"}}>
                        <div style={{paddingBottom: "5.5rem"}}>
                            <Loader wait/>
                        </div>
                    </div>
                </>}
                {userResponse.message &&
                <>
                    <br/>
                    <p className={"py-1 px-3 mt-2 mb-0 " + (userResponse.success ? "background__success" : "background__fail")}>{userResponse.message}</p>
                </>}
            </Form>

        </>
    );
}

export default SignIn;