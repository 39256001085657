import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart as solidHeart} from "@fortawesome/free-solid-svg-icons";

function Likes({likes}) {

    return (
        <div className={"border-grey border-thin"} style={{height: "27rem"}}>
            {likes && [...Array(6)].map((l, i) => {
                return <div key={i} className={"d-flex px-4 py-3 " + (i !== 5 && "border-bottom")}>
                    <p className={"m-0 pr-4 line-height-tight align-self-center"}
                       style={{fontSize: "calc(1.5rem + .2vw)", opacity: ".7"}}>{i + 1}</p>
                    <FontAwesomeIcon icon={solidHeart} style={{fontSize: "calc(1.5rem + .2vw)"}}
                                     className={"line-height-tight prime-colour mr-2 align-self-center"}/>
                    <p className={"m-0 pr-4 line-height-tight align-self-center prime-colour"}
                       style={{fontSize: "calc(1.5rem + .2vw)"}}>{likes[i].likes}</p>
                    <p className={"line-height-tight m-0 align-self-center"}>{likes[i].title}</p>
                    {/*<p className={"text-small line-height-tight m-0"}>{likes[i].likes}</p>*/}
                </div>
            })}


        </div>
    );
}

export default Likes;