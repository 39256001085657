import React from "react";
import InputWithList from "../../elements/InputWithList";
import Input from "../../elements/Input";
import {Tab, Tabs} from "react-bootstrap";

function EventNotesSurveyAndDonateTabs({event, onNoteChanges}) {
  return (
    <>
      {/* CTA */}
      <InputWithList value={event.programmeNotes.surveyCallToActionText} label={"Call to action"}
                     keyToUpdate={"surveyCallToActionText"}
                     handleChange={onNoteChanges} collection={"general"} docId={"surveyCallToActionTextOptions"}
                     className={"mb-3"} />

      <Tabs
        defaultActiveKey="survey"
        id="uncontrolled-tab-example"
        className="my-3"
      >
        {["Survey", "Donate"].map(title => {
          let key = title.toLowerCase()
          return <Tab key={key} eventKey={key} title={title} tabClassName={"font-colour"}>

            {/* BTN TEXT */}
            <InputWithList value={event.programmeNotes[`${key}BtnText`]} label={`${title} Button text`}
                           keyToUpdate={`${key}BtnText`} handleChange={onNoteChanges}
                           collection={"general"} docId={`${key}BtnTextOptions`} className={"mb-3"} />


            {/* BTN LINK */}
            <Input label={`${title} Link`} keyToUpdate={`${key}Link`} value={event.programmeNotes[`${key}Link`]}
                   handleChange={onNoteChanges} ClassName={"mb-3"} />

          </Tab>
        })}

        {/*<InputWithList value={event.programmeNotes.donateBtnText} label={"Donate Button text"}*/}
        {/*               keyToUpdate={"donateBtnText"} handleChange={onNoteChanges}*/}
        {/*               collection={"general"} docId={"donateBtnTextOptions"} className={"mb-3"} />*/}
      </Tabs>
    </>
  );
}

export default EventNotesSurveyAndDonateTabs;