import React, {useContext, useEffect, useState} from 'react';
import PopupModal from "../../components/blocks/PopupModal";
import CredContext from "../../providers/CredContext";
import GeneralSettings from "./GeneralSettings";
import AdvancedSettings from "./AdvancedSettings";
import ToggleButtons from "../../components/elements/ToggleButtons";


function UserEditorModal({
                             modalUidState: [modalUid, setModalUid],
                             user: [user, setUser],
                             updateUserToDb,
                             setUserToUpdate,
                             mapId,
                             savingStatus,
                             handleDeletedUser,
                         }) {
    const cred = useContext(CredContext)
    const [toggleBtns, setToggleBtns] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [access, setAccess] = useState({});
    const [accountType, setAccountType] = useState({});


    // Set modal id to null if showModal is false
    useEffect(() => {
        setUserToUpdate(mapId)
        setAccountType({admin: user.admin, principal: user.principal})
        !showModal && setModalUid(null)
    }, [showModal])

    // Show modal, set userUpdate and set Access state if uid === modal id
    useEffect(() => {
        setShowModal(user.userId === modalUid)
        user.userId === modalUid && setAccess(user.access)
    }, [modalUid])

    // Set userUpdates with any changes to access
    useEffect(() => {
        setUser(prevValues => {
            return {...prevValues, access: access}
        })
    }, [access])


    return (
        <>
            <PopupModal title={`Account Settings: ${user.displayName}`} buttonText={"Close"}
                        showModalState={[showModal, setShowModal]} center={true}
                        greenBtnText={toggleBtns === 0 && (cred.principal || (cred.admin && !user.admin)) ? "Save" : "none"}
                        greenBtnFunction={() => updateUserToDb(mapId)}
            >
                {(cred.principal || (cred.admin && !user.admin)) &&
                <div className={"d-flex justify-content-end mb-2"}>
                    <ToggleButtons btnState={[toggleBtns, setToggleBtns]} size={"lg"}
                                   buttonArray={[{name: "Account", value: 0}, {name: "Other", value: 1}]}/>
                </div>}

                {/*If admin viewing admin account*/}
                {accountType.admin && !cred.principal && <p>You are not authorized to edit Admin accounts</p>}

                {/*General settings*/}
                {toggleBtns === 0 &&
                <GeneralSettings userState={[user, setUser]} accountTypeState={[accountType, setAccountType]}
                                 accessState={[access, setAccess]} savingStatus={savingStatus}/>}

                {toggleBtns === 1 &&
                <AdvancedSettings userState={[user, setUser]} handleDeletedUser={handleDeletedUser} cred={cred}/>}


            </PopupModal>
        </>
    );
}

export default UserEditorModal;