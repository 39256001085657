import React from "react";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import SocialSection from "../components/news/SocialSection";
// import PrevNextBnts from "../components/news/PrevNextBnts";
// import Comments from "../components/news/commentsAndLikes/Comments";
// import {clListener, clUnsubscribe} from "../components/news/commentsAndLikes/commentsAndLikesHelper";
import ScreenContainer from "../blocks/ScreenContainer";
import HtmlReader from "../blocks/HtmlReader";


function NewsPost({article}) {

  //News dates (json to below format)
  const getNewsStyleDate = (d) => {
    const dateList = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"]
    const date = new Date(d);
    const options = {
      // day: 'numeric',
      month: "short",
      year: "numeric",
    }
    let output = date.toLocaleDateString("en-GB", options);
    if (output === null) {
      return ""
    } else {
      return dateList[date.getDate() - 1] + " " + output
    }
  }

  console.log("POST: ", article)


  return <ScreenContainer>
    {article &&
    <div className={"smallContainer"}>
      <div className={"pt-5 pb-4 w-100"}>
        <h1 className={"sc-page-title text-capitalize"}>{article.title}</h1>
      </div>
      {/*DATE*/}
      <div className={"d-flex"}>
        <p className={"scPost-date"}><FontAwesomeIcon icon={faCalendarAlt} /> <span
          style={{position: "relative", top: "1px"}}>{getNewsStyleDate(article.dateCreated)}</span></p>
      </div>

      {/*HEAD IMG*/}
      {article.img !== null &&
      <div className="scPost-topImg">
        <img id={"blogMainImg"} className={"scPost-topImg__image"}
             src={article.img} alt={article.alt} />
      </div>}


      {/*BLOG BODY*/}
      <div id={"mainBody"}><HtmlReader htmlContent={article.body} editorId={"news-body"} /></div>


      <div style={{marginTop: "6rem"}} />
    </div>
    }
  </ScreenContainer>
}

export default NewsPost;