import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown, faAngleUp, faPlus, faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {FetchContent} from "../../dataManagement/FetchContent";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";


function SocialBar() {
    const [array, setArray] = useState([])

    const handleChange = (newArr) => {
        let tempObj = array[0]
        tempObj.socialBar = newArr
        setArray([tempObj])
    }

    return <>
        <FetchContent contentState={[array, setArray]} table={"footer"} conditions={"socialBar"}>
            {array[0] && array[0].socialBar &&
            <ArrayRepeater array={array[0].socialBar} setFunction={handleChange} />}
        </FetchContent>
        {array[0] && array[0].socialBar &&
        <UpdateDocumentField collection={"footer"} docId={"socialBar"} updateKey={"socialBar"}
                             updateValue={array[0].socialBar} />}
    </>
}

export default SocialBar;

/////////////////// Repeater ///////////////////////////

//  1. {array}
//  ! Array to be repeated (mapped)

//  2. {setFunction}
//  ! function that takes updated array as input


function ArrayRepeater({array, setFunction}) {
    const [tempArray, setTempArray] = useState(array)

    useEffect(() => {
        setFunction(tempArray)
    }, [tempArray])

    return <>
        <hr className={"mt-5"} />
        {tempArray[0] && tempArray.map((icon, i) => {
            return <div key={i} className={"my-4"}>
                <Row>
                    <Col md={9}>
                        <ArrayForm icon={icon} state={[tempArray, setTempArray]} index={i} />
                    </Col>
                    <Col md={3}>
                        <ArrayBtns last={i + 1 !== tempArray.length} first={i !== 0} index={i}
                                   state={[tempArray, setTempArray]} />
                    </Col>
                </Row>
                <hr className={"mt-4"} />
            </div>
        })}
        <div className={"mb-5 text-right"}>
            <button type={"button"} className={"scbtn--med scbtn--1"} onClick={() => {
                setTempArray(prevState => {
                    return [...prevState, {icon: "", link: ""}]
                })
            }}>
                New Social Link
            </button>
        </div>
    </>
}

///////////////////// Form ///////////////////////

function ArrayForm({state: [array, setArray], icon, index}) {

    const handleChange = (v, n) => {
        let tempArr = array
        tempArr[index][n] = v
        setArray([...tempArr])
    }

    return <>
        <Row>
            <Col md={4}>
                <label className={"general-input__label"}>Icon</label>
                <select className={"general-input"} value={icon.icon}
                        onChange={e => handleChange(e.target.value, "icon")}>
                    <option disabled={true} value={""}>Choose...</option>
                    <option value={"fb"}>Facebook</option>
                    <option value={"tw"}>Twitter</option>
                    <option value={"in"}>Instagram</option>
                    <option value={"yt"}>Youtube</option>
                    <option value={"tt"}>TikTok</option>
                    <option value={"pi"}>Pinterest</option>
                    <option value={"sn"}>SoundCloud</option>
                </select>
            </Col>
            <Col md={8}>
                <label className={"general-input__label"}>Link</label>
                <input type={"text"} placeholder={"Link"} className={"general-input mb-2"}
                       value={icon.link} onChange={(e) => handleChange(e.target.value, "link")} />
            </Col>
        </Row>

    </>
}

///////////////////// Buttons ///////////////////////

function ArrayBtns({last, first, index, state: [array, setArray]}) {
    const [helpText, setHelpText] = useState(" ")

    const handleMove = (action) => {
        let tempArr = array
        let f = tempArr.splice(index, 1)[0];
        action === "down" && tempArr.splice(index + 1, 0, f);
        action === "up" && tempArr.splice(index - 1, 0, f);
        setArray([...tempArr])
    }

    const handleAddDelete = (action) => {
        let tempArr = array
        action === "add" && tempArr.splice(index + 1, 0, {icon: "", link: ""});
        action === "delete" && tempArr.splice(index, 1);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"position-absolute"} style={{bottom: "4rem", right: "0"}}>
                <p className={"text-right m-0 p-0 text-small"}>{helpText}</p>
            </div>
            <div className={"position-absolute"} style={{bottom: "0", right: "0"}}>
                {first && <Btn value={<FontAwesomeIcon icon={faAngleUp} />} action={"up"}
                               setHelp={setHelpText} helpText={"Move Up"} handleChange={handleMove} />}
                {last && <Btn value={<FontAwesomeIcon icon={faAngleDown} />} action={"down"}
                              setHelp={setHelpText} helpText={"Move Down"} handleChange={handleMove} />}

                <Btn value={<FontAwesomeIcon icon={faPlus} />} action={"add"} handleChange={handleAddDelete}
                     setHelp={setHelpText} helpText={"Add Bellow"} />
                <Btn value={<FontAwesomeIcon icon={faTrashAlt} />} action={"delete"} handleChange={handleAddDelete}
                     setHelp={setHelpText} helpText={"Delete"} />

            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, setHelp, helpText, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "2rem", lineHeight: ".6"}}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onMouseEnter={() => setHelp(helpText)} onMouseLeave={() => setHelp("")}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {value}
        </button>
    </>
}