import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import Loader from "../dataManagement/components/Loader";
import PageTitle from "../components/elements/PageTitle";
import {Link} from "react-router-dom";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ChangePassword({signInDetailsState: [signInDetails, setSignInDetails], handleSubmit, userResponse}) {
    const [showPassword, setShowPassword] = useState(false);

    //handles input change with state prop
    const handleChange = (e) => {
        const {value, id} = e.target
        setSignInDetails(prevState => {
            return {...prevState, [id]: value}
        })
    }

    return (
        <>

            <PageTitle title={"Change Your Password"}/>
            <Link to={"/my-account"} className={"scbtn--back"}>
                <FontAwesomeIcon icon={faArrowLeft}/> My Account
            </Link>

            <Form className={"mt-5 bg-light border table-bordered border-thin p-5 position-relative"}
                  style={{maxWidth: "500px", minWidth: "240px", width: "50%", margin: "auto"}}
                  onSubmit={(e) => handleSubmit(e, 3)}
            >

                <Form.Group className={"mt-4"}>
                    <Form.Label className={"readers-only"}>Old Password</Form.Label>
                    <Form.Control type={showPassword ? "text" : "password"} placeholder={"Old Password"}
                                  id={"oldPassword"} className={"general-input"}
                                  value={signInDetails.oldPassword}
                                  onChange={(e) => handleChange(e)}
                    />
                </Form.Group>

                <Form.Group className={"mt-4"}>
                    <Form.Label className={"readers-only"}>New Password</Form.Label>
                    <Form.Control type={showPassword ? "text" : "password"} placeholder={"New Password"}
                                  id={"password"} className={"general-input"}
                                  value={signInDetails.password}
                                  onChange={(e) => handleChange(e)}
                    />
                </Form.Group>


                <Form.Group className={"mt-4 mb-2"}>
                    <Form.Label className={"readers-only"}>Conform Password</Form.Label>
                    <Form.Control type={showPassword ? "text" : "password"}
                                  placeholder={"Confirm Password"} id={"confirmPassword"}
                                  className={"general-input mb-0"}
                                  value={signInDetails.confirmPassword}
                                  onChange={(e) => handleChange(e)}
                    />
                </Form.Group>

                <div className={"d-flex justify-content-end"}>
                    <button
                        className={"text-small border-0 background-light"}
                        type={"button"}
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? "Hide" : "Show"} Password
                    </button>
                </div>


                <div className={"d-flex justify-content-end"}>
                    <button className={"scbtn--med scbtn--1 mt-4 background-light"} style={{padding: ".4rem 1.2rem"}}
                            type="submit">
                        Submit
                    </button>
                </div>

                {userResponse.loading &&
                <>
                    <div
                        className={"position-absolute background-white d-flex justify-content-center align-items-center"}
                        style={{height: "100%", width: "100%", top: "0", left: "0", opacity: ".8"}}>
                        <div style={{paddingBottom: "5.2rem"}}>
                            <Loader wait/>
                        </div>
                    </div>
                </>}
                {userResponse.message &&
                <>
                    <br/>
                    <p className={"py-1 px-3 mt-2 mb-0 " + (userResponse.success ? "background__success" : "background__fail")}>{userResponse.message}</p>
                </>}
            </Form>

        </>
    );
}

export default ChangePassword;