import React, {useState} from 'react';
import PageTop from "../components/blocks/PageTop";
import TextEditor from "../components/textEditor/TextEditor";
import {FetchContent} from "../dataManagement/FetchContent";
import UpdateDocumentField from "../dataManagement/UpdateDocumentField";
import EmailTo from "../components/contact/EmailTo";

function ContactUs() {
    const [data, setData] = useState([]);

    //Text Editor Change Handler
    const handleChange = (d, key) => {
        let tempObj = data[0] && data[0].content
        tempObj[key] = d
        setData([{content: tempObj, fetchId: "contactPage"}])
    }

    // console.log("EEE DATA: ", data[0] && data[0]?.content)

    return <>
        <PageTop title={"Contact us"} section={"contact"} />

        <FetchContent contentState={[data, setData]} table={"general"} conditions={"contactPage"}>

            {data[0] && data[0].content &&
            <>
                <label className={"general-input__label"}>Contact Info</label>
                <TextEditor handleChange={handleChange} value={data[0].content.info} imageDirectory={"contact/info"}
                            keyToUpdate={"info"} />
                <label className={"general-input__label mb-0 mt-4"}>Contact Form Recipients</label>
                <p className={"mt-0 mb-2 pt-0 line-height-tight"}>Recipients of emails sent via the websites contact form.</p>
                <EmailTo data={data[0].content.emailTo} handleChange={handleChange} />

            </>}


        </FetchContent>
        {data[0] &&
        <UpdateDocumentField updateValue={data[0].content} updateKey={"content"} collection={"general"}
                             docId={"contactPage"} />}
    </>
}

export default ContactUs;