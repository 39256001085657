import React, {useState} from 'react';
import BtnAndConfirm from "./BtnAndConfirm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {handleBtnFunction} from "./btnHelper";
import EditBtns from "./EditBtns";


function SectionManageBtns({itemState: [item, setItem], page, index, dbCollection}) {
    const [show, setShow] = useState(null)
    const [status, setStatus] = useState({pending: "", err: false})

    const handleClick = (action) => {
        setStatus({pending: action, err: false})
        handleBtnFunction(action, index, [item, setItem], setStatus, dbCollection)
    }

    let ref = page === "news" ? "post" : page === "events" ? "event" : page === "policies" ? "policy" : "--NO REF--"

    return <>
        <div className="d-flex justify-content-end">

            <EditBtns page={page} item={item} index={index} btnId={7} showState={[show, setShow]} />

            {item[index].datePublished === "unpublished" ?
                <BtnAndConfirm btnText={"Publish"} btnClassName={"online btn btn-info"}
                               confirmBtnText={"Confirm"} message={`Publish this ${ref}?`}
                               showState={[show, setShow]} btnId={1} handleClick={handleClick}
                               onClickAction={"publish"} spin={status.pending} />
                :
                <>
                    {item[index].online ?
                        <BtnAndConfirm btnText={"Online"} btnClassName={"online btn btn-success"}
                                       confirmBtnText={"Confirm"} message={`Take this ${ref} offline?`}
                                       showState={[show, setShow]} btnId={2} handleClick={handleClick}
                                       onClickAction={"takeOffline"} spin={status.pending} />
                        :

                        <BtnAndConfirm btnText={"Offline"} btnClassName={"btn btn-danger"} confirmBtnText={"Confirm"}
                                       message={`Put this ${ref} online?`} showState={[show, setShow]} btnId={3}
                                       handleClick={handleClick} onClickAction={"takeOnline"}
                                       spin={status.pending} />}
                </>}

            {page !== "policies" && <>
                {item[index].homePage ?
                    <BtnAndConfirm btnText={"Home Page"} btnClassName={"btn btn-success"} confirmBtnText={"Confirm"}
                                   message={`Hide this ${ref} on the homepage?`} showState={[show, setShow]} btnId={4}
                                   handleClick={handleClick} onClickAction={"offHome"} spin={status.pending} />
                    :
                    <BtnAndConfirm btnText={"Home Page"} btnClassName={"btn btn-danger"} confirmBtnText={"Confirm"}
                                   message={`Show this ${ref} on the homepage?`} showState={[show, setShow]} btnId={5}
                                   handleClick={handleClick} onClickAction={"onHome"} spin={status.pending} />}
            </>}


            <BtnAndConfirm btnText={<FontAwesomeIcon icon={faTrashAlt} />} btnClassName={"btn btn-outline-danger"}
                           confirmBtnText={"Delete"} message={`Are you sure you want to delete this ${ref}?`}
                           showState={[show, setShow]} btnId={6} handleClick={handleClick} onClickAction={"delete"}
                           spin={status.pending} />


        </div>
    </>
}

export default SectionManageBtns;