import React, {useContext, useEffect, useRef, useState} from 'react';
import PageTop from "../blocks/PageTop";
import PoliciesEditorForm from "./PoliciesEditorForm";
import SetSectionDocument from "../../dataManagement/SetSectionDocument";
import {PoliciesEditorContext} from "../../providers/PoliciesEditorContext";


function PoliciesEditorScreen() {
    const {policy, policyId, fetchErr, fetchComplete} = useContext(PoliciesEditorContext)
    const titleBeforeUpdate = useRef({set: false, title: ""})

    useEffect(() => {
        if (fetchComplete) {
            if (!titleBeforeUpdate.current.set) titleBeforeUpdate.current = {set: true, title: policy.title}
        }
    }, [policyId, fetchComplete])


    return <>
        <PageTop section={"policies"} title={policyId === "new" ? "Policy Creator" : "Policy Editor"} />


        {policy && <PoliciesEditorForm />}


        {/* Create/Update Button */}
        {policyId === "new" && policy &&
        <SetSectionDocument document={policy} collection={"policies"} docId={policy.id} btnText={"Create Policy"}
                            cancelLink={"policies"} isUpdate={false} />}
        {policyId !== "new" && policy &&
        <SetSectionDocument document={policy} collection={"policies"} docId={policyId} btnText={"Update Policy"}
                            cancelLink={"policies"} isUpdate oldTitle={titleBeforeUpdate.current.title} />}

    </>
}

export default PoliciesEditorScreen;