import React from "react";
import HeaderLink from "./HeaderLink";
import HeaderDropdown from "./HeaderDropdown";

function NavDesktop({cred}) {
  return <>
    <div className={"ml-auto navbar__desktop-wrap d-flex justify-content-end"}>

      <HeaderLink linkTitle={"Dashboard"} linkTo={"/"} />


      <HeaderDropdown navTitle={"Sections"} navTitleLink={"sections"} links={[
        {title: "Events", link: "events"},
        {title: "News", link: "news"},
        {title: "Policies", link: "policies"},
        {title: "Surveys", link: "surveys"},
      ]} />

      <HeaderDropdown navTitle={"Pages"} navTitleLink={"pages"} links={[
        {title: "Home", link: "home"},
        {title: "About Us", link: "about-us"},
        {title: "Support Us", link: "support-us"},
        {title: "Contact Us", link: "contact-us"},
        {title: "Terms of Use", link: "terms-of-use"},
        {title: "Privacy Notice", link: "privacy-notice"},
        {title: "Cookies", link: "cookies"},
      ]} />

      <HeaderDropdown navTitle={"Components"} navTitleLink={"components"} links={[
        {title: "Message Strip", link: "message-strip"},
        {title: "Header Button", link: "header-button"},
        {title: "Footer", link: "footer"},
      ]} />

      {cred.admin && <HeaderLink linkTitle={"Account Manager"} linkTo={"/accounts/account-manager"} />}


    </div>
  </>
}

export default NavDesktop;