import {
    AUTH_REQUEST, BANNER_REQUEST, CONTENT_REQUEST, EVENTS_REQUEST, FOOTER_REQUEST,
    GENERAL_REQUEST, MESSAGE_REQUEST, NEWS_REQUEST, POLICIES_REQUEST
} from "../constants/contentConstants";


//Content
export const contentReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case CONTENT_REQUEST[0]:
            return {loading: true}
        case CONTENT_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case CONTENT_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Footer
export const footerReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case FOOTER_REQUEST[0]:
            return {loading: true}
        case FOOTER_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case FOOTER_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//banner
export const bannerReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case BANNER_REQUEST[0]:
            return {loading: true}
        case BANNER_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case BANNER_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Message
export const messageReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case MESSAGE_REQUEST[0]:
            return {loading: true}
        case MESSAGE_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case MESSAGE_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//News
export const newsReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case NEWS_REQUEST[0]:
            return {loading: true}
        case NEWS_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case NEWS_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Events
export const eventsReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case EVENTS_REQUEST[0]:
            return {loading: true}
        case EVENTS_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case EVENTS_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Policies
export const policiesReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case POLICIES_REQUEST[0]:
            return {loading: true}
        case POLICIES_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case POLICIES_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Management
export const generalReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case GENERAL_REQUEST[0]:
            return {loading: true}
        case GENERAL_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case GENERAL_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Auth
export const authReducer = (state = {pageContent: [{content: ""}]}, action) => {
    switch (action.type) {
        case AUTH_REQUEST[0]:
            return {loading: true}
        case AUTH_REQUEST[1]:
            return {loading: false, pageContent: action.payload}
        case AUTH_REQUEST[2]:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
