import React, {useContext} from 'react';
import {EventEditorContext} from "../../../providers/EventEditorContext";
import Input from "../../elements/Input";
import TextEditor from "../../textEditor/TextEditor";

function EventNotesEditorForm({onNoteChanges}) {
    const {event, handleChange, eventId, fetchErr, fetchComplete} = useContext(EventEditorContext)

    return (
        <>
            {/*Editor - Repertoire*/}
            <div>
                <h4 style={{margin: "2rem auto 0"}}>Repertoire Notes</h4>
                <TextEditor value={event.programmeNotes.repertoireNotes} handleChange={onNoteChanges}
                            imageDirectory={`events/${event.id}/repertoireNotes`}
                            keyToUpdate={"repertoireNotes"} rerenderKey={`${event.id}-repNotes`} />
            </div>

            {/*Editor - Performers*/}
            <div>
                <h4 style={{margin: "2rem auto 0"}}>Performers Notes</h4>
                <TextEditor value={event.programmeNotes.performersNotes} handleChange={onNoteChanges}
                            imageDirectory={`events/${event.id}/performersNotes`}
                            keyToUpdate={"performersNotes"} rerenderKey={`${event.id}-perfNotes`} />
            </div>

        </>
    );
}

export default EventNotesEditorForm;