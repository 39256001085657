import {
  CalendarDate, CalendarEvent,
  CardText, Clock,
  InputCursorText,
  MenuDown,
  Sliders,
  UiChecks,
  UiChecksGrid,
  UiRadios,
  UiRadiosGrid
} from "react-bootstrap-icons";

// Question Types...
export const QUESTION_TYPES = [
  {value: "INPUT", label: <span><InputCursorText size={"20"} className={"pb-1 mr-2"} /> Short Text</span>},
  {value: "TEXTAREA", label: <span><CardText size={"20"} className={"pb-1 mr-2"} /> Paragraph Text</span>},
  {value: "hr"},
  {value: "DROPDOWN", label: <span><MenuDown size={"20"} className={"pb-1 mr-2"} /> Dropdown</span>},
  {value: "RADIO", label: <span><UiRadios size={"20"} className={"pb-1 mr-2"} /> Multiple Choice</span>},
  {value: "CHECKBOX", label: <span><UiChecks size={"20"} className={"pb-1 mr-2"} /> Multiple Select</span>},
  {value: "hr"},
  {value: "SCALE", label: <span><Sliders size={"20"} className={"pb-1 mr-2"} /> Slider</span>},
  {value: "RADIO_GRID", label: <span><UiRadiosGrid size={"20"} className={"pb-1 mr-2"} /> Multiple Choice Grid</span>},
  {value: "CHECK_GRID", label: <span><UiChecksGrid size={"20"} className={"pb-1 mr-2"} /> Multiple Select Grid</span>},
  {value: "hr"},
  {value: "DATE", label: <span><CalendarDate size={"20"} className={"pb-1 mr-2"} /> Date</span>},
  {value: "TIME", label: <span><Clock size={"20"} className={"pb-1 mr-2"} /> Time</span>},
  {value: "DATE_TIME", label: <span><CalendarEvent size={"20"} className={"pb-1 mr-2"} /> Date & Time</span>}]