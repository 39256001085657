import React from 'react';
import {useParams} from "react-router-dom";
import PageTop from "../components/blocks/PageTop";
import SocialBar from "../components/footerManager/SocialBar";
import Funders from "../components/footerManager/Funders";
import LegalInfo from "../components/footerManager/LegalInfo";

function FooterManager() {
    const {section} = useParams()
    return <>
        <PageTop title={`Footer ${section !== undefined ? section : ""}`} section={"footer"}/>
        {section === "social-bar" ? <SocialBar/> :
            section === "funders" ? <Funders/> :
                section === "legal-information" ? <LegalInfo/> :
                    <div className={"d-flex justify-content-center align-items-center"} style={{minHeight: "30vh"}}>
                        <div>
                            <h4 style={{opacity: ".6"}}>Select an option from the footer menu above</h4>
                        </div>
                    </div>}
    </>
}

export default FooterManager;