import React, {useContext, useState, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Form} from "react-bootstrap";
import CredContext from "../providers/CredContext";
import {updateDisplayName} from "./authHelpers";
import {updateData} from "../dataManagement/database/dbFunctions";
import PageTitle from "../components/elements/PageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import Loader from "../dataManagement/components/Loader";


function ChangeUserName() {
    const cred = useContext(CredContext)
    const [username, setUsername] = useState(cred.displayName);
    const [status, setStatus] = useState({pending: false, show: false, success: false, message: ""})
    const history = useHistory()

    const params = new URLSearchParams(history.location.search)
    const updated = params.get("updated")
    useEffect(() => {
        if (updated) {
            setStatus({pending: false, show: true, success: true, message: "Your display name has been updated"})
        }
    }, [history.location.pathname])


    const handleSubmit = () => {
        setStatus({pending: true, show: false, success: false, message: ""})
        updateDisplayName(username)
        updateData("displayName", username, cred.uid, "users")
            .then(() => {
                history.push(`/my-account/change-display-name-${username}?updated=true`)
            })
            .catch(() => {
                setStatus({
                    pending: false,
                    show: true,
                    success: false,
                    message: "Something went wrong, please try again."
                })
            })
    }


    return (
        <>
            <PageTitle title={"Change Your Display Name"}/>
            <Link to={"/my-account"} className={"scbtn--back"}>
                <FontAwesomeIcon icon={faArrowLeft}/> My Account
            </Link>

            <div className={"mt-5 bg-light border table-bordered border-thin p-5 position-relative"}
                 style={{maxWidth: "500px", minWidth: "240px", width: "50%", margin: "auto"}}
                // onSubmit={(e) => handleSubmit(e, 3)}
            >

                <Form.Group className={"mt-4 mb-2"}>
                    <Form.Label className={"readers-only"}>Display Name</Form.Label>
                    <Form.Control type={"text"} placeholder={"Display Name"} className={"general-input mb-0"}
                                  value={username}
                                  onChange={(e) => setUsername(e.target.value)}
                    />
                </Form.Group>


                <div className={"d-flex justify-content-end"}>
                    <button className={"scbtn--med scbtn--1 mt-4 background-light"} style={{padding: ".4rem 1.2rem"}}
                            onClick={handleSubmit} disabled={username === cred.displayName}>
                        Submit
                    </button>
                </div>

                {status.pending &&
                <>
                    <div
                        className={"position-absolute background-white d-flex justify-content-center align-items-center"}
                        style={{height: "100%", width: "100%", top: "0", left: "0", opacity: ".8"}}>
                        <div style={{paddingBottom: "2.2rem"}}>
                            <Loader wait/>
                        </div>
                    </div>
                </>}

                {status.show &&
                <>
                    <br/>
                    <p className={"py-1 px-3 mt-2 mb-0 " + (status.success ? "background__success" : "background__fail")}>{status.message}</p>
                </>}
            </div>


        </>
    );
}

export default ChangeUserName;