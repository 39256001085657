import React, {useState} from "react";
import FetchWithEndlessScroll from "../dataManagement/endlessScroll/FetchWithEndlessScroll";
import {Col, Row} from "react-bootstrap";
import PageTop from "../components/blocks/PageTop";
import SectionManageBtns from "../components/sectionManagerButtons/SectionManageBtns";
import SectionSearch from "../components/blocks/SectionSearch";

function Events() {
  const [surveys, setSurveys] = useState([])
  const [searchResults, setSearchResults] = useState([])

  return <>
    <PageTop section={"surveys"} title={"Survey Manager"} />

    <FetchWithEndlessScroll currentPageDocsState={[surveys, setSurveys]} type={"surveys"}
                            pauseFetch={searchResults[0]}>

      {/*Search bar*/}
      <SectionSearch setSearchResults={setSearchResults} collection={"surveys"} field={"title"} />

      <section className="container">
        {surveys && (searchResults[0] ? searchResults : surveys).map((survey, i) => {
          return <Row key={i} className={"scPast search-card"}>

            <Col md={6} style={{padding: "0"}}>
              {/*Titles*/}
              <h2 style={{fontSize: "2.5rem"}}>{survey.title}</h2>

              {/*  */}
              <div className={"m-0"}>
                {/*<EventDateTime dateTime={survey.surveyDates}*/}
                {/*               classname={"search-card__pe-date"} />*/}
              </div>
            </Col>


            <Col md={6}>
              {/*Buttons*/}
              <SectionManageBtns
                itemState={searchResults[0] ? [searchResults, setSearchResults] : [surveys, setSurveys]}
                page={"surveys"} index={i} dbCollection={"surveys"} />
            </Col>


          </Row>
        })}
      </section>
    </FetchWithEndlessScroll>
  </>
}

export default Events;