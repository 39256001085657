import React from "react";
import PageTitle from "../elements/PageTitle";
import HeaderLink from "../../appBase/header/HeaderLink";

//// Page title

// 1. {title}
// ! title text

function PageTop({section, title}) {

  let home = [
    {linkTitle: "Banner", href: "/home/banner"},
    {linkTitle: "Intro", href: "/home/intro"},
    {linkTitle: "Welcome", href: "/home/welcome"},
    {linkTitle: "Display Order", href: "/home/display"},
  ]
  let events = [
    {linkTitle: "Manager", href: "/events"},
    {linkTitle: "Creator", href: `/events/editor/new`},
  ]
  let news = [
    {linkTitle: "Manager", href: "/news"},
    {linkTitle: "Creator", href: `/news/editor/new`},
  ]

  let policies = [
    {linkTitle: "Manager", href: "/policies"},
    {linkTitle: "Creator", href: `/policies/editor/new`},
  ]

  let surveys = [
    {linkTitle: "Manager", href: "/surveys"},
    {linkTitle: "Creator", href: `/surveys/editor/new`},
  ]


  let footer = [
    {linkTitle: "Funders", href: "/footer/funders"},
    {linkTitle: "Social Bar", href: `/footer/social-bar`},
    {linkTitle: "Policies Information", href: `/footer/legal-information`},
  ]

  let accountManagement = [
    {linkTitle: "Accounts", href: "/accounts/account-manager"},
    {linkTitle: "Create Account", href: `/accounts/create-account`},
  ]

  let links =
    section === "home" ? home :
    section === "events" ? events :
    section === "news" ? news :
    section === "policies" ? policies :
    section === "surveys" ? surveys :
    section === "footer" ? footer :
    section === "accountManagement" ? accountManagement :
    null

  return (
    <>
      <div className={"ml-auto navbar__desktop-wrap d-flex pb-4 pt-2"}>
        <PageTitle title={title.replace("-", " ")} pageTop />

        {links && links.map((link, i) => {
          return <HeaderLink key={i} linkTitle={link.linkTitle} linkTo={link.href} />
        })}

      </div>
    </>
  )
    ;
}

export default PageTop;