import {idMaker} from "../helper";
import moment from "moment";

// Survey Factory
export const createNewSurvey = () => {
  return {
    object: "survey",
    id: idMaker(),
    dateCreated: moment.utc().format(),
    datePublished: "unpublished",
    title: "",
    questions: [],
    online: false,
  }
}

// Question Types...
// ["INPUT", "TEXTAREA", "RADIO", "CHECKBOX", "DROPDOWN", "SCALE", "RADIO_GRID", "CHECK_GRID", "DATE", "TIME", "DATE_TIME"]

// Question Factory
export const createNewQuestion = (data = {}) => {
  return {
    type: data.type,
    title: data?.title || "",
    description: data?.description || "",
    showDescription: data?.showDescription || false,
    isRequired: data?.isRequired || false,
    ...q_text(data),
    ...q_multiChoice(data),
    ...q_scale(data),
    ...q_grid(data),
    ...q_dateTime(data),
  }
}

// Text Answers (input, textarea)
const q_text = (data) => {
  return ["INPUT", "TEXTAREA"].includes(data.type) ?
    {
      userResponse: data?.userResponse || [""],
    } : null
}

// Multiple Choice (radio-box, checkbox, dropdown)
const q_multiChoice = (data) => {
  return ["RADIO", "CHECKBOX", "DROPDOWN"].includes(data.type) ?
    {
      choices: [{label: ""}],
      checkLimit: null, // If checkbox, set limit to how many boxes can be checked
      userResponse: [""],
    } : null
}


// Scale
const q_scale = (data) => {
  return ["SCALE"].includes(data.type) ?
    {
      scaleMin: 0,
      scaleMax: 10,
      minScaleDescription: "Most unlikely",
      maxScaleDescription: "Most likely"
    } : null
}

// Grid (radio-grid, check-grid)
const q_grid = (data) => {
  return ["RADIO_GRID", "CHECK_GRID"].includes(data.type) ?
    {
      cols: [],
      rows: [],
    } : null
}


// Date / Time
const q_dateTime = (data) => {
  return ["DATE", "TIME", "DATE_TIME"].includes(data.type) ?
    {
      // value: ""
    } : null
}


