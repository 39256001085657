import React, {useState, useEffect} from 'react'
import Loader from "../../dataManagement/components/Loader";
import {getDocById} from "../../dataManagement/database/dbFunctions";
import Content from "./Content";

function BannerView({bannerId}) {
    const [banner, setBanner] = useState({
        docId: "",
        bannerId: "",
        bannerName: "",
        Image: "",
        Alt: "",
        imagePosition: "",
        overlayColour: "",
        writtenContent: {},
        links: {},
    })
    const [wholeLink, setWholeLink] = useState(null)

    //Fetch banner
    useEffect(() => {
        getDocById("banner", bannerId)
            .then((doc) => {
                setBanner(doc)
            })
    }, [])

    useEffect(() => {
        if (banner.links.link1) {
            if (banner.links.link1.applyTo === "wbbl" || banner.links.link1.applyTo === "wb") {
                setWholeLink(banner.links.link1.link)
            } else if (banner.links.link2.applyTo === "wbbl" || banner.links.link2.applyTo === "wb") {
                setWholeLink(banner.links.link2.link)
            } else if (banner.links.link2.applyTo === "wbbl" || banner.links.link2.applyTo === "wb") {
                setWholeLink(banner.links.link2.link)
            }
        }
    }, [banner])


    return (
        <>
            <div style={{height: "90vh", marginTop: "1.3rem"}}/>

            <div className={"banner position-absolute overflow-hidden banner-view-container"}>

                {banner.Image === "" ? <Loader/> :
                    <a style={{padding: "0"}} className={"banner__anchor"}
                       href={wholeLink !== null ? wholeLink : false}>

                        <img className={"banner__img h-100 " + (banner.imagePosition)}
                             src={banner.img} alt={banner.alt}
                        />

                        <div style={{backgroundColor: banner.overlayColour}} className="banner__overlay"/>

                        <div>
                            <Content content={banner.writtenContent.content1} link={banner.links.link1} disabled={true}
                                     preview={false}/>
                            <Content content={banner.writtenContent.content2} link={banner.links.link2} disabled={true}
                                     preview={false}/>
                            <Content content={banner.writtenContent.content3} link={banner.links.link3} disabled={true}
                                     preview={false}/>
                        </div>

                    </a>
                }
            </div>
        </>
    )
}

export default BannerView
