import React, {useContext, useState} from 'react';
import {LinkContainer} from "react-router-bootstrap";
import {NavDropdown} from "react-bootstrap";
import {useHistory} from "react-router-dom";

function HeaderDropdown({navTitle, navTitleLink, links}) {
    let history = useHistory();
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(true);
    }
    const hideDropdown = (e) => {
        setShow(false);
    }

    const handleTitleLink = (e) => {
        let index = links.findIndex(l => l.title === e.target.innerText)
        index === -1 ?
            history.push("/" + navTitleLink)
            :
            history.push("/" + links[index].link)
    }

    return (
        <NavDropdown title={navTitle}
                     id="collapsible-nav-dropdown"
                     className={"navbar__dropdown"}
                     style={{zIndex: show ? "1" : "0"}}
                     show={show}
                     onMouseEnter={showDropdown}
                     onMouseLeave={hideDropdown}
                     onClick={(e) => handleTitleLink(e)}
        >

            {links.map((link, index) => {
                return (
                    <LinkContainer to={"/" + link.link} key={index}>
                        <NavDropdown.Item
                            className={"navbar__link navbar__link--dropdown"}
                            onClick={(e) => handleTitleLink(e)}
                        >
                            {link.title}
                        </NavDropdown.Item>
                    </LinkContainer>

                )
            })}


        </NavDropdown>
    );
}

export default HeaderDropdown;