export function getFileId(fileUrl) {
    return fileUrl
        .replace("https://firebasestorage.googleapis.com/", "")
        .replace("v0/b/", "")
        .replace("skipton-camerata-cf704", "")
        .replace(".appspot.com/o", "")
        .replace(new RegExp("/", 'g'), "")
        .replace("?", "")
        .replace(new RegExp("=", 'g'), "")
        .replace("media&token", "")
        .replace(new RegExp("%2F", 'g'), "")
}

export function getFileName(fileUrl) {
    return fileUrl
        .replace("https://firebasestorage.googleapis.com/", "")
        .replace("v0/b/", "")
        .replace("skipton-camerata-cf704", "")
        .replace(".appspot.com/o", "")
        .replace(new RegExp("/", 'g'), "")
        .replace(new RegExp("=", 'g'), "")
        .replace("banner", "")
        .replace(new RegExp("%2F", 'g'), "")
        .split("?")[0]
}