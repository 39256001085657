import React, {useEffect} from 'react';

// Hook that triggers callback when clicks outside of the passed ref
function useOutsideClick(ref, callback) {

    // Trigger click outside callback
    function handleClickOutside(e) {
        if (ref.current && !ref.current.contains(e.target)) {
            callback({click_x: e.clientX, click_y: e.clientY})
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Unbind the event listener on clean up
        return () => document.removeEventListener("mousedown", handleClickOutside);

    }, [ref]);


    return null;
}

export default useOutsideClick;