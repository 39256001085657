import React, {useEffect, useState} from 'react';
import {FetchContent} from "../../dataManagement/FetchContent";
import ImageLibrary from "../../imageManager/ImageLibrary";
import ToggleButtons from "../elements/ToggleButtons";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";
import TextEditor from "../textEditor/TextEditor";
import {Col, Form, Row} from "react-bootstrap";
import HomeAboutUs from "./HomeAboutUs";

function HomeIntro() {
    const [previewMode, setPreviewMode] = useState(false);
    const [toggle, setToggle] = useState(0)
    const [data, setData] = useState([])
    const [titleStyleOptions, setTitleStyleOptions] = useState(null);

    useEffect(() => {
        if (data && data[0] && data[0].homeIntro) {
            setToggle(data[0].homeIntro.online ? 1 : 0)
        }
    }, [data])


    const updater = (val, key) => {
        const tempObj = data[0].homeIntro
        tempObj[key] = val
        setData([{fetchId: "homeIntro", homeIntro: tempObj}])
    }

    const handleImg = (imgArray) => {
        updater(imgArray[0], "img")
    }

    const handleToggle = (val) => {
        updater(val === 1, "online")
    }

    const handleChange = (e) => {
        let {name, value} = e.target
        updater(value, name)
        if (name === "link" && value === "" && data[0].homeIntro.linkActive) updater(false, "linkActive")
        else if (name === "link" && value !== "" && !data[0].homeIntro.linkActive) updater(true, "linkActive")
    }


    return <>
        <FetchContent contentState={[data, setData]} table={"general"} conditions={"homeIntro"}>
            {data && data[0] && data[0].homeIntro && <>
                {/* Mode Button (edit / preview*/}
                <div className={"position-sticky text-right"} style={{top: "4rem", right: "0", zIndex: "99"}}>
                    <button className={"scbtn--med scbtn--1"} onClick={() => {
                        setPreviewMode(!previewMode)
                    }}>
                        {!previewMode ? "Preview" : "Edit"} Mode
                    </button>
                </div>

                {previewMode ?
                    <HomeAboutUs about={data} />
                    :
                    <>
                        {/* Online */}
                        < ToggleButtons onChangeFunc={handleToggle}
                                        buttonArray={[{name: "Online", value: 1}, {name: "Offline", value: 0}]}
                                        btnState={[toggle, setToggle]} />

                        {/* Image */}
                        <div className={"mb-4 mt-5 d-flex"}>
                            <ImageLibrary handleImageChoice={handleImg} directory={"homeIntro"} />
                            {data[0].homeIntro.img !== "" && data[0].homeIntro.img !== undefined &&
                            <img src={data[0].homeIntro.img} alt={data[0].homeIntro.alt} className={"ml-4"}
                                 style={{width: "auto", height: "6.7rem"}} />}
                        </div>

                        {/* Image Caption */}
                        <label className={"general-input__label"}>Image Caption</label>
                        <input className={"general-input"} name={"caption"} type={"text"}
                               placeholder={"Image Caption (Be careful with the length of the caption."}
                               value={data[0].homeIntro.caption} onChange={handleChange} />

                        {/* Alt */}
                        <label className={"general-input__label"}>Image Alt</label>
                        <input className={"general-input"} name={"alt"} type={"text"} placeholder={"Image alternative text"}
                               value={data[0].homeIntro.alt} onChange={handleChange} />

                        {/* Image Link*/}
                        <label className={"general-input__label"}>Image Link</label>
                        <input className={"general-input"} name={"link"} type={"text"} placeholder={"Leave blank to disable link"}
                               value={data[0].homeIntro.link} onChange={handleChange} />

                        {/* Body */}
                        <TextEditor value={data[0].homeIntro.text} keyToUpdate={"text"} handleChange={updater}
                                    imageDirectory={"homeIntro"} className={"line-height-tight"} />
                    </>}
            </>}
        </FetchContent>
        {data && data[0] &&
        <UpdateDocumentField collection={"general"} docId={"homeIntro"} updateKey={"homeIntro"}
                             updateValue={data[0].homeIntro} />}
    </>
}

export default HomeIntro;


//import React, {useEffect, useState} from 'react';
// import {FetchContent} from "../../dataManagement/FetchContent";
// import ImageLibrary from "../../imageManager/ImageLibrary";
// import ToggleButtons from "../elements/ToggleButtons";
// import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";
// import TextEditor from "../textEditor/TextEditor";
// import HomeAboutUs from "./HomeAboutUs";
// import useFetchData from "../../dataManagement/fetchData/useFetchData";
//
// function HomeIntro() {
//     const [previewMode, setPreviewMode] = useState(false);
//     const [toggle, setToggle] = useState(0)
//     const {data, handleChange, err, pending, complete, FetchDataLoader} = useFetchData("general", "homeIntro")
//
//
//     useEffect(() => {
//         if (data && data && data.homeIntro) {
//             setToggle(data.homeIntro.online ? 1 : 0)
//         }
//     }, [data])
//
//     console.log("DATA: ", data)
//
//
//     const onChange = (e) => {
//         let {name, value} = e.target
//         handleChange(value, name)
//         // if (name === "link" && value === "" && data.homeIntro.linkActive) handleChange(false, "linkActive")
//         // else if (name === "link" && value !== "" && !data.homeIntro.linkActive) handleChange(true, "linkActive")
//     }
//
//
//     return <FetchDataLoader>
//         {data && data.homeIntro && <>
//             {/* Mode Button (edit / preview*/}
//             <div className={"position-sticky text-right"} style={{top: "4rem", right: "0", zIndex: "99"}}>
//                 <button className={"scbtn--med scbtn--1"} onClick={() => {
//                     setPreviewMode(!previewMode)
//                 }}>
//                     {!previewMode ? "Preview" : "Edit"} Mode
//                 </button>
//             </div>
//
//             {previewMode ?
//                 <HomeAboutUs about={data} />
//                 :
//                 <>
//                     {/* Online */}
//                     <ToggleButtons onChangeFunc={val => handleChange(val === 1, "online")}
//                                    buttonArray={[{name: "Online", value: 1}, {name: "Offline", value: 0}]}
//                                    btnState={[toggle, setToggle]} />
//
//                     {/* Image */}
//                     <div className={"mb-4 mt-5 d-flex"}>
//                         <ImageLibrary handleImageChoice={imgArray => handleChange(imgArray[0], "img")}
//                                       directory={"homeIntro"} />
//                         {data.homeIntro.img !== "" && data.homeIntro.img !== undefined &&
//                         <img src={data.homeIntro.img} alt={data.homeIntro.alt} className={"ml-4"}
//                              style={{width: "auto", height: "6.7rem"}} />}
//                     </div>
//
//                     {/* Image Caption */}
//                     <label className={"general-input__label"}>Image Caption</label>
//                     <input className={"general-input"} name={"caption"} type={"text"}
//                            placeholder={"Image Caption (Be careful with the length of the caption."}
//                            value={data.homeIntro.caption} onChange={onChange} />
//
//                     {/* Alt */}
//                     <label className={"general-input__label"}>Image Alt</label>
//                     <input className={"general-input"} name={"alt"} type={"text"} placeholder={"Image alternative text"}
//                            value={data.homeIntro.alt} onChange={onChange} />
//
//                     {/* Image Link*/}
//                     <label className={"general-input__label"}>Image Link</label>
//                     <input className={"general-input"} name={"link"} type={"text"} placeholder={"Leave blank to disable link"}
//                            value={data.homeIntro.link} onChange={onChange} />
//
//                     {/* Body */}
//                     <TextEditor value={data.homeIntro.text} keyToUpdate={"text"} onChange={handleChange}
//                                 imageDirectory={"homeIntro"} className={"line-height-tight"} />
//                 </>}
//         </>}
//         {data && data &&
//         <UpdateDocumentField collection={"general"} docId={"homeIntro"} updateKey={"homeIntro"}
//                              updateValue={data.homeIntro} />}
//     </FetchDataLoader>
// }
//
// export default HomeIntro;