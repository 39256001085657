import React, {useEffect, useState} from 'react';
import {fetchBanner} from "../../dataManagement/fetchBanner";
import Loader from "../../dataManagement/components/Loader";
import {Button, Collapse, Spinner} from "react-bootstrap";
import BannerCreator from "./BannerCreator";
import {deleteData, getDocById, setData} from "../../dataManagement/database/dbFunctions";
import ToastNotification from "../blocks/ToastNotification";
import PopupModal from "../blocks/PopupModal";
import BannerView from "./BannerView";


function BannerLibrary() {
    const [banners, setBanners] = useState(["none"])
    const [view, setView] = useState({
        show: false,
        bannerId: ""
    })
    const [showCreator, setShowCreator] = useState(false)
    const [creatorBannerId, setCreatorBannerId] = useState("none")
    const [bannerOnDb, setBannerOnDb] = useState(false)
    const [deleteStatus, setDeleteStatus] = useState({pending: false, success: false, err: false});
    // Set live banner ID:
    const [liveConfirmId, setLiveConfirmId] = useState("");

    //show creator handler
    function handleShowCreator(bannerId, isBannerOnDb) {
        setBannerOnDb(isBannerOnDb)
        setCreatorBannerId(bannerId)
        setShowCreator(true)
    }

    //Show banner Preview
    function handleView(show, bannerId) {
        show === false ?
            setView({show: false, bannerId: ""})
            :
            setView({show: true, bannerId: bannerId})
    }

    //Fetch banner
    useEffect(() => {
        fetchBanner("all", setBanners)
    }, [deleteStatus, showCreator, liveConfirmId])

    //Create banner name array for banner creator name check
    const [bannerNames, setBannerNames] = useState(["live-banner"])
    useEffect(() => {
        if (banners[0] !== "none") {
            banners.forEach((banner, index) => {
                setBannerNames(prevState => [...prevState, banners[index][1].bannerName])
            })
        }
    }, [banners])


    return (
        <>
            {banners[0] === "none" ? <Loader/> : <>
                {view.show === true ?
                    <div className={"w-100"} style={{marginTop: "-3rem"}}>
                        <Button
                            className={"sc-red-btn bg-danger border-danger position-absolute z-index-front white-colour"}
                            style={{top: "6rem", right: "0", height: "3.2rem", zIndex: "99", fontSize: "1.5rem",}}
                            onClick={() => handleView(false, "")}>
                            Close Banner <img className={"ml-2"} style={{height: "1.5rem"}}
                                              src={"/images/icons/close.svg"} alt={"Close"}/>
                        </Button>
                        <BannerView bannerId={view.bannerId}/>
                    </div>
                    :
                    <>

                        <div className={"d-flex position-relative"}>
                            <button onClick={() => handleShowCreator("none", false)}
                                    className={"scbtn--2 scbtn--large ml-auto"}
                                    style={{right: "0", top: "1.6rem"}}
                            >
                                Create a new banner
                            </button>
                            {showCreator &&
                            <BannerCreator setShowCreator={setShowCreator} bannerNames={bannerNames}
                                           bannerId={creatorBannerId} bannerOnDb={bannerOnDb}/>}
                        </div>


                        <LiveBanner banners={banners} liveConfirmId={liveConfirmId}/>

                        <BannerImageMap banners={banners} handleView={handleView}
                                        handleShowCreator={handleShowCreator}
                                        deleteStatusState={[deleteStatus, setDeleteStatus]}
                                        liveConfirmIdState={[liveConfirmId, setLiveConfirmId]}/>


                    </>}
            </>}
        </>
    );
}

export default BannerLibrary;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// banner Library
function BannerImageMap(
    {
        banners, handleView, handleShowCreator, deleteStatusState, liveConfirmIdState
    }
) {


    return (
        <>
            <h4 className={"pb-0 mt-4"}>Banner Library</h4>
            <div className={"d-flex flex-wrap"}>

                {banners.filter((b) => {
                    return b[0] !== "live-banner"
                }).map((obj, index) => {
                    return (
                        <div key={index} style={{maxWidth: "22.2rem"}}
                             className={"p-3 mr-3 mb-3 border border-thin"}>
                            <p className={"mb-0 line-height-tight font-weight-light text-small"}
                               style={{height: "5.5rem"}}>{obj[1].bannerName}</p>
                            <div className={"d-flex justify-content-center position-relative"}
                                 style={{height: "9rem", width: "20rem"}}>
                                <img style={{width: "100%"}}
                                     src={obj[1].img}
                                     alt={obj[1].img}
                                />
                                <div
                                    style={{
                                        backgroundColor: obj[1].overlayColour,
                                        opacity: obj[1].overlayOpacity /= Math.pow(10, 2),
                                    }}
                                    className="banner__overlay"/>
                            </div>
                            <div className={"mt-0 py-3 d-flex justify-content-around"} style={{width: "20rem"}}>

                                <SetAsLiveBtn bannerId={obj[0]} bannerName={obj[1].bannerName}
                                              liveConfirmIdState={liveConfirmIdState}/>

                                <button onClick={() => handleView(true, obj[0])}
                                        id={obj[0]}
                                        className={"scbtn--small scbtn--1 mr-2 btn-small"}
                                        style={{padding: ".3rem .7rem", minWidth: "2rem"}}>
                                    View
                                </button>
                                <button className={"scbtn--small scbtn--1 mr-2 btn-small"}
                                        style={{padding: ".3rem .7rem", minWidth: "2rem"}}
                                        onClick={() => handleShowCreator(obj[0], true)}>Edit
                                </button>

                                <DeleteBanner bannerId={obj[0]} deleteStatusState={deleteStatusState}/>
                            </div>
                        </div>
                    )
                })}

            </div>
        </>
    );
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Live banner
function LiveBanner(
    {
        banners
    }
) {

    return (
        <div className={"border-thin border-bottom pb-5"}>
            <h4>Live Banner</h4>
            <div className={"d-flex flex-wrap"}>

                {banners.map((obj, index) => {
                    return (
                        <>
                            {obj[0] === "live-banner" &&
                            <div className={"d-flex justify-content-center position-relative"}
                                 style={{height: "9rem", width: "20rem"}}>
                                {obj[1].img !== "" &&
                                <img style={{width: "100%", height: "100%"}} src={obj[1].img} alt={obj[1].img}/>}
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: obj[1].overlayColour,
                                    opacity: obj[1].overlayOpacity /= Math.pow(10, 2),
                                }} className="banner__overlay"/>
                            </div>}
                        </>)
                })}

            </div>
        </div>
    )
        ;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//delete btn & collapse
function SetAsLiveBtn(
    {
        bannerId,
        bannerName,
        liveConfirmIdState:
            [liveConfirmId, setLiveConfirmId]
    }
) {
    const [savingStatus, setSavingStatus] = useState({pending: false, saved: false, err: false, fetched: false});
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        liveConfirmId === bannerId && setOpenModal(true) && setOpenModal(true)
    }, [liveConfirmId])


    const setAsLive = () => {
        setLiveConfirmId(bannerId)
        setSavingStatus({pending: true, saved: false, err: false, fetched: false})
        getDocById("banner", bannerId)
            .then((doc) => {

                setSavingStatus({pending: true, saved: false, err: false, fetched: true})

                setData("banner", "live-banner", doc)
                    .then(id => {
                        setSavingStatus({pending: false, saved: true, err: false, fetched: false})
                        setOpenModal(false)
                        setLiveConfirmId(null)
                    })
                    .catch(err => {
                        console.log(err)
                    })

            })
    }


    return (
        <>
            <button
                className={"scbtn--small scbtn--1 mr-2 btn-small"}
                style={{padding: ".3rem .7rem", minWidth: "2rem"}}
                onClick={() => setLiveConfirmId(bannerId)}
                aria-controls="example-collapse-text"
                aria-expanded={liveConfirmId}
            >
                Publish
            </button>
            <PopupModal title={"Set As Live banner"} buttonText={"Cancel"} greenBtnText={"Confirm"}
                        greenBtnFunction={setAsLive} showModalState={[openModal, setOpenModal]}>

                <div className={"p-2 d-flex justify-content-between align-items-center"}
                >
                    <p>
                        Set <strong><u>{bannerName}</u></strong> as the home page banner?
                    </p>

                </div>
                {savingStatus.pending && <span className={"ml-2"}><Spinner animation={"border"} size="md"/></span>}
                {savingStatus.saved && <span className={"ml-2"}>Success</span>}
                {savingStatus.err && <span className={"ml-2"}>Failed</span>}
            </PopupModal>
            {/*<ToastNotification header={deleteStatus.success ? "banner eliminado" : "Algo salió mal"}*/}
            {/*                   body={deleteStatus.success ? "El banner se ha eliminado correctamente." : "No se pudo eliminar el banner. Inténtalo de nuevo."}*/}
            {/*                   className={deleteStatus.success ? "background__success" : "background__fail"}*/}
            {/*                   showState={[showNotification, setShowNotification]}/>*/}
        </>
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//delete btn & collapse
function DeleteBanner(
    {
        bannerId, deleteStatusState:
        [deleteStatus, setDeleteStatus]
    }
) {
    const [openDelete, setOpenDelete] = useState(false);
    const [showNotification, setShowNotification] = useState(false);


    return (
        <div className={"position-relative"}>
            <button
                className={"scbtn--small scbtn--1 mr-2 btn-small"}
                style={{padding: ".3rem .7rem", minWidth: "2rem"}}
                onClick={() => setOpenDelete(!openDelete)}
                aria-controls="example-collapse-text"
                aria-expanded={openDelete}
            >
                Delete
            </button>
            <Collapse in={openDelete} className={""}>
                <div className={"border-red border-thin background-white border-grey position-absolute mt-2"}
                     style={{right: "0", width: "20rem"}}>
                    <div className={"p-3"}
                    >
                        <p className={"text-small mb-0"}>
                            {!deleteStatus.pending ? "Delete this banner?" :
                                <Spinner animation={"border"} size="sm"/>}
                        </p>
                        <Button
                            onClick={() => {
                                setDeleteStatus({pending: true, success: false, err: false})
                                deleteData("banner", bannerId)
                                    .then(() => {
                                        setOpenDelete(false)
                                        setTimeout(() => {
                                            setDeleteStatus({pending: false, success: true, err: false})
                                        }, 120)
                                    })
                                    .catch(() => {
                                        setOpenDelete(false)
                                        setTimeout(() => {
                                            setDeleteStatus({pending: false, success: false, err: true})
                                        }, 120)
                                    })
                            }}
                            className={"sc-red-btn border-danger dm-btn-solid px-3 mr-2"} style={{borderRadius: "0"}}>
                            Confirm
                        </Button>
                        <Button
                            onClick={() => setOpenDelete(false)} style={{borderRadius: "0"}}
                            className={"bg-success scbtn--2  border-success dm-btn-solid px-3"}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Collapse>
            <ToastNotification header={deleteStatus.success ? "Banner deleted" : "Something went wrong"}
                               body={deleteStatus.success ? "The banner has been successfully deleted." : "The banner could not be deleted. Please try again."}
                               className={deleteStatus.success ? "background__success" : "background__fail"}
                               showState={[showNotification, setShowNotification]}/>
        </div>
    );
}