import React, {useEffect, useState} from 'react';
import PageTop from "../../components/blocks/PageTop";
import ToggleButtons from "../../components/elements/ToggleButtons";
import Input from "../../components/elements/Input";
import {Col, Row} from "react-bootstrap";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";
import {FetchContent} from "../../dataManagement/FetchContent";

function HeaderBtn() {
    const [btnToggle, setBtnToggle] = useState(0)
    const [data, setData] = useState([])
    const [headerBtn, setHeaderBtn] = useState([{
        display: false,
        link: "",
        title: "",
    }])
    const [long, setLong] = useState("")

    useEffect(() => {
        headerBtn && headerBtn[0].title.length >= 22 && setLong(`Try shorten the text. The button is getting too big for some smaller screens.`)
        headerBtn && headerBtn[0].title.length < 22 && setLong("")
    }, [headerBtn])

    useEffect(() => {
        const tempObj = headerBtn[0]
        tempObj.display = btnToggle === 1
        setHeaderBtn([tempObj])
    }, [btnToggle])

    useEffect(() => {
        if (data && data[0] && data[0].headerBtn) {
            setHeaderBtn(data[0].headerBtn)
            setBtnToggle(data[0].headerBtn[0].display ? 1 : 0)
        }
    }, [data])

    // console.log(data)


    return <>
        <PageTop title={"Header Button"} section={"headerBtn"} />

        <FetchContent contentState={[data, setData]} table={"general"} conditions={"headerBtn"}>
            {headerBtn && headerBtn[0] &&
            <Row>
                <Col md={3} className={"mb-3"}>
                    < label className={"general-input__label"}>Display Button?</label><br />
                    <ToggleButtons buttonArray={[{name: "Yes", value: 1}, {name: "No", value: 0}]}
                                   btnState={[btnToggle, setBtnToggle]} />
                </Col>
                <Col md={4}>
                    <Input value={headerBtn[0].title}
                           handleChange={v => setHeaderBtn([{...headerBtn[0], title: v}])}
                           keyToUpdate={"title"}
                           label={"Button Text"} />
                    <p>{long}</p>
                </Col>
                <Col md={5}>
                    <Input value={headerBtn[0].link}
                           handleChange={v => setHeaderBtn([{...headerBtn[0], link: v}])}
                           keyToUpdate={"link"}
                           label={"Button Link"} />
                </Col>
            </Row>}
        </FetchContent>
        <UpdateDocumentField collection={"general"} docId={"headerBtn"} updateKey={"headerBtn"}
                             updateValue={headerBtn} />
    </>
}

export default HeaderBtn;