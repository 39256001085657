import React, {useContext} from 'react';
import TextEditor from "../textEditor/TextEditor";
import {EventEditorContext} from "../../providers/EventEditorContext";

function Event_TextEditorFormSection() {
    const {event, handleChange} = useContext(EventEditorContext)

    return (
        <>
            <div>
                <h4 style={{margin: "2rem auto 0"}}>Main Description</h4>
                {<TextEditor value={event.body} handleChange={handleChange}
                             imageDirectory={`events/${event.id}/body`}
                             keyToUpdate={"body"} rerenderKey={`${event.id}-body`} />}

            </div>
            {/*Editor - Programme*/}
            <div>
                <hr />
                <h4 style={{margin: "2rem auto 0"}}>Repertoire Info</h4>
                <TextEditor value={event.programme} handleChange={handleChange}
                            imageDirectory={`events/${event.id}/programme`}
                            keyToUpdate={"programme"} rerenderKey={`${event.id}-prog`} />
            </div>
            {/*Editor - Performers*/}
            <div>
                <hr />
                <h4 style={{margin: "2rem auto 0"}}>Performers Info</h4>
                <TextEditor value={event.performers} handleChange={handleChange}
                            imageDirectory={`events/${event.id}/performers`}
                            keyToUpdate={"performers"} rerenderKey={`${event.id}-perf`} />
            </div>
            {/*Editor - Ticket*/}
            <div>
                <hr />
                <h4 style={{margin: "2rem auto 0"}}>Ticket Info</h4>
                <TextEditor value={event.ticket} handleChange={handleChange}
                            imageDirectory={`events/${event.id}/ticket`}
                            keyToUpdate={"ticket"} rerenderKey={`${event.id}-ticket`} />
            </div>
        </>
    );
}

export default Event_TextEditorFormSection;