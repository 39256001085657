import React, {useContext, useRef, useState} from "react";
import {decode, encode} from "html-entities";
import Editor from 'ckeditor5-custom-build';
import {CKEditor} from '@ckeditor/ckeditor5-react'
import {handleOnBlur, handleOnFocus, UploadAdapter} from "./textEditorHelper";
import {
    basicToolbarConfig,
    bgColourConfig,
    fontColourConfig,
    headingConfig,
    imageConfig,
    linkConfig,
    mediaConfig,
    tableConfig,
    toolbarConfig,
} from "ckeditor5-custom-build/src/editorConfig";
import TextEditorContext from "../../providers/TextEditorContext";
import HtmlReader from "../blocks/HtmlReader";
import useOutsideClick from "../../customHooks/useOutsideClick";

const defaultConfiguration = {
    fontColor: fontColourConfig,
    fontBackgroundColor: bgColourConfig,
    table: tableConfig,
    image: imageConfig,
    mediaEmbed: mediaConfig,
    placeholder: "Start here...",
    link: linkConfig,
    heading: headingConfig
}

const editorConfiguration = {
    ...defaultConfiguration,
    toolbar: toolbarConfig,
};

const basicConfiguration = {
    ...defaultConfiguration,
    toolbar: basicToolbarConfig,
};


function TextEditor({
                        handleChange,
                        value = "--Loading--",
                        imageDirectory,
                        keyToUpdate,
                        toolbar = "full",
                        className = "",
                        rerenderKey = keyToUpdate,
                        onReadyFunc = null
                    }) {  // options: "full", "basic", "none"readOnly = false
    const {textEditorCleanup, setTextEditorCleanup} = useContext(TextEditorContext)
    const [showEditor, setShowEditor] = useState(null);
    const editorRef = useRef(null);

    /*
    * CALCULATES IF EDITOR SHOULD BE CLOSED OR NOT
    * - useOutsideClick only triggers if click is detected outside the passed ref
    * - Widgets are renders outside of editor and so useOutsideClick is triggered when widgets are clicked
    * - To ensure to close the editor only when clicking outside the editor or a ck widget, the bellow works out if the click was outside the boundaries of a widget, and only closes it if it is.
    */
    useOutsideClick(editorRef, ({click_x, click_y}) => {
        // Get boundary positions for editor and balloon widget
        const {left: editor_l, right: editor_r, top: editor_t, bottom: editor_b} = editorRef.current.getBoundingClientRect()
        const balloon = document.getElementsByClassName("ck-balloon-panel")[0]
        // If balloon exists, grab the dimensions. Else, set values as 0
        const {left: balloon_l, right: balloon_r, top: balloon_t, bottom: balloon_b} = balloon ? balloon.getBoundingClientRect()
            : {left: 0, right: 0, top: 0, bottom: 0}

        // calculate if click was outside of boundaries of editor or balloon widget
        const outsideEditor = !(editor_l < click_x && click_x < editor_r && editor_t < click_y && click_y < editor_b)
        const outsideBalloon = !(balloon_l < click_x && click_x < balloon_r && balloon_t < click_y && click_y < balloon_b)

        // if click was outside editor or balloon: setShowEditor(false)
        if (outsideEditor && outsideBalloon) setShowEditor(null)
    });


    return (
        <>
            {value !== "--Loading--" &&
            <div>

                <div className={"data-from-editor border-grey border-thin mb-3 " + (className)}
                     key={rerenderKey}
                     ref={editorRef}
                     onClick={() => setShowEditor(keyToUpdate)}
                >

                    {showEditor !== keyToUpdate ?
                        <div className={"border-0 background-colour w-100 pb-0"} style={{cursor: "text"}}>
                            <div className={"pt-2 px-2"} style={{minHeight: "10rem"}}>
                                <HtmlReader editorId={`ck-${keyToUpdate}`}
                                            htmlContent={decode(value.length ? value : "<p class='light-grey-colour'>Click here to edit...</p>")} />
                            </div>
                        </div>
                        :
                        <>
                            <CKEditor
                                id={`ck-${keyToUpdate}`}
                                editor={Editor}
                                config={toolbar === "full" ? editorConfiguration : toolbar === "basic" ? basicConfiguration : []}
                                data={decode(value)}
                                onReady={editor => {
                                    onReadyFunc && onReadyFunc()
                                    editor.focus()
                                    editor.model.change(writer => {
                                        writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), 'end'));
                                    });
                                }}
                                onFocus={(event, editor) => {
                                    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                                        return new UploadAdapter(loader, imageDirectory);
                                    };
                                    handleOnFocus(setTextEditorCleanup, imageDirectory)
                                }}
                                onChange={(event, editor) => {
                                    const data = encode(editor.getData());
                                    handleChange(data, keyToUpdate)
                                }}
                                onBlur={(event, editor) => {
                                    const data = encode(editor.getData());
                                    handleOnBlur(data, imageDirectory, textEditorCleanup, setTextEditorCleanup)
                                }}
                            />
                        </>}
                </div>
            </div>}

        </>
    );
}

export default TextEditor;