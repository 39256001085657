import React from 'react';

function StatsContainer() {
    return (
        <>
            <h5>General Statistics</h5>
            <p>Coming soon...</p>
        </>
    );
}

export default StatsContainer;