import React, {useEffect, useState} from 'react';
import BannerPreview from "./BannerPreview";
import Page4Content from "./Page4Content";
import Page4Link from "./Page4Link";
import ToggleButtons from "../elements/ToggleButtons";


// Add Content

function Page4({bannerState}) {
    const [toggle, setToggle] = useState(1)
    const [allContent, setAllContent] = useState({
        content1: bannerState[0].writtenContent.content1,
        content2: bannerState[0].writtenContent.content2,
        content3: bannerState[0].writtenContent.content3,
    });
    // create array from content.position
    const [vert1, hoz1] = allContent.content1.position.split("-")
    const [vert2, hoz2] = allContent.content2.position.split("-")
    const [vert3, hoz3] = allContent.content3.position.split("-")
    // set position states with above arrays
    const [pos1, setPos1] = useState({vert: vert1, hoz: hoz1});
    const [pos2, setPos2] = useState({vert: vert2, hoz: hoz2});
    const [pos3, setPos3] = useState({vert: vert3, hoz: hoz3});

    useEffect(() => {
        bannerState[1](prevState => {
            return {
                ...prevState,
                writtenContent: {
                    content1: allContent.content1,
                    content2: allContent.content2,
                    content3: allContent.content3
                }
            }
        })
    }, [allContent])


    function handleContentChange(e, i, key) {
        const {value} = e.target
        let tempObj = allContent["content" + (i + 1)]
        if (key === "custom") {
            tempObj.custom = !tempObj.custom
        } else if (key === "contentQuick" || key === "contentCustom" || key === "textAlign") {
            tempObj[key] = value
        } else {
            let setPosition = i === 0 ? setPos1 : i === 1 ? setPos2 : setPos3
            if (key === "vertical") {
                let prevValue = i === 0 ? pos1.hoz : i === 1 ? pos2.hoz : pos3.hoz
                setPosition(prevState => {
                    return {...prevState, vert: value}
                })
                tempObj.position = `${value}-${prevValue}`
            } else if (key === "horizontal") {
                let prevValue = i === 0 ? pos1.vert : i === 1 ? pos2.vert : pos3.vert
                setPosition(prevState => {
                    return {...prevState, hoz: value}
                })
                tempObj.position = `${prevValue}-${value}`
            }
            key = "position"
        }
        setAllContent((prevValue) => {
            return {
                ...prevValue,
                [key]: tempObj
            }
        })
    }

    return (
        <div style={{overflowX: "hidden"}}>
            <h3 className={"pl-5 mt-4"}>Content settings</h3>

            <BannerPreview bannerState={bannerState}/>

            <div className={"mb-3 mt-5 container d-flex align-items-center justify-content-between"}>
                <h5 className={"mb-0 mr-3"}>Content</h5>
                <div>
                    <ToggleButtons btnState={[toggle, setToggle]} buttonArray={[{name: "Content 1", value: 1},
                        {name: "Content 2", value: 2}, {name: "Content 3", value: 3}]}/>
                </div>
            </div>


            {[...Array(3)].map((obj, i) => {
                let contentI = i === 0 ? allContent.content1 : i === 1 ? allContent.content2 : i === 2 ? allContent.content3 : null
                return <>
                    <div className={"container " + (toggle === i + 1 ? "" : "collapse")}>
                        <Page4Content contentI={contentI} allContent={allContent}
                                      handleContentChange={handleContentChange}
                                      pos1={pos1} pos2={pos2} pos3={pos3} i={i}/>
                        <hr/>
                        <h5 className={"mb-0 mt-3"}>Link</h5>
                        <Page4Link bannerState={bannerState} linkNum={i + 1}/>
                    </div>
                </>
            })}


        </div>
    );
}

export default Page4;