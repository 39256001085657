import React from 'react';
import BannerLibrary from "../components/banner/BannerLibrary";
import PageTop from "../components/blocks/PageTop";
import {useParams} from "react-router-dom";
import HomeDisplay from "../components/home/HomeDisplay";
import HomeWelcome from "../components/home/HomeWelcome";
import HomeIntro from "../components/home/HomeIntro";

function Home() {
    const {section} = useParams()
    return <>
        <PageTop title={`Home ${section !== undefined ? section : ""}`} section={"home"} />
        {section === "banner" ? <BannerLibrary /> :
            section === "display" ? <HomeDisplay /> :
                section === "welcome" ? <HomeWelcome /> :
                    section === "intro" ? <HomeIntro /> :
                        <div className={"d-flex justify-content-center align-items-center"} style={{minHeight: "30vh"}}>
                            <div>
                                <h4 style={{opacity: ".6"}}>Select an option from the home menu above</h4>
                            </div>
                        </div>}

    </>
}

export default Home;