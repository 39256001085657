import React, {useContext, useEffect, useRef, useState} from "react";
import PageTop from "../blocks/PageTop";
import SetSectionDocument from "../../dataManagement/SetSectionDocument";
import {SurveyEditorContext} from "../../providers/SurveyEditorContext";
import SurveyPreview from "./SurveyPreview";
import SurveyEditorForm from "./SurveyEditorForm";

function SurveyEditorContainer() {
  const {survey, surveyId, fetchErr, fetchComplete, handlePreSave} = useContext(SurveyEditorContext)
  const [previewMode, setPreviewMode] = useState(false);
  const titleBeforeUpdate = useRef({set: false, title: ""})

  useEffect(() => {
    if (fetchComplete) {
      if (!titleBeforeUpdate.current.set) titleBeforeUpdate.current = {set: true, title: survey.title}
    }
  }, [surveyId, fetchComplete])

  return <>
    <PageTop section={"surveys"} title={surveyId === "new" ? "Survey Creator" : "Survey Editor"} />

    {/* Set edit || preview mode button */}
    <div className={"position-sticky text-right"} style={{top: "5rem", right: "0", zIndex: "99"}}>
      <button className={"scbtn--med scbtn--1"} onClick={() => setPreviewMode(!previewMode)}>
        {!previewMode ? "Preview" : "Edit"} Mode
      </button>
    </div>


    {/* Preview || Editor Form */}
    {/*{previewMode ? <SurveyPreview survey={survey} /> : <SurveyEditorForm />}*/}


    {/* Create/Update Button */}
    {surveyId === "new" && survey &&
    <SetSectionDocument document={survey} collection={"surveys"} docId={survey.id} btnText={"Create Survey"}
                        cancelLink={"surveys"} callback={handlePreSave} isUpdate={false} />}
    {surveyId !== "new" && survey &&
    <SetSectionDocument document={survey} collection={"surveys"} docId={surveyId} btnText={"Update Survey"}
                        cancelLink={"surveys"} callback={handlePreSave} isUpdate
                        oldTitle={titleBeforeUpdate.current.title} />}

  </>
}

export default SurveyEditorContainer;