import React, {useContext} from 'react';
import {Form, Row} from "react-bootstrap";
import Loader from "../../dataManagement/components/Loader";
import CredContext from "../../providers/CredContext";

function GeneralSettings({
                             accessState: [access, setAccess],
                             userState: [user, setUser],
                             accountTypeState: [accountType, setAccountType],
                             savingStatus,
                         }) {
    const cred = useContext(CredContext)


    // handle Access changes
    const handleChecked = (e) => {
        let key = e.target.id
        setAccess(prevState => {
            return {...prevState, [key]: !access[key]}
        })
    }

    // handle Account Type changes
    const handleAccountTypeChange = (e) => {
        let {value} = e.target
        let updates = value === "editor" ? {admin: false, principal: false} : value === "admin"
            ? {admin: true, principal: false} : {admin: true, principal: true}
        setUser(prevValue => {
            return {...prevValue, ...updates}
        })
        setAccountType(updates)
    }


    return (
        <>

            {cred.principal && <>
                <h4 className={"font-family-paragraph"}>Account Type</h4>
                {user && <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className={"readers-only"}>Account Type</Form.Label>
                    <Form.Control as="select" className={"general-input"}
                                  value={accountType.admin ? "admin" : "editor"}
                                  onChange={handleAccountTypeChange}
                    >
                        <option value={"editor"}>Editor</option>
                        <option value={"admin"}>Admin</option>
                    </Form.Control>
                </Form.Group>}
                {accountType.admin ?
                    <div className={"pb-3 pt-0"}>
                        <p className={"font-weight-bold mb-0 text-small"}>Admin accounts can:</p>
                        <ul className={"mb-1"} style={{listStyle: "none"}}>
                            <li>Edit all areas of the website</li>
                            <li>Create and delete Editor accounts</li>
                            <li>Manage access for users with Editor accounts</li>
                        </ul>
                        <p className={"font-weight-bold mb-0 text-small"}>Admin account <u>cannot</u>:</p>
                        <ul style={{listStyle: "none"}}>
                            <li>Change account types</li>
                            <li>
                                Make any change to other Admin accounts or the Site Manager account.
                            </li>
                        </ul>
                    </div>
                    :
                    <div className={"pb-3 pt-0"}>
                        <p className={"font-weight-bold mb-0 text-small"}>Editor accounts can:</p>
                        <ul style={{listStyle: "none"}}>
                            <li>Edit areas of the website where access is granted</li>
                        </ul>
                        <p className={"font-weight-bold mb-0 text-small"}>Editor accounts <u>cannot</u>:</p>
                        <ul style={{listStyle: "none"}}>
                            <li>
                                Access the "Account Manager" page to create, delete or manage accounts.
                            </li>
                        </ul>
                    </div>
                }</>}


            {!accountType.admin && <>
                <p className={"font-weight-bold mb-0"}>Access settings</p>

                <Row className={"d-flex flex-wrap"}>
                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.home} id={"home"} onChange={e => handleChecked(e)}
                                    type="checkbox" label="Home Page" className={""} />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.events} id={"events"} onChange={e => handleChecked(e)}
                                    type="checkbox" label="Events" />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.news} id={"news"} onChange={e => handleChecked(e)}
                                    type="checkbox" label="News" />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.about} id={"about"} onChange={e => handleChecked(e)}
                                    type="checkbox" label="About Us" />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.support} id={"support"}
                                    onChange={e => handleChecked(e)}
                                    type="checkbox" label="Support Us" />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.legal} id={"legal"}
                                    onChange={e => handleChecked(e)}
                                    type="checkbox" label="Policies Pages" />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.headerFooter} id={"headerFooter"}
                                    onChange={e => handleChecked(e)}
                                    type="checkbox" label="Header & Footer" />
                    </Form.Group>

                    <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
                        <Form.Check checked={access.message} id={"message"}
                                    onChange={e => handleChecked(e)}
                                    type="checkbox" label="Message Strip" />
                    </Form.Group>


                </Row>
            </>}

            {savingStatus.pending &&
            <>
                <div className={"position-absolute background-colour"}
                     style={{height: "100%", width: "100%", top: "0", left: "0", opacity: ".8"}}>
                    <Loader />
                </div>
            </>}
            {savingStatus.message &&
            <>
                <br />
                <p className={"py-1 px-3 mt-2 mb-0 " + (savingStatus.success ? "background__success" : "background__fail")}>{savingStatus.message}</p>
            </>}
        </>
    );
}

export default GeneralSettings;