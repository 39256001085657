import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {RgbaStringColorPicker} from "react-colorful";
import BannerPreview from "./BannerPreview";
import {handleBannerInputChange} from "./BannerHelper";


// Position & Align Image

function Page3({bannerState}) {
    const [colour, setColour] = useState(bannerState[0].overlayColour)
    const [vertical, horizontal] = bannerState[0].imagePosition.replace("image__", "").split("-")
    const [imagePos, setImagePos] = useState({
        vertical: vertical,
        horizontal: horizontal
    })
    useEffect(() => {
        bannerState[1](prevState => {
            return {...prevState, imagePosition: `image__${imagePos.vertical}-${imagePos.horizontal}`}
        })
    }, [imagePos])

    useEffect(() => {
        bannerState[1](prevState => {
            return {...prevState, overlayColour: colour}
        })
    }, [colour])

    return (
        <div className={"w-100"}>

            <h3 className={"pl-5 mt-4"}>Image settings</h3>

            <BannerPreview bannerState={bannerState}/>

            <Row className={"container m-auto"}>
                <Col>
                    <h4 className={"mt-1"}>Image alignment</h4>
                    <p className={"text-small"}>
                        How should the image align? This is important for small screens.
                    </p>
                    <div className={"w-100 d-flex"}>
                        <Form.Group className={"mr-3 w-50"}>
                            <Form.Label className={"text-small general-input__label"}>Vertical</Form.Label>
                            <Form.Control as={"select"} value={imagePos.vertical} id={"vertical"}
                                          className={"general-input"}
                                          onChange={(e) => handleBannerInputChange(e, setImagePos)}>
                                <option value={"top"}>Top</option>
                                <option value={"middle"}>Middle</option>
                                <option value={"bottom"}>Bottom</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className={"w-50"}>
                            <Form.Label className={"text-small general-input__label"}>Horizontal</Form.Label>
                            <Form.Control as="select" value={imagePos.horizontal} id={"horizontal"}
                                          className={"general-input"}
                                          onChange={(e) => handleBannerInputChange(e, setImagePos)}>
                                <option value={"left"}>Far Left</option>
                                <option value={"left_25"}>Left of Centre</option>
                                <option value={"centre"}>Centre</option>
                                <option value={"right_25"}>Right of Centre</option>
                                <option value={"right"}>Right</option>
                            </Form.Control>
                        </Form.Group>
                    </div>

                </Col>
                <Col>
                    <h4 className={"mt-1"}>Image overlay</h4>
                    <p className={"text-small"}>
                        Optional: Choose a color and opacity for the overlay image. Opacity between 1 and 100%.
                    </p>
                    <div>

                        <RgbaStringColorPicker color={colour} onChange={setColour}/>

                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Page3;