import React, {useEffect, useState} from "react";
import {getAllData, updateData} from "../dataManagement/database/dbFunctions";
import moment from "moment";
import Loader from "../dataManagement/components/Loader";
import {db} from "../dataManagement/firebaseConfig";
import firebase from "firebase/app";
import HtmlReader from "../components/blocks/HtmlReader";

function EventDatesDbSeeder() {
  const [dataUpdateIndex, setDataUpdateIndex] = useState(0);
  const [totalDocsToUpdate, setTotalDocsToUpdate] = useState(0);
  const [seedInProgress, setSeedInProgress] = useState(false);

  const startSeed = () => {
    setSeedInProgress(true)

    getAllData("events")
      .then(data => {
        setTotalDocsToUpdate(data.length)


        let docIndex = 0
        const updateSingleEvent = () => {

          let ref = db.collection("events").doc(data[docIndex].id);

          ref.update({"programmeNotes.donateLink": "support-us"})
            .then(() => {
              console.log("UPDATED: ", data[docIndex].id)
            })
            .catch(err => {
              console.log("ERROR ID: ", data[docIndex].id)
              console.log("ERROR: ", err)
            })
            .finally(() => {
              docIndex = docIndex + 1
              setDataUpdateIndex(prevIndex => prevIndex + 1)
              // if (docIndex < 3) updateSingleEvent()
              if (docIndex < data.length) {
                setTimeout(() => {
                  updateSingleEvent()
                }, 100)
              } else {
                setSeedInProgress(false)
                setTotalDocsToUpdate(0)
                setDataUpdateIndex(0)
              }
            })
        }
        updateSingleEvent()

      })
  }


  return (
    <>
      <h1>SEEDER PAGE</h1>

      <div className={"d-flex"}>
        <button className={"scbtn scbtn--2 scbtn--large mr-4"} style={{minWidth: "16rem"}} onClick={startSeed}>
          {seedInProgress ? <Loader spinner /> : "Start Seed "}
        </button>

        <h4> {seedInProgress && totalDocsToUpdate === 0 ? "Fetching Docs" : seedInProgress ? `Updating ${dataUpdateIndex} of ${totalDocsToUpdate} docs` : "Idol"} </h4>
      </div>

    </>
  );
}

export default EventDatesDbSeeder;