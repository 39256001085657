import React from 'react';
import EventEditorProvider from "../providers/EventEditorContext";
import EventEditorContainer from "../components/events/EventEditorContainer";

function EventEditorScreen() {
    return (
        <EventEditorProvider>
            <EventEditorContainer />
        </EventEditorProvider>
    );
}

export default EventEditorScreen;