import React from 'react';
import NewsEditorProvider from "../providers/NewsEditorContext";
import NewsEditorContainer from "../components/news/NewsEditorContainer";

function NewsArticleEditorScreen() {
    return (
        <NewsEditorProvider>
            <NewsEditorContainer />
        </NewsEditorProvider>
    )
}

export default NewsArticleEditorScreen;