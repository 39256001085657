import React, {useContext} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import CredContext from "./providers/CredContext";
import ScrollToTop from "./components/siteTools/ScrollToTop";
import Header from "./appBase/Header";
import Footer from "./appBase/Footer";

//Screens
import StartScreen from "./screens/StartScreen";
import Events from "./screens/Events";
import News from "./screens/News";
import Projects from "./screens/Projects";
import SupportUs from "./screens/SupportUs";
import EventEditorScreen from "./screens/EventEditorScreen";
import NewsArticleEditorScreen from "./screens/NewsArticleEditorScreen";
import Home from "./screens/Home";
import HeaderBtn from "./appBase/header/HeaderBtn";
import FooterManager from "./screens/FooterManager";
import Policies from "./screens/Policies";
import MessageStrip from "./screens/MessageStrip";
import MyAccount from "./accountMangment/MyAccount";
import ChangeUserName from "./accountMangment/ChangeUserName";
import SignInContainer from "./accountMangment/SignInContainer";
import ForgottenPassword from "./accountMangment/ForgottenPassword";
import AccountManagerContainer from "./accountMangment/AccountManagerContainer";
import AccessDenied from "./screens/AccessDenied";
import HeaderTopLinkPage from "./screens/HeaderTopLinkPage";
import GeneralScreenEditor from "./screens/GeneralScreenEditor";
import PoliciesEditorScreen from "./screens/PoliciesEditorScreen";
import ContactUs from "./screens/ContactUs";
import Surveys from "./screens/Surveys";
import SurveyEditorScreen from "./screens/SurveyEditorScreen";
import EVENT_DATES_DB_SEEDER from "./TEMP_DATE_CORRECTION/EVENT_DATES_DB_SEEDER";


function App() {
  const cred = useContext(CredContext)

  return (
    <>
      <ScrollToTop />
      <Header cred={cred} />

      <main className={"container"}>
        {!cred ?
         <Switch>
           <Route exact path="/login" component={SignInContainer} />
           <Route exact path="/reset-password" component={ForgottenPassword} />
           <Route path="/">
             <Redirect to="/login" />
           </Route>
         </Switch>
               :
         <Switch>
           <Route path="/" component={StartScreen} exact />
           <Route path="/db-seeder" component={EVENT_DATES_DB_SEEDER} exact />
           <Route path="/sections" component={HeaderTopLinkPage} exact />
           <Route path="/pages" component={HeaderTopLinkPage} exact />
           <Route path="/components" component={HeaderTopLinkPage} exact />

           <Route exact path="/home"
                  component={(cred.admin || cred.access.home) ? Home : AccessDenied} />
           <Route exact path="/home/:section"
                  component={(cred.admin || cred.access.home) ? Home : AccessDenied} />

           <Route exact path="/events"
                  component={(cred.admin || cred.access.events) ? Events : AccessDenied} />
           <Route exact path="/events/editor/:id"
                  component={(cred.admin || cred.access.events) ? EventEditorScreen : AccessDenied} />

           <Route exact path="/news"
                  component={(cred.admin || cred.access.news) ? News : AccessDenied} />
           <Route exact path="/news/editor/:id"
                  component={(cred.admin || cred.access.news) ? NewsArticleEditorScreen : AccessDenied} />

           <Route exact path="/surveys"
                  component={(cred.admin) ? Surveys : AccessDenied} />
           <Route exact path="/surveys/editor/:id"
                  component={(cred.admin) ? SurveyEditorScreen : AccessDenied} />

           <Route exact path="/policies"
                  component={(cred.admin || cred.access.legal) ? Policies : AccessDenied} />
           <Route exact path="/policies/editor/:id"
                  component={(cred.admin || cred.access.legal) ? PoliciesEditorScreen : AccessDenied} />

           <Route path="/projects" component={Projects} exact />

           <Route exact path="/about-us"
                  component={(cred.admin || cred.access.about) ? GeneralScreenEditor : AccessDenied} />
           <Route exact path="/support-us"
                  component={(cred.admin || cred.access.support) ? SupportUs : AccessDenied} />
           <Route exact path="/contact-us"
                  component={(cred.admin || cred.access.support) ? ContactUs : AccessDenied} />
           <Route exact path="/footer"
                  component={(cred.admin || cred.access.headerFooter) ? FooterManager : AccessDenied} />
           <Route exact path="/footer/:section"
                  component={(cred.admin || cred.access.headerFooter) ? FooterManager : AccessDenied} />
           <Route exact path="/header-button"
                  component={(cred.admin || cred.access.headerFooter) ? HeaderBtn : AccessDenied} />
           <Route exact path="/terms-of-use"
                  component={(cred.admin || cred.access.legal) ? GeneralScreenEditor : AccessDenied} />
           <Route exact path="/privacy-notice"
                  component={(cred.admin || cred.access.legal) ? GeneralScreenEditor : AccessDenied} />
           <Route exact path="/cookies"
                  component={(cred.admin || cred.access.legal) ? GeneralScreenEditor : AccessDenied} />

           <Route exact path="/message-strip"
                  component={(cred.admin || cred.access.message) ? MessageStrip : AccessDenied} />
           <Route exact path="/message-strip/:section"
                  component={(cred.admin || cred.access.message) ? MessageStrip : AccessDenied} />

           {/*My Account*/}
           <Route path="/my-account" component={MyAccount} exact />
           <Route exact path="/my-account/change-display-name-:user"
                  component={ChangeUserName} />
           <Route exact path="/my-account/change-password" component={SignInContainer} />
           {/*Account Managing (Principal & Admins Only*/}
           <Route exact path="/accounts/account-manager"
             // component={(cred.admin || cred.admin) ? AccountManager : NotFound}/>
                  component={AccountManagerContainer} />
           <Route exact path="/accounts/create-account"
             // component={(cred.admin || cred.admin) ? SignInContainer : NotFound}/>
                  component={AccountManagerContainer} />
           <Route path="/login">
             <Redirect to="/" />
           </Route>
         </Switch>}

      </main>

      <Footer />
    </>
  );
}

export default App;