import React from "react";
import {Col, Row} from "react-bootstrap";
import {checkIfObjInArr, newsDateFormat} from "../../../helper";

function ResultList({searchResults, handleChangeList, addedResults}) {

  if (!searchResults) return null
  return (
    <>
      <h6 className={"mb-2 line-height-tight"}>Search Results</h6>
      <div style={{height: "35rem", overflow: "hidden auto"}}>
        {searchResults.map(x => {
          return <Row className={"scPast search-card background-colour mx-0 py-2 align-items-center"}
                      style={{
                        border: "3px solid",
                        borderColor: checkIfObjInArr(addedResults, "id", x.id) ? "#28a745" : "#f7f7f7"
                      }}
          >
            <Col xs={8}>
              <h6>{x.title}</h6>


              {x.object === "event" &&
              <p className={"text-smaller line-height-tight m-0"}>Event dates: {x.eventDates.map((d, i) => {
                return <span key={d}>{i > 0 && ", "}{newsDateFormat(d)}</span>
              })}
              </p>}

              {x.object === "news" &&
              <p className={"text-smaller line-height-tight m-0"}>Published: {newsDateFormat(x.datePublished)}</p>}


            </Col>
            <Col className={"text-right"}>
              <button className={"scbtn--small scbtn--1"} onClick={() => handleChangeList(x,)}>
                {checkIfObjInArr(addedResults, "id", x.id) ? "Remove" : "Add"}
              </button>
            </Col>
          </Row>
        })}
      </div>
    </>
  );
}

export default ResultList;