import React, {useState} from "react";
import {setData} from "../../dataManagement/database/dbFunctions";
import {Collapse, Spinner} from "react-bootstrap";
import ToastNotification from "../blocks/ToastNotification";

//Update content
function UpdateMessages({changeListState: [changeList, setChangeList], data}) {
    const [open, setOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [updateStatus, setUpdateStatus] = useState({pending: false, err: false});

    let docIds = ["01Home", "02WhatsOn", "03Past", "04Projects", "05News", "06About", "07Support", "08Contact", "09Policies", "messageLibrary"]

    const handleUpdate = async () => {
        setUpdateStatus({pending: true, err: false});
        for (let i = 0; i < docIds.length; i++) {
            if (changeList.includes(i)) {
                await setData("messageStrip", docIds[i], data[i])
                    .catch(() => {
                        setUpdateStatus({pending: false, err: true})
                        setShowToast(true)
                        setOpen(false)
                    })
            }
            if (updateStatus.err) {
                break
            }
        }
        setUpdateStatus({pending: false, err: false})
        setShowToast(true)
        setOpen(false)
        setChangeList([])
    }


    return (
        <div key={changeList.length} className={"py-5 "}>
            <div className={"mb-5 d-inline-block"}>
                <button onClick={() => setOpen(!open)}
                        aria-controls={"example-collapse-text"}
                        aria-expanded={open}
                        className={"px-4 py-2 scbtn--2 scbtn--med"}>
                    Save Changess
                </button>
                <Collapse in={open}>
                    <div className={"py-2"}>
                        <p className={"mb-0"}>Changes will be published to the main website.</p>
                        <hr className={"mt-0"}/>
                        <button className={"scbtn--med btn background__success p-2 mt-2"} style={{borderRadius: "0"}}
                                onClick={() => {
                                    handleUpdate()
                                }} disabled={updateStatus.pending}>
                            {updateStatus.pending ? <Spinner animation={"border"}/> : "Confirm Save"}
                        </button>
                    </div>
                </Collapse>
            </div>
            <ToastNotification header={updateStatus.err ? "Oh no!" : "Success!"}
                               body={updateStatus.err ? "Something went wrong while trying to save your changes. Please try again."
                                   : "You changes have been saved."}
                               className={updateStatus.err ? "background__fail" : "background__success"}
                               showState={[showToast, setShowToast]}/>
        </div>
    );
}

export default UpdateMessages
