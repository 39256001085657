import React, {useContext} from 'react';
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

function HeaderLink({linkTo, linkTitle}) {
    return (
        <LinkContainer style={{zIndex: "9"}} to={linkTo}>
            <Nav.Link className={"navbar__link"}>{linkTitle}</Nav.Link>
        </LinkContainer>
    );
}

export default HeaderLink;