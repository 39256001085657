import {eventsFetch, newsFetch, policiesFetch} from "./endlessScrollActions";

// REDUCER
export const endlessScrollReducer = async (action) => {
    switch (action.type) {
        //Events
        case "events":
            return await eventsFetch(action.startAfter)
        //News
        case "news":
            return await newsFetch(action.startAfter)
        //News
        case "policies":
            return await policiesFetch(action.startAfter)
        default:
            return []
    }
}