import React from 'react';
import PoliciesEditorProvider from "../providers/PoliciesEditorContext";
import PoliciesEditorContainer from "../components/policies/PoliciesEditorContainer";

function NewsArticleEditorScreen() {
    return (
        <PoliciesEditorProvider>
            <PoliciesEditorContainer />
        </PoliciesEditorProvider>
    )
}

export default NewsArticleEditorScreen;