import {postData, setData} from "../../dataManagement/database/dbFunctions";


// Posts new banner to db.
export function saveBannerToDb(banner, setPostStatus, setNewBanner, setOriginalBanner) {
    setPostStatus({pending: true, saved: false, error: false})
    if (banner.bannerId === "none") {
        postData("banner", banner)
            .then(id => {
                setPostStatus({pending: false, saved: true, error: false})
                handleBannerChange("docId", id[1], setNewBanner)
                setOriginalBanner(prevState => {
                    return {...prevState, originalBanner: banner}
                })

            })
            .catch(err => {
                setPostStatus({pending: false, saved: false, error: true})
            })
    } else {
        setData("banner", banner.bannerId, banner)
            .then(id => {
                setPostStatus({pending: false, saved: true, error: false})
                setOriginalBanner(prevState => {
                    return {...prevState, originalBanner: banner}
                })
            })
            .catch(err => {
                setPostStatus({pending: false, saved: false, error: true})
            })
    }

}


export function handleBannerChange(contentKey, contentValue, setBanner) {
    setBanner((prevValue) => {
        return {
            ...prevValue,
            [contentKey]: contentValue
        }
    })
}

export function handleBannerInputChange(e, setBanner) {
    setBanner((prevValue) => {
        return {
            ...prevValue,
            [e.target.id]: e.target.value
        }
    })
}
