import React from 'react';

function AccessDenied() {
    return (
        <div className={"d-flex justify-content-center align-items-center"} style={{minHeight: "30vh"}}>
            <div>
                <h3>Sorry... Access Not Granted</h3>
                <p className={"mb-0"}>You have not been provided access to this area of the site.</p>
                <p className={"mb-0 line-height-tight text-small"} style={{opacity: ".8"}}>Please contact a Site Admin
                    or the Site Manager.</p>
            </div>
        </div>
    );
}

export default AccessDenied;