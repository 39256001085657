import React from 'react';
import PageTop from "../components/blocks/PageTop";
import {useLocation} from "react-router-dom"
import AccountManager from "./AccountManager";
import SignInContainer from "./SignInContainer";

function AccountManagerContainer() {
    const pathname = useLocation().pathname
    return (
        <>
            <PageTop title={`Account Manager`} section={"accountManagement"}/>

            {
                pathname.includes("account-manager") ? <AccountManager/> :
                    pathname.includes("create-account") ? <SignInContainer/> :
                        null
            }

        </>
    );
}

export default AccountManagerContainer;