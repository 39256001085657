import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import TextEditor from "../textEditor/TextEditor";
import {decode} from "html-entities";
import HtmlReader from "../blocks/HtmlReader";


function MessageCreator({data, handleChange}) {
    const [tempArray, setTempArray] = useState(data)
    const hasRendered = useRef(false)

    useEffect(() => {
        hasRendered.current && handleChange(tempArray)
    }, [tempArray])

    useEffect(() => {
        hasRendered.current = true
    }, [])

    return <>
        {tempArray[0] && tempArray.map((msg, i) => {
            return <div key={i} className={"mb-4"}>
                <div className={"pb-3"}>
                    <div className={"d-block"}>
                        <ArrayForm msg={msg} state={[tempArray, setTempArray]} index={i} />
                    </div>
                    <div className={"d-block"} style={{height: "3.8rem"}}>
                        <ArrayBtns last={i + 1 !== tempArray.length} first={i !== 0} index={i}
                                   state={[tempArray, setTempArray]} />
                    </div>
                </div>
            </div>
        })}
        <div className={"mb-3 text-right"}>
            <button type={"button"} className={"scbtn--med scbtn--1"} onClick={() => {
                setTempArray(prevState => {
                    return [...prevState, {message: "", name: "", rename: true}]
                })
            }}>
                New Message
            </button>
        </div>
    </>
}

export default MessageCreator;


///////////////////// Form ///////////////////////

function ArrayForm({state: [array, setArray], msg, index}) {
    const [showEditor, setShowEditor] = useState(msg.message === "")


    const handleChange = (v, n) => {
        let tempArr = array
        tempArr[index][n] = v
        setArray([...tempArr])
    }

    return <>
        {msg.rename ?
            <input type={"text"} placeholder={"Name"} className={"general-input mb-2"}
                   value={msg.name} onChange={(e) => handleChange(e.target.value, "name")} />
            : <p className={"mb-0 font-weight-bold"}>{msg.name}</p>}

        <div className={""}>
            <TextEditor handleChange={handleChange} value={msg.message} keyToUpdate={"message"} imageDirectory={"messageStrip"} />
            {/*<button className={"scbtn--small scbtn--2"} onClick={() => {*/}
            {/*    setShowEditor(false)*/}
            {/*    handleChange(false, "rename")*/}
            {/*}}>Done*/}
            {/*</button>*/}
        </div>

    </>
}

///////////////////// Buttons ///////////////////////

function ArrayBtns({index, state: [array, setArray]}) {
    const [helpText, setHelpText] = useState(" ")

    const handleDelete = () => {
        let tempArr = array
        tempArr.splice(index, 1);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"position-absolute"} style={{top: ".3rem", right: "4.5rem"}}>
                <p className={"text-right m-0 p-0 text-small"}>{helpText}</p>
            </div>
            <div className={"position-absolute"} style={{bottom: "0", right: "0"}}>
                <Btn value={<FontAwesomeIcon icon={faTrashAlt} />} handleChange={handleDelete}
                     setHelp={setHelpText} helpText={"Delete This Message"} />

            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, setHelp, helpText, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "2rem", lineHeight: ".6"}}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onMouseEnter={() => setHelp(helpText)} onMouseLeave={() => setHelp("")}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {value}
        </button>
    </>
}