import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useFetchData from "../customHooks/useFetchData";
import {createNewSurvey} from "../factoryFunctions/surveyFactory";

export const SurveyEditorContext = React.createContext({})

const SurveyEditorProvider = ({children}) => {
  const [survey, setSurvey] = useState({})
  const {id} = useParams()
  const {data, err, pending, complete} = useFetchData("surveys", id, id === "new")

  // Fetch Data
  useEffect(() => {
    const surveyData = id === "new" ? createNewSurvey() : data ? data : {}
    setSurvey(surveyData)
    return () => setSurvey({})
  }, [data, id])

  //Change Handler
  const handleChange = (data, key) => {
    survey[key] = data
    setSurvey({...survey})
  }

  return (
    <SurveyEditorContext.Provider
      value={{survey, handleChange, surveyId: id, fetchErr: err, fetchPending: pending, fetchComplete: complete}}
    >
      {children}
    </SurveyEditorContext.Provider>
  );
}

export default SurveyEditorProvider;