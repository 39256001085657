import React, {useContext} from "react";
import {Collapse, Form, Row} from "react-bootstrap";
import {EventEditorContext} from "../../../providers/EventEditorContext";

function EventNotesSurveyBtnOrder({onNoteChanges, positions}) {
  const {event} = useContext(EventEditorContext)

  // Survey Position Checkbox Handler
  const handleSurveyPositionChange = (e) => {
    const {checked, id} = e.target
    let newArr = checked ? [...event.programmeNotes.surveyBtnPositions, id] : [...event.programmeNotes.surveyBtnPositions.filter(v => v !== id)]
    onNoteChanges(newArr, "surveyBtnPositions")
  }

  const cardParams = (get, position) => {
    if (get === "position") return positions.includes(`pos-${position}_survey`) || positions.includes(`pos-${position}_donate`)
    if (get === "label") return positions.includes(`pos-${position}_survey`) && positions.includes(`pos-${position}_donate`) ? "Survey & Donate Buttons" :
                                positions.includes(`pos-${position}_survey`) ? "Survey Button" : "Donate Button";
  }

  if (!event.programmeNotes) return null
  return (
    <div className={"mt-4"}>
      {/* Map rows */}
      {["Survey", "Donate"].map(x => {
        return <span key={x}>
          <label className={"general-input__label"}>{x} Button</label>
          <Row className={"d-flex flex-wrap"}>
            {/* Map checkboxes */}
            {["Titles", "Repertoire Info", "Repertoire Notes", "Performer Notes"].map((check, i) => {
              return <BtnCheckbox key={check} event={event} handlePosChange={handleSurveyPositionChange}
                                  label={`Below ${check}`} id={`pos-${i}_${x.toLowerCase()}`} />
            })}
          </Row>
        </span>
      })}

      <div className={"border-thin border-grey p-2 mt-2"}>
        <PositionCard label={"Titles"} />
        <PositionCard label={cardParams("label", "0")} isSurveyBtn show={cardParams("position", "0")} />
        <PositionCard label={"Repertoire Info"} />
        <PositionCard label={cardParams("label", "1")} isSurveyBtn show={cardParams("position", "1")} />
        <PositionCard label={"Repertoire Notes"} />
        <PositionCard label={cardParams("label", "2")} isSurveyBtn show={cardParams("position", "2")} />
        <PositionCard label={"Performer Notes"} />
        <PositionCard label={cardParams("label", "3")} isSurveyBtn show={cardParams("position", "3")} />
      </div>
    </div>
  );
}

export default EventNotesSurveyBtnOrder;


// Position Card Component
const PositionCard = ({label, isSurveyBtn = false, show = true}) => {
  return <Collapse in={show}>
    <div>
      <div className={"px-2 background-light-grey"}>
        <p className={isSurveyBtn ? "prime-colour" : ""}>{label}</p>
      </div>
    </div>
  </Collapse>
}

// Checkbox Button  Component
const BtnCheckbox = ({event, id, label, handlePosChange}) => {
  return <Form.Group className={"mb-3 col-lg-3 col-sm-6"}>
    <Form.Check checked={event.programmeNotes.surveyBtnPositions.includes(id)} id={id}
                onChange={handlePosChange} type="checkbox" label={label} />
  </Form.Group>
}