import React from "react";

import {Col, Row} from "react-bootstrap";
import {eventDateFormat} from "../../helper";
import HtmlReader from "../blocks/HtmlReader";
import ScreenContainer from "../blocks/ScreenContainer";
import PageTitle from "../elements/PageTitle";

function EventPreview({event}) {

  console.log(event)

  return <ScreenContainer>
    {event && event.dateCreated && <div className={"event"}>
      <PageTitle title={event.title} pbSmall />
      <h2 className={"event__subtitle"}>{event.subtitle}</h2>

      <div className={"event__img-wrap"}>
        <img className={"event__img mb-4 mb-lg-5 pb-2 pb-lg-3"} src={event.img} alt={event.alt} />
      </div>


      <Row>
        <Col md={6} className={"mt-0"}>
          {/*Date & time*/}
          {event.eventDates && event.eventDates.map((date, i) => {
            return <p key={i} className={"event__date"}>{eventDateFormat(date)}</p>
          })}
          {/*Venue*/}
          <p className={"event__venue"}>{event.venue}</p>
          {/*Programme*/}
          {event.programme !== "" &&
          <HtmlReader htmlContent={event.programme} classNames={"event__info"} editorId={"event-programme"} />}
          {/*Performers*/}
          {event.performers !== "" &&
          <HtmlReader htmlContent={event.performers} classNames={"event__info"} editorId={"event-performers"} />}

          {/* MOBILE TICKET SECTION*/}
          <div className={"d-md-none mb-5 mt-4"}>
            {/*Ticket Info*/}
            {event.ticket !== "" &&
            <>
              <HtmlReader htmlContent={event.ticket} classNames={"event__info"}
                          editorId={"event-tickets-mobile"} />
            </>}
            {/*Book Tickets Button*/}
            {event.ticketURL !== "--noUrl--" &&
            <div className={"mt-0 mt-md-2"}>
              <a href={event.ticketURL} target={"_blank"} rel={"noreferrer"}>
                <button type={"button"} className={"scbtn--2 scbtn--large"}>{event.ticketBtnTxt}</button>
              </a>
            </div>}
          </div>


          {/*Main Body - Large Screens*/}
          <div className={"d-md-none event__info"}>
            <h4>Information</h4>
            <HtmlReader htmlContent={event.body} editorId={"event-body-mobile"} />

            {/*<h6 className={"mt-4 prime-colour"}>Go to programme notes</h6>*/}

          </div>

          {/* DESKTOP TICKET SECTION*/}
          <div className={"d-none d-md-block"}>
            {/*Ticket Info*/}
            {event.ticket !== "" &&
            <>
              <HtmlReader htmlContent={event.ticket} classNames={"event__info mt-5"}
                          editorId={"event-tickets-desktop"} />
            </>}
            {/*Book Tickets Button*/}
            <div className={"mt-2"}>
              <a target={"_blank"} rel={"noreferrer"}>
                <button type={"button"} className={"scbtn--2 scbtn--large"}>{event.ticketBtnTxt}</button>
              </a>
            </div>
          </div>

        </Col>
        {/*Main Body - Large Screens*/}
        <Col md={6} className={"col-md-6 d-none d-md-block"}>
          <HtmlReader htmlContent={event.body} editorId={"event-body-desktop"} />


          {/*<h6 className={"mt-4 prime-colour"}>Go to programme notes</h6>*/}

        </Col>
      </Row>

    </div>}

  </ScreenContainer>
}

export default EventPreview;