import {createStore, combineReducers, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import {
    bannerReducer, contentReducer, messageReducer, newsReducer,
    authReducer, eventsReducer, generalReducer, footerReducer, policiesReducer
} from "./reducers/contentReducers";

const reducer = combineReducers({
    contentData: contentReducer,
    footerData: footerReducer,
    bannerData: bannerReducer,
    messageData: messageReducer,
    newsData: newsReducer,
    eventsData: eventsReducer,
    generalData: generalReducer,
    authData: authReducer,
    policiesData: policiesReducer,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store