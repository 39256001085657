import {db} from "../firebaseConfig";
import {ENDLESS_SCROLL_CONFIG, DOC_FETCH_QUANTITY} from "./endlessScrollConstants";

const endlessScrollConfig = ENDLESS_SCROLL_CONFIG


//FETCH ACTIONS:

// Events
export const eventsFetch = (startAfter) => {
    if (startAfter) {
        return db.collection("events")
            .orderBy(endlessScrollConfig.events.orderBy, "desc")
            .startAfter(startAfter)
            .limit(DOC_FETCH_QUANTITY)
            .get()
            .then((snap) => snap)
    } else {
        return db.collection("events")
            .orderBy(endlessScrollConfig.events.orderBy, "desc")
            .limit(DOC_FETCH_QUANTITY)
            .get()
            .then((snap) => snap)
    }
}

//News
export const newsFetch = (startAfter) => {
    if (startAfter) {
        return db.collection("news")
            .orderBy(endlessScrollConfig.news.orderBy, "desc")
            .startAfter(startAfter)
            .limit(DOC_FETCH_QUANTITY)
            .get()
            .then((snap) => snap)
    } else {
        return db.collection("news")
            .orderBy(endlessScrollConfig.news.orderBy, "desc")
            .limit(DOC_FETCH_QUANTITY)
            .get()
            .then((snap) => snap)
    }
}

//Policies
export const policiesFetch = (startAfter) => {
    if (startAfter) {
        return db.collection("policies")
            .orderBy(endlessScrollConfig.news.orderBy, "desc")
            .startAfter(startAfter)
            .limit(DOC_FETCH_QUANTITY)
            .get()
            .then((snap) => snap)
    } else {
        return db.collection("policies")
            .orderBy(endlessScrollConfig.news.orderBy, "desc")
            .limit(DOC_FETCH_QUANTITY)
            .get()
            .then((snap) => snap)
    }
}