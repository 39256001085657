import React from "react";
import PageTitle from "../../elements/PageTitle";
import HtmlReader from "../../blocks/HtmlReader";
import EventSurveyAndDonorBtns from "./Event_Preview_SurveyAndDonorBtns";

function EventNotesContainer({event}) {

  console.log("EVENT: ", event)

  if (!event?.programmeNotes) return null
  return (
    <div className={"mb-5"} id={"print-content"}>


      <PageTitle title={event.title} pbSmall />
      <h2 className={"event__subtitle"}>{event.subtitle}</h2>

      <h1 className={"font-colour fw-normal"}>Programme Notes</h1>

      <EventSurveyAndDonorBtns event={event} position={"0"} />

      {event.programme !== "" &&
      <HtmlReader htmlContent={event.programme} classNames={"event__info"} editorId={"notes-programme"} />}

      <EventSurveyAndDonorBtns event={event} position={"1"} />

      <br />
      {event.programmeNotes.repertoireNotes !== "" &&
      <HtmlReader htmlContent={event.programmeNotes.repertoireNotes} classNames={"event__info"}
                  editorId={"notes-repertoireNotes"} />}

      <EventSurveyAndDonorBtns event={event} position={"2"} />

      <br />
      {event.programmeNotes.performersNotes !== "" &&
      <HtmlReader htmlContent={event.programmeNotes.performersNotes} classNames={"event__info"}
                  editorId={"notes-performersNotes"} />}

      <EventSurveyAndDonorBtns event={event} position={"3"} />

    </div>
  );
}

export default EventNotesContainer;