import React, {useEffect, useState} from 'react';
import {clListener} from "./commentsAndLikesHelper";
import Comments from "./Comments";
import Likes from "./Likes";

function CommentsAndLikesContainer() {
    const [comments, setComments] = useState(null)
    const [likes, setLikes] = useState(null)

    console.log(comments)

    useEffect(() => {
        clListener(setComments, setLikes)
    }, [])


    return (
        <>
            <h5>Top Liked Posts</h5>
            <Likes likes={likes}/>
            <h5>Latest Comments</h5>
            <Comments comments={comments}/>
        </>
    );
}

export default CommentsAndLikesContainer;