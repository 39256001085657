import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom"
import PageTitle from "../components/elements/PageTitle";

function HeaderTopLinkPage() {
    const [title, setTitle] = useState("")
    const path = useLocation().pathname

    useEffect(() => {
        setTitle(path.includes("sections") ? "Sections" :
            path.includes("pages") ? "Pages" :
                path.includes("components") ? "Components" :
                    null)
    }, [path])
    return (
        <>
            <PageTitle title={title}/>
            <div className={"d-flex justify-content-center align-items-center"} style={{minHeight: "30vh"}}>
                <div>
                    <h4 style={{opacity: ".6"}}>Please select a link from the {title} dropdown.</h4>
                </div>
            </div>
        </>
    );
}

export default HeaderTopLinkPage;