import firebase from "firebase";
import {auth} from "../dataManagement/firebaseConfig";
import {getDocById} from "../dataManagement/database/dbFunctions";

const firebaseConfig = {
    apiKey: "AIzaSyB1Zoj1FGkBmetVBOBmJW9ie8UNbdFPSBE",
    authDomain: "skipton-camerata-cf704.firebaseapp.com",
    appId: "1:568196353287:web:730f08e65c3e13903b09a2",
};

// Listen for auth state change
export const authCheck = () => {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                getDocById("users", user.uid).then(data => {
                    resolve({...user, ...data}) // return email address when logged in and email passed in is true.
                })
            } else {
                reject(null)
            }
        })
    })
}

//Change password
export const changePassword = (oldPw, newPw) => {
    return new Promise((resolve, reject) => {
        checkPassword(oldPw)
            .then(user => {
                user.updatePassword(newPw)
                    .then(() => {
                        resolve("Your password has been successfully updated.")
                    })
                    .catch(err => {
                        reject("Something went wrong. Please try again.")
                    });
            })
            .catch((err) => { // current password is incorrect
                reject("Your old password is incorrect.")
            })
    })
}

//Check given password against current password
const checkPassword = (providedPw) => {
    return new Promise((resolve, reject) => {
        let user = auth.currentUser;
        let credential = firebase.auth.EmailAuthProvider.credential(
            firebase.auth().currentUser.email,
            providedPw
        );
        user.reauthenticateWithCredential(credential)
            .then((cred) => {
                resolve(user)
            }).catch(err => {
            reject(err)
        });
    })
}

// Update own display name
export const updateDisplayName = (name) => {
    let user = auth.currentUser;
    user.updateProfile({
        displayName: name,
    }).then(() => {
        console.log("Display Name Updated")
    }).catch(() => {
        console.log("ERROR: Display name not updated")
    });
}


// Create New User (with secondary app)
const setNewUserOnDb = firebase.functions().httpsCallable("setNewUserOnDb")
const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");
export const createUser = (em, pwd, name) => {
    return new Promise((resolve, reject) => {
        secondaryApp.auth().createUserWithEmailAndPassword(em, pwd)// Creating User
            .then(() => { // Setting Display Name
                let user = secondaryApp.auth().currentUser;
                user.updateProfile({
                    displayName: name,
                }).then(() => { // Adding user privileges to DB
                    setNewUserOnDb({displayName: name, email: em, uid: user.uid})
                        .then(() => resolve(name))
                        .catch(() => reject())
                    secondaryApp.auth().signOut();
                }).catch(() => reject());
            })
            .catch(err => reject(err))
    })
}

// Delete Account (own account)
export const deleteUser = (email, pw) => {
    let user = auth.currentUser;
    return new Promise((resolve, reject) => {
        let cred = firebase.auth.EmailAuthProvider.credential(email, pw)
        user.reauthenticateWithCredential(cred)
            .then(() => {
                user.delete()
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    });
            })
            .catch(err => {
                reject(err)
            })
    })
}

// reset password by email
export const resetPasswordEmail = (email) => {
    firebase.auth().languageCode = 'en';
    return new Promise((resolve, reject) => {
        auth.sendPasswordResetEmail(email).then(() => {
            resolve()
        }).catch(err => {
            reject(err)
        });
    })
}






