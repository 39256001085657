import moment from "moment";
import {
  deleteData,
  deleteDocAndUpdateStat, setData,
} from "../../dataManagement/database/dbFunctions";

export const handleBtnFunction = async (action, index, state, setStatus, dbCollection) => {
  let docArr = [...state[0]]
  let docObj = {...state[0][index]}

  let statField;

  switch (action) {
    case "takeOnline":
    case "takeOffline":
      docObj = onlineToggle(docObj)
      break
    case "publish":
      docObj = publish(docObj)
      break
    case "onHome":
    case "offHome":
      docObj = homeToggle(docObj)
      break
    case "delete":
      docArr.splice(index, 1)
      break
    default:
      return null
  }


  if (action !== "delete") {
    docArr[index] = docObj
    await setData(dbCollection, docObj.id, docObj)
      .then(() => {
        state[1]([...docArr])
        setStatus({pending: "", err: false})
      })
      .catch(err => setStatus({pending: "", err: true}))
  } else if (action === "delete") {
    await deleteDocAndUpdateStat(dbCollection, state[0][index].id, state[0][index], "docCount", -1)
    await deleteData("likesComments", state[0][index].id)
      .then(() => {
        state[1]([...docArr])
        setStatus({pending: "", err: false})
      })
      .catch(err => setStatus({pending: "", err: true}))
  }


}


const publish = (obj) => {
  obj.online = true
  obj.datePublished = moment.utc().format()
  return obj
}

const onlineToggle = (obj) => {
  obj.online = !obj.online
  return obj
}

const homeToggle = (obj) => {
  obj.homePage = !obj.homePage
  return obj
}

// const deleteItem = (arr, index) => {}