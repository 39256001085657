import React, {useState} from 'react';
import PopupModal from "../blocks/PopupModal";
import TextEditor from "../textEditor/TextEditor";

export function ModelExitWithoutSaving({showExitConfirmState, setShowCreator}) {
    return (
        <PopupModal title={"You have unsaved changes"} buttonText={"none"} center={true}
                    showModalState={showExitConfirmState}>
            <p className={"mb-3"}>Are you sure you want to exit without saving?</p>
            <button onClick={() => {
                showExitConfirmState[1](false)
                setShowCreator(false)
            }} className={"scbtn--small scbtn--1 border-thin mr-2"}>Exit Without Saving
            </button>
            <button onClick={() => {
                showExitConfirmState[1](false)
            }} className={"scbtn--small scbtn--2 border-thin mb-3"}>Cancel
            </button>
        </PopupModal>
    );
}

export function ModelBannerContent({editorValue, handleContentChange, index}) {
    const [showModel, setShowModel] = useState(false);

    function handleEditorChange(value) {
        let e = {target: {value: value}}
        handleContentChange(e, index, "contentCustom")
    }

    return (
        <>
            <button className={"scbtn--small scbtn--1 mb-2 py-2 px-4"} onClick={() => setShowModel(true)}
                    onClickCapture={(e) => e.currentTarget.blur()}>
                Create custom content
            </button>
            <PopupModal title={`Banner content creator`} buttonText={"Done"} center={true}
                        showModalState={[showModel, setShowModel]}>

                <div className={"px-3"}>
                    <TextEditor value={editorValue} handleChange={handleEditorChange}
                                imageDirectory={`bannerContent${index}`}/>
                </div>


            </PopupModal>
        </>

    );
}