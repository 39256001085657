import React, {useEffect, useState} from "react";
import {LinkContainer} from "react-router-bootstrap";

function EditBtns({page, item, index, showState: [show, setShow], btnId}) {
  const [collapse, setCollapse] = useState(true)

  useEffect(() => {
    setCollapse(btnId !== show)
  }, [show])

  return <>
    {page !== "events" ?
     <LinkContainer to={`${page}/editor/${item[index].id}`}
                    style={{fontSize: "1.3rem", width: "8rem", height: "4rem", borderRadius: "0"}}>
       <button className={"eventEditBtn btn btn-secondary"} type={"button"}>Edit</button>
     </LinkContainer>
                       :
     <button className={"eventEditBtn btn btn-secondary"} type={"button"}
             onClick={() => setShow(show === btnId ? 0 : btnId)}
             style={{fontSize: "1.3rem", width: "8rem", height: "4rem", borderRadius: "0"}}>
       Edit
     </button>}


    <div className={"position-absolute text-right background-white"}
         style={{
           top: "4.4rem",
           transition: ".5s",
           maxHeight: !collapse ? "15rem" : "0",
           overflow: "hidden",
           zIndex: "999",
         }}>
      <div className={"border border-thin px-3 pb-4"}>

        <p className={"position-relative my-0 pt-3 px-3"}>What would you like to edit?</p>

        <LinkContainer to={`${page}/editor/${item[index].id}`}
                       style={{fontSize: "1.3rem", height: "3.5rem", borderRadius: "0"}}>
          <button className={"eventEditBtn btn btn-secondary scbtn--small"} type={"button"}>
            Event Page
          </button>
        </LinkContainer>

        <LinkContainer to={`${page}/editor/${item[index].id}#notes`}
                       style={{fontSize: "1.3rem", minWidth: "8rem", height: "3.5rem", borderRadius: "0"}}>
          <button className={"eventEditBtn btn btn-secondary ml-1 scbtn--small"} type={"button"}>
            Notes
          </button>
        </LinkContainer>

        <LinkContainer to={`${page}?see_also=${item[index].id}&title=${item[index].title}`}
                       style={{fontSize: "1.3rem", minWidth: "8rem", height: "3.5rem", borderRadius: "0"}}>
          <button className={"eventEditBtn btn btn-secondary ml-1 scbtn--small"} type={"button"}
                  onClick={() => setShow(0)}>
            See Also
          </button>
        </LinkContainer>


      </div>
    </div>
  </>;
}

export default EditBtns;