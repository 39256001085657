import React, {useState} from 'react';
import {Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

export function ButtonsSaveAndExit({
                                       pageNum,
                                       setShowCreator,
                                       setShowExitConfirm,
                                       saveBanner,
                                       postStatus,
                                       safeToExit,
                                   }) {

    return (
        <div className={"d-flex"}>

            {pageNum > 1 &&
            <button className={"btn btn-outline-success background__success success-disabled mr-1 mt-1"}
                    style={{borderRadius: "0", height: "3.2rem", width: "15rem"}}
                    disabled={safeToExit}
                    onClick={(e) => {
                        saveBanner()
                    }}
                    onClickCapture={(e) => e.currentTarget.blur()}>
                {postStatus.pending ?
                    <Spinner animation="border" size="sm"/> : safeToExit ? "Saved" : "Save banner"}
            </button>}

            <button className={"btn btn-danger mt-1 d-flex align-items-center"}
                    style={{height: "3.2rem", borderRadius: "0"}}
                    onClick={() => {
                        !safeToExit ? setShowExitConfirm(true) : setShowCreator(false)
                    }}>
                <FontAwesomeIcon icon={faArrowLeft}/>  &nbsp;
                Return to home banner page
            </button>

        </div>
    );
}


export function ButtonsNextAndPrev(
    {
        pageNumState, namesArray, newName, bannerOnDb, safeToExit, setOriginal
    }
) {
    const [errorMessage, setErrorMessage] = useState("")

    function handleNameCheck() {
        if (namesArray.includes(newName)) {
            setErrorMessage("A banner with this name already exists. Please choose a different name.")
        } else if (newName === "") {
            setErrorMessage("Please provide a banner name.")
        } else {
            setErrorMessage("")
            pageNumState[1](pageNumState[0] + 1)
        }
    }

    return (
        <div className={"container"}>
            <div className={"d-flex w-100 text-right mb-3"} style={{marginTop: "4rem", paddingBottom: "5rem"}}>
                <p className={"ml-auto mr-4 prime-colour"}> {errorMessage}</p>
                <div className={""}>
                    {pageNumState[0] > 1 && !bannerOnDb ?
                        <PreNxtBtn pageNumState={pageNumState} type={"prev"} handleNameCheck={handleNameCheck}/>
                        : pageNumState[0] > 2 && bannerOnDb ?
                            <PreNxtBtn pageNumState={pageNumState} type={"prev"} handleNameCheck={handleNameCheck}/>
                            : null}

                    {pageNumState[0] < 4 &&
                    <PreNxtBtn pageNumState={pageNumState} type={"next"} handleNameCheck={handleNameCheck}
                               safeToExit={safeToExit} setOriginal={setOriginal}/>}


                </div>
            </div>
        </div>
    );
}

//-------//

function PreNxtBtn(
    {
        pageNumState, type, handleNameCheck, safeToExit, setOriginal
    }
) {
    // let safe = useRef(safeToExit)
    // console.log("MATCHES: ", safeToExit)
    return (
        <button className={"scbtn--1 scbtn--small ml-2"}
                onClick={() => {
                    let safe = safeToExit
                    type === "prev" && pageNumState[1](pageNumState[0] - 1)
                    pageNumState[0] !== 1 && type === "next" && pageNumState[1](pageNumState[0] + 1)
                    pageNumState[0] === 1 && type === "next" && handleNameCheck()
                    safe ? setOriginal() : console.log(safe)
                }}
                onClickCapture={(e) => e.currentTarget.blur()}
        >
            {type === "next" ? "Next" : "Back"}
        </button>
    );
}

