import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";

function useSearchQuery() {
    const history = useHistory()
    const [queryAsObj, setQueryAsObj] = useState({});

    // SETS QUERY
    const setQuery = (queryObj = null) => {
        const searchParams = new URLSearchParams();
        Object.entries(queryObj).forEach(([key, val]) => {
            searchParams.append(key, val);
        })
        queryObj && history.push(`${history.location.pathname}?${searchParams}`)
    }

    // SETS STATE
    useEffect(() => {
        let searchParams = history.location.search.substring(1).split("&")
        let searchObj = {}
        searchParams.forEach(s => {
            const x = s.split("=")
            searchObj[x[0]] = x[1]
        })
        setQueryAsObj(searchObj)
    }, [history.location.search])

    return [queryAsObj, setQuery];
}

export default useSearchQuery;