import firesbase, {db} from "../firebaseConfig";


//// Get Doc(s) from db with query ////
export function getDocsByQuery(collection, queryObj, comparison = "==") {
  return new Promise(function (resolve, reject) {
    let key = Object.keys(queryObj)[0]
    db.collection(collection)
      .where(key, comparison, queryObj[key])
      .get()
      .then((snapshot) => {
        let dataArray = [];
        snapshot.docs.forEach(doc => {
          dataArray.push(doc)
        })
        resolve(dataArray)
      })
      .catch(err => {
        reject(Error("Error: ", err))
      })
  })
}


//get single doc from db - with doc ID
export const getDocById = (collection, docId) => new Promise((async (resolve, reject) => {
  const cityRef = db.collection(collection).doc(docId);
  const doc = await cityRef.get();
  if (!doc.exists) {
    reject("No such document!")
  } else {
    resolve(doc.data())
  }
}));


//// Get data from db ////
export function getAllDocs(collection) {
  return new Promise(function (resolve, reject) {
    db.collection(collection)
      .get()
      .then((snapshot) => {
        let dataArray = [];
        snapshot.docs.forEach(doc => {
          dataArray.push(doc)
        })
        resolve(dataArray)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
}

//// Get doc data from DB (using getDocsByQuery) ////
export function getAllData(collection) {
  return new Promise((resolve, reject) => {

    getAllDocs(collection)
      .then(docs => {
        let dataArray = []
        docs.forEach(doc => {
          dataArray.push(doc.data())
        })
        resolve(dataArray)
      })
      .catch(err => reject(err))

  })
}


//// Get doc data from DB (using getDocsByQuery) ////
export function getDataByQuery(collection, queryObj) {
  return new Promise((resolve) => {

    getDocsByQuery(collection, queryObj)
      .then(docs => {
        let dataArray = []
        docs.forEach(doc => {
          dataArray.push(doc.data())
        })
        resolve(dataArray)
      })

  })
}


//// Get doc data from DB (using getDocsByQuery) ////
export function searchWithIdArray(collection, idArr) {
  return new Promise((resolve, reject) => {
    db.collection(collection)
      .where("id", "in", idArr)
      .get()
      .then(docs => {
        let dataArray = []
        docs.forEach(doc => {
          dataArray.push(doc.data())
        })
        resolve(dataArray)
      })
      .catch(err => reject(err))
  })
}


//// Post data to db ////
export function postData(collection, content) {
  return new Promise((resolve, reject) => {
    db.collection(collection)
      .add(content)
      .then((snapshot) => {
        resolve([true, snapshot.id])
      })
      .catch((err) => {
        reject([false, err])
      })
  })
}


//// Update data to db ////
//dataObj = Object of data to update. Key = value of query key. Value = value of data to update
//collectionName = name of collection to update
//keyToUpdate = key in doc to update
export function updateData(keyToUpdate, valueToUpdate, docId, collectionName) {
  console.log("Trying to update: ", keyToUpdate, " : ", valueToUpdate, " : ", docId)
  return new Promise((resolve, reject) => {
    db.collection(collectionName)
      .doc(docId)
      .update(keyToUpdate, valueToUpdate)
      .then(() => {
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}


//// Set data to db ////
export function setData(collection, docId, dataObj) {
  return new Promise((resolve, reject) => {
    db.collection(collection)
      .doc(docId)
      .set(dataObj)
      .then(res => {
        resolve([true, res])
      })
      .catch(reason => {
        reject([false, reason])
      })
  })
}

// Remove and add object to search
export const updateTitleInCollectionSearch = (collection, docId, oldTitle, newTitle) => {
  return new Promise((resolve, reject) => {
    const removeSearch = firesbase.firestore.FieldValue.arrayRemove({id: docId, title: oldTitle})
    const addSearch = firesbase.firestore.FieldValue.arrayUnion({id: docId, title: newTitle})
    const searchRef = db.collection(collection).doc("--collection-search--")
    const batch = db.batch()

    batch.update(searchRef, {searchHelper: removeSearch})
    batch.update(searchRef, {searchHelper: addSearch})
    batch.commit()
      .then(res => resolve([true, res]))
      .catch(reason => {
        console.log("ERR: ", reason)
        reject([false, reason])
      })
  })
}


const COLLECTION_WITH_SEARCH_DOCS = ["news", "events", "projects", "policies"]


//// Set data to db and Increment count ////
export function setDocAndUpdateStat(collection, docId, dataObj, statField, IncrementBy) {
  return new Promise((resolve, reject) => {
    console.log("Trying to set ", collection, docId, dataObj)
    const increment = firesbase.firestore.FieldValue.increment(IncrementBy)
    const addSearch = firesbase.firestore.FieldValue.arrayUnion({id: docId, title: dataObj.title})
    const docRef = db.collection(collection).doc(docId)
    const statsRef = db.collection(collection).doc("--collection-stats--")
    const searchRef = db.collection(collection).doc("--collection-search--")
    const batch = db.batch()

    batch.set(docRef, dataObj)
    batch.set(statsRef, {[statField]: increment}, {merge: true})
    if (COLLECTION_WITH_SEARCH_DOCS.includes(collection)) {
      batch.update(searchRef, {searchHelper: addSearch})
    }
    batch.commit()
      .then(res => {
        resolve([true, res])
      })
      .catch(reason => {
        console.log("ERR: ", reason)
        reject([false, reason])
      })
  })
}

//// Delete data to db and update count ////
export function deleteDocAndUpdateStat(collection, docId, dataObj, statField, IncrementBy) {
  return new Promise((resolve, reject) => {
    console.log("Trying to delete ", collection, docId)
    const increment = firesbase.firestore.FieldValue.increment(IncrementBy)
    const removeSearch = firesbase.firestore.FieldValue.arrayRemove({id: docId, title: dataObj.title})
    const docRef = db.collection(collection).doc(docId)
    const statsRef = db.collection(collection).doc("--collection-stats--")
    const searchRef = db.collection(collection).doc("--collection-search--")
    const batch = db.batch()

    batch.delete(docRef)
    batch.set(statsRef, {[statField]: increment}, {merge: true})
    if (COLLECTION_WITH_SEARCH_DOCS.includes(collection)) {
      batch.update(searchRef, {searchHelper: removeSearch})
    }
    batch.commit()
      .then(res => {
        resolve([true, res])
      })
      .catch(reason => {
        console.log("ERR: ", reason)
        reject([false, reason])
      })
  })
}


//// Delete data to db ////
export function deleteData(collection, docId) {
  return new Promise((resolve, reject) => {
    db.collection(collection)
      .doc(docId)
      .delete()
      .then(() => resolve())
      .catch(err => reject(err))
  })
}