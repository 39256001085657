import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from "react-bootstrap";

function Page4Link({bannerState, linkNum}) {
    const [link, setLink] = useState(bannerState[0].links[`link${linkNum}`]);

    const handleChange = (key, value) => {
        setLink(prevState => {
            return {...prevState, [key]: value}
        })
    }

    useEffect(() => {
        let tempObj = bannerState[0].links
        tempObj[`link${linkNum}`] = link
        bannerState[1](prevState => {
            return {...prevState, links: tempObj}
        })
    }, [link])


    return <>
        {link &&
        <Form>

            <Row key={`inline-radios`} className="mb-3 pl-0 custom-radio custom-control">
                {/* "Apply Link To" Options*/}
                <Col>
                    <Form.Label className={"mb-1 text-small general-input__label"}>Apply link to...</Form.Label>
                    <Form.Control as="select" className={"general-input"} value={link.applyTo}
                                  onChange={(e) => handleChange("applyTo", e.target.value)}>
                        <option value={"nl"}>No Link</option>
                        <option value={"wb"}>Whole Banner</option>
                        <option value={"bl"}>Link Only</option>
                        <option value={"wbbl"}>Whole Banner & Link</option>
                    </Form.Control>
                </Col>

                {link.applyTo !== "nl" && link.applyTo !== "wb" ?
                    <>
                        {/*Link Type Options*/}
                        <Col>
                            <Form.Label className={"mb-1 text-small general-input__label"}>Link Type</Form.Label>
                            <Form.Control as="select" className={"general-input"} value={link.type}
                                          onChange={(e) => handleChange("type", e.target.value)}>
                                <option value={"o-link"}>Orange Link</option>
                                <option value={"w-link"}>White Link</option>
                                <option value={"btn-2"}>Solid Button</option>
                                <option value={"btn-1"}>Outlined Button</option>
                            </Form.Control>
                        </Col>

                        {/*Link Size Options*/}
                        <Col>
                            <Form.Label className={"mb-1 text-small general-input__label"}>Link Size</Form.Label>
                            <Form.Control as="select" className={"general-input"} value={link.size}
                                          onChange={(e) => handleChange("size", e.target.value)}>
                                <option value={"sm"}>Small</option>
                                <option value={"md"}>Normal</option>
                                <option value={"lg"}>Large</option>
                            </Form.Control>
                        </Col>
                    </>
                    :
                    <Col xs={8}>&nbsp;</Col>
                }
            </Row>

            <Row>
                {link.applyTo !== "nl" && link.applyTo !== "wb" &&
                < Col>
                    {/*Link Text Input*/}
                    <Form.Group className={""}>
                        <Form.Label className={"readers-only"}>Link Text</Form.Label>
                        <Form.Control type="text" placeholder="Link Text" className={"general-input"}
                                      onChange={(e) => handleChange("text", e.target.value)}
                                      value={link.text}
                        />
                    </Form.Group>
                </Col>}
                {link.applyTo !== "nl" &&
                <Col>
                    {/*Link href Input*/}
                    <Form.Group className={""}>
                        <Form.Label className={"readers-only"}>Link href</Form.Label>
                        <Form.Control type="text" placeholder="Link href" className={"general-input mb-1"}
                                      onChange={(e) => handleChange("link", e.target.value)}
                                      value={link.link}/>
                        <p className={"line-height-tight text-small"}>Exclude <strong>"https://skiptoncamerata.com"</strong> for
                            internal site links. <br/> Example: "/events/event-id-&-name"</p>
                    </Form.Group>
                </Col>}
            </Row>
        </Form>}
    </>
}

export default Page4Link;