import React, {useEffect, useState} from 'react';
import {FetchContent} from "../../dataManagement/FetchContent";
import ImageLibrary from "../../imageManager/ImageLibrary";
import ToggleButtons from "../elements/ToggleButtons";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";
import TextEditor from "../textEditor/TextEditor";
import {Col, Form, Row} from "react-bootstrap";
import HomeAboutUs from "./HomeAboutUs";

function HomeWelcome() {
    const [previewMode, setPreviewMode] = useState(false);
    const [toggle, setToggle] = useState(0)
    const [data, setData] = useState([])
    const [titleStyleOptions, setTitleStyleOptions] = useState(null);


    useEffect(() => {
        if (data && data[0] && data[0].homeAbout) {
            setToggle(data[0].homeAbout.online ? 1 : 0)
        }
    }, [data])


    const updater = (val, key) => {
        const tempObj = data[0].homeAbout
        tempObj[key] = val
        setData([{fetchId: "homeAbout", homeAbout: tempObj}])
    }

    const handleImg = (imgArray) => {
        updater(imgArray[0], "img")
    }

    const handleToggle = (val) => {
        updater(val === 1, "online")
    }

    const handleChange = (e) => {
        let {name, value} = e.target
        updater(value, name)
        if (name === "link" && value === "" && data[0].homeAbout.linkActive) updater(false, "linkActive")
        else if (name === "link" && value !== "" && !data[0].homeAbout.linkActive) updater(true, "linkActive")
    }

    const handleTitleClassChange = (e, key) => {
        const {value} = e.target
        setTitleStyleOptions(prevState => {
            return {...prevState, [key]: value}
        })
    }

    useEffect(() => {
        if (data[0]) updater(titleStyleOptions, "titleStyles")
    }, [titleStyleOptions])

    useEffect(() => {
        if (!titleStyleOptions && data[0] && data[0].homeAbout) setTitleStyleOptions(data[0].homeAbout.titleStyles)
    }, [data])


    return <>
        <FetchContent contentState={[data, setData]} table={"general"} conditions={"homeAbout"}>
            {data && data[0] && data[0].homeAbout && titleStyleOptions && <>
                {/* Mode Button (edit / preview*/}
                <div className={"position-sticky text-right"} style={{top: "4rem", right: "0", zIndex: "99"}}>
                    <button className={"scbtn--med scbtn--1"} onClick={() => {
                        setPreviewMode(!previewMode)
                    }}>
                        {!previewMode ? "Preview" : "Edit"} Mode
                    </button>
                </div>

                {previewMode ?
                    <HomeAboutUs about={data}/>
                    :
                    <>
                        {/* Online */}
                        < ToggleButtons onChangeFunc={handleToggle}
                                        buttonArray={[{name: "Online", value: 1}, {name: "Offline", value: 0}]}
                                        btnState={[toggle, setToggle]}/>

                        {/* Image */}
                        <div className={"mb-4 mt-5 d-flex"}>
                            <ImageLibrary handleImageChoice={handleImg} directory={"homeAbout"}/>
                            {data[0].homeAbout.img !== "" && data[0].homeAbout.img !== undefined &&
                            <img src={data[0].homeAbout.img} alt={data[0].homeAbout.title} className={"ml-4"}
                                 style={{width: "auto", height: "6.7rem"}}/>}
                        </div>

                        {/* Image Link*/}
                        <label className={"general-input__label"}>Image Link</label>
                        <input className={"general-input"} name={"link"} type={"text"} placeholder={"Leave blank to disable link"}
                               value={data[0].homeAbout.link} onChange={handleChange}/>

                        {/* Title */}
                        <label className={"general-input__label"}>Title</label>
                        <input className={"general-input"} name={"title"} type={"text"}
                               value={data[0].homeAbout.title} onChange={handleChange}/>

                        {/* Title Styling...*/}
                        <Row className={"mb-5"}>
                            {/* TEXT TYPE */}
                            <Col xs={3} className={"ml-0"}>
                                <label className={"general-input__label mb-0"}>Title Type</label>
                                <Form.Control as="select" className={"general-input"}
                                              value={titleStyleOptions.type}
                                              onChange={(e) => handleTitleClassChange(e, "type")}>
                                    {/*<option value={"o-h2-lg"}>Large Title - Orange</option>*/}
                                    {/*<option value={"w-h2-lg"}>Large Title - White</option>*/}
                                    <option value={"o-h2-md"}>Title - Orange</option>
                                    <option value={"w-h2-md"}>Title - White</option>
                                    <option value={"o-h3"}>Small Title - Orange</option>
                                    <option value={"w-h3"}>Small Title - White</option>
                                    <option value={"o-p"}>Paragraph - Orange</option>
                                    <option value={"w-p"}>Paragraph - White</option>
                                    <option value={"n-p"}>Paragraph - Normal</option>
                                </Form.Control>
                            </Col>
                            {/* BACKGROUND COLOUR */}
                            <Col xs={2} className={"mr-0"}>
                                <label className={"general-input__label mb-0"}>Title Background</label>
                                <Form.Control as="select" className={"general-input"}
                                              value={titleStyleOptions.bg}
                                              onChange={(e) => handleTitleClassChange(e, "bg")}>
                                    <option value={"n"}>None</option>
                                    <option value={"o"}>Orange</option>
                                    <option value={"w"}>White</option>
                                    <option value={"d"}>Dark</option>
                                </Form.Control>
                            </Col>
                            {/* TEXT WEIGHT */}
                            <Col xs={2} className={"mr-0"}>
                                <label className={"general-input__label mb-0"}>Title Weight</label>
                                <Form.Control as="select" className={"general-input"}
                                              value={titleStyleOptions.weight}
                                              onChange={(e) => handleTitleClassChange(e, "weight")}>
                                    <option value={"900"}>Bold</option>
                                    <option value={"300"}>Light</option>
                                </Form.Control>
                            </Col>
                        </Row>

                        {/* Body */}
                        <TextEditor value={data[0].homeAbout.text} keyToUpdate={"text"} handleChange={updater}
                                    imageDirectory={"homeAbout"}/>
                    </>}
            </>}
        </FetchContent>
        {data && data[0] && !previewMode &&
        <UpdateDocumentField collection={"general"} docId={"homeAbout"} updateKey={"homeAbout"}
                             updateValue={data[0].homeAbout}/>}
    </>
}

export default HomeWelcome;