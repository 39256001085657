import React, {useEffect, useState} from 'react';
import {FetchContent} from "../../dataManagement/FetchContent";
import {Col, Row} from "react-bootstrap";
import ImageLibrary from "../../imageManager/ImageLibrary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import UpdateDocumentField from "../../dataManagement/UpdateDocumentField";


function Funders() {
    const [funders, setFunders] = useState([])

    const handleChange = (newArr) => {
        let tempObj = funders[0]
        tempObj.funders = newArr
        setFunders([tempObj])
    }

    return <>
        <FetchContent contentState={[funders, setFunders]} table={"footer"} conditions={"funders"}>
            {funders[0] && funders[0].funders &&
            <ArrayRepeater array={funders[0].funders} setFunction={handleChange}/>}
        </FetchContent>
        {funders[0] && funders[0].funders &&
        <UpdateDocumentField collection={"footer"} docId={"funders"} updateKey={"funders"}
                             updateValue={funders[0].funders}/>}
    </>
}

export default Funders;

/////////////////// Repeater ///////////////////////////

//  1. {array}
//  ! Array to be repeated (mapped)

//  2. {setFunction}
//  ! function that takes updated array as input


function ArrayRepeater({array, setFunction}) {
    const [tempArray, setTempArray] = useState(array)

    useEffect(() => {
        setFunction(tempArray)
    }, [tempArray])

    return <>
        <hr className={"mb-4"}/>
        {tempArray[0] && tempArray.map((logo, i) => {
            return <div key={i} className={"mb-4"}>
                <Row>
                    <Col md={9}>
                        <ArrayForm state={[tempArray, setTempArray]} name={logo.name} link={logo.link}
                                   imgLight={logo.logo} imgDark={logo.logoDark} index={i}/>
                    </Col>
                    <Col md={3}>
                        <ArrayBtns last={i + 1 !== tempArray.length} first={i !== 0} index={i}
                                   state={[tempArray, setTempArray]}/>
                    </Col>
                </Row>
                <hr className={"mt-4"}/>
            </div>
        })}
        <div className={"mb-5 text-right"}>
            <button type={"button"} className={"scbtn--med scbtn--1"} onClick={() => {
                setTempArray(prevState => {
                    return [...prevState, {title: "", link: ""}]
                })
            }}>
                New Supporter
            </button>
        </div>
    </>
}

///////////////////// Form ///////////////////////

function ArrayForm({name, link, index, imgLight, imgDark, state: [array, setArray]}) {

    const handleChange = (v, n) => {
        let tempArr = array
        tempArr[index][n] = v
        setArray([...tempArr])
    }

    const handleImg = (ImgArr, logoType) => {
        let tempArr = array
        tempArr[index][logoType] = ImgArr[0]
        setArray([...tempArr])
    }

    return <>
        <label className={"general-input__label"}>Name</label>
        <input type={"text"} placeholder={"Organization Name"} className={"general-input mb-2"}
               value={name} onChange={(e) => handleChange(e.target.value, "name")}/>

        <label className={"general-input__label"}>Website Link</label>
        <input type={"text"} placeholder={"Website Link"} className={"general-input mb-2"}
               value={link} onChange={(e) => handleChange(e.target.value, "link")}/>

        <div className={""}>
            {/* Light Logo: */}
            <div className={"d-flex py-3"}>
                <div>
                    <label className={"general-input__label"}>Logo (Light Pages)</label>
                    <ImageLibrary handleImageChoice={(imgArr) => handleImg(imgArr, "logo")} directory={"funders"}
                                  mapIndex={index}/>
                </div>
                {imgLight !== "" && imgLight !== undefined && <img src={imgLight} alt={name} className={"ml-4 mt-auto"}
                                                                   style={{
                                                                       width: "auto",
                                                                       height: "6rem",
                                                                       backgroundColor: "#dbdbdb",
                                                                       padding: ".5rem",
                                                                   }}/>}
            </div>
            {/* Dark Logo: */}
            <div className={"d-flex py-2" +
            ""}>
                <div>
                    <label className={"general-input__label"}>Logo (Dark Pages)</label>
                    <ImageLibrary handleImageChoice={(imgArr) => handleImg(imgArr, "logoDark")} directory={"funders"}
                                  mapIndex={index}/>
                </div>
                {imgDark !== "" && imgDark !== undefined && <img src={imgDark} alt={name} className={"ml-4 mt-auto"}
                                                                 style={{
                                                                     width: "auto",
                                                                     height: "6rem",
                                                                     backgroundColor: "#dbdbdb",
                                                                     padding: ".5rem",
                                                                 }}/>}
            </div>
        </div>
    </>
}

///////////////////// Buttons ///////////////////////

function ArrayBtns({last, first, index, state: [array, setArray]}) {
    const [helpText, setHelpText] = useState(" ")

    const handleMove = (action) => {
        let tempArr = array
        let f = tempArr.splice(index, 1)[0];
        action === "down" && tempArr.splice(index + 1, 0, f);
        action === "up" && tempArr.splice(index - 1, 0, f);
        setArray([...tempArr])
    }

    const handleAddDelete = (action) => {
        let tempArr = array
        action === "add" && tempArr.splice(index + 1, 0, {name: "", link: "", logo: ""});
        action === "delete" && tempArr.splice(index, 1);
        setArray([...tempArr])
    }

    return <>
        <div className={"position-relative h-100"}>
            <div className={"position-absolute"} style={{bottom: "4rem", right: "0"}}>
                <p className={"text-right m-0 p-0 text-small"}>{helpText}</p>
            </div>
            <div className={"position-absolute"} style={{bottom: "0", right: "0"}}>
                {first && <Btn value={<FontAwesomeIcon icon={faAngleUp}/>} action={"up"}
                               setHelp={setHelpText} helpText={"Move Up"} handleChange={handleMove}/>}
                {last && <Btn value={<FontAwesomeIcon icon={faAngleDown}/>} action={"down"}
                              setHelp={setHelpText} helpText={"Move Down"} handleChange={handleMove}/>}

                <Btn value={<FontAwesomeIcon icon={faPlus}/>} action={"add"} handleChange={handleAddDelete}
                     setHelp={setHelpText} helpText={"Add Bellow"}/>
                <Btn value={<FontAwesomeIcon icon={faTrashAlt}/>} action={"delete"} handleChange={handleAddDelete}
                     setHelp={setHelpText} helpText={"Delete"}/>

            </div>
        </div>
    </>
}


////// Button //////
function Btn({value, action, setHelp, helpText, handleChange}) {
    return <>
        <button type={"button"} style={{fontSize: "2rem", lineHeight: ".6"}}
                onClick={() => handleChange(action)} className={"btn ml-2 scbtn--1"}
                onMouseEnter={() => setHelp(helpText)} onMouseLeave={() => setHelp("")}
                onClickCapture={(e) => e.currentTarget.blur()}>
            {value}
        </button>
    </>
}