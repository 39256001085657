import React, {useContext} from 'react';
import Input from "../elements/Input";
import {Form} from "react-bootstrap";
import TextEditor from "../textEditor/TextEditor";
import {PoliciesEditorContext} from "../../providers/PoliciesEditorContext";

function EventEditorForm() {
    const {policy, handleChange} = useContext(PoliciesEditorContext)

    return <>
        {policy &&
        <Form className={""}>
            {/*Title*/}
            <Input label={"Title"} keyToUpdate={"title"} value={policy.title} handleChange={handleChange} />

            <hr className={"mb-4"} />

            {/*Editor - BODY*/}
            <TextEditor value={policy.body}
                        handleChange={handleChange}
                        imageDirectory={`news/${policy.id}/body`}
                        keyToUpdate={"body"} />

            <hr />
        </Form>
        }
    </>
}

export default EventEditorForm;